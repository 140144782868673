import React from "react";
import { SupplyChainMode } from "../utils/constants";

const ActiveKeywordsPanel = ({ activeKeywords, mode }) => {
  return (
    <div className="form-group">
      <div className="new-profile-panel-header">
        <div className="new_p_tbl_1">
          <p className="services-selected-title-center">Active Keywords</p>
        </div>
      </div>
      <div className="segment_steps_body clearfix">
        {activeKeywords !== undefined &&
          activeKeywords.map((x) => (
            <div
              key={`active-services-selected-${x.serviceId}-${x.level}`}
              className="popup-item-boxes"
            >
              <div className="tbl">
                <div className="tbl-cell md_ch_2">
                  <div className="tbl">
                    <div className="tbl-cell">{x.name}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="text-center">
        <p>
          {SupplyChainMode.DEMO === mode ? (
            <b>
              IMPORTANT NOTE! Your profile will be found online when customers
              search for the above (services / keywords).
            </b>
          ) : (
            <b>
              IMPORTANT NOTE! To avoid bad reviews or negative reports from
              customers please remove all keywords listed that you do not
              provide, they will only ruin your online reputation
            </b>
          )}
        </p>
      </div>
    </div>
  );
};

export default ActiveKeywordsPanel;
