import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import '../../style.css'
const CollapsibleList = forwardRef(({ items, level = 0, prefix = '', padding, show = 'block' }, ref) => {
  const treeRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState([]);


  const saveCheckedItems = () => {
    const checkedCheckboxes = treeRef.current.querySelectorAll('input[type="checkbox"]:checked');
    const selected = Array.from(checkedCheckboxes).map((checkbox) => ({
      id: checkbox.id,
      parent_id: checkbox.getAttribute('data-parent-id'),
    }));
    setSelectedItems(selected);
    return selected; // Retorna los elementos seleccionados
  };

  // Exponer el método saveCheckedItems a través de la referencia
  useImperativeHandle(ref, () => ({
    saveCheckedItems
  }));

  return (

    <ol ref={treeRef} className="container-framework-list" style={{ paddingLeft: padding + "px", display: (show), width: 'max-content' }}>
      {items.map((item, index) => {
        const currentNumber = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        return <CollapsibleItem key={item.id} item={item} level={level} currentNumber={currentNumber} getSelectedItems={saveCheckedItems} />;
      })}
    </ol>

  );
});

const CollapsibleItem = ({ item, level, currentNumber, getSelectedItems }) => {

  // Check if the item or any child is checked to determine if it should be open
  const hasCheckedChildren = (item) => {
    if (item.checked) return true;
    return item.children?.some(hasCheckedChildren);
  };

  const calculateIndeterminateState = (item) => {
    const childChecked = item.children?.some((child) => child.checked);
    const childUnchecked = item.children?.some((child) => !child.checked && !child.indeterminate);
       
    return childChecked && childUnchecked; // Indeterminado si hay hijos marcados y no marcados
  };

  const [isOpen, setIsOpen] = useState(() => hasCheckedChildren(item)); // true if all open
  const [isChecked, setIsChecked] = useState(item.checked || false);
  const [isIndeterminate, setIsIndeterminate] = useState(() => calculateIndeterminateState(item));


  useEffect(() => {
    setIsOpen(hasCheckedChildren(item)); // Sync isOpen when item.checked changes
    setIsChecked(item.checked || false);
    setIsIndeterminate(calculateIndeterminateState(item));
  }, [item]);



  const toggle = (e) => {
    e.stopPropagation(); // Evitar conflictos con otros eventos   
    setIsOpen(!isOpen)
  };

  const handleCheckItem = (e, item) => {
    e.stopPropagation();
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      setIsOpen(true);
    }
    handleChangeItem(e.target);
  }

  const handleChangeItem = (checkbox) => {
    const isChecked = checkbox.checked;
    const parentNode = checkbox.closest("li");

    // Marcar o desmarcar hijos
    const childCheckboxes = parentNode.querySelectorAll("ol input[type='checkbox']");

    /** 
     * Deshabilitado pero pendiente de activacion
    */

    // childCheckboxes.forEach((child) => {
    //   child.checked = isChecked; // Sincronizar estado
    //   child.indeterminate = false;
    // });

    // Propagar hacia arriba: sincronizar el estado de los padres
    updateParentState(parentNode);

  }

  const updateParentState = (node) => {
    let currentParent = node.parentElement.closest("li");
    while (currentParent) {
      const parentCheckbox = currentParent.querySelector(":scope > input[type='checkbox']");
      const childCheckboxes = currentParent.querySelectorAll(":scope >  ol input[type='checkbox']");

      const allChecked = [...childCheckboxes].every((child) => child.checked);
      const someChecked = [...childCheckboxes].some((child) => child.checked);

      if (parentCheckbox) {

        parentCheckbox.checked = allChecked;
        parentCheckbox.indeterminate = !allChecked && someChecked; // Estado intermedio
        if (parentCheckbox.indeterminate) {
          parentCheckbox.classList.add("indeterminate");
        } else {
          parentCheckbox.classList.remove("indeterminate");
        }

      }

      currentParent = currentParent.parentElement.closest("li");
    }
  };


  return (
    <li style={{ marginLeft: `${0}px` }} key={item.id} onClick={toggle}>

      {item.children.length > 0 &&
        < small className='icon-open ' style={{ color: '#8d8d8d', fontFamily: "Inter, serif" }}>
          {isOpen > 0 ?
            <i class="bi bi-caret-down-fill"></i> :
            <i class="bi bi-caret-right-fill"></i>
          }
          &nbsp;
        </small>
      }
      <input
        ref={(input) => input && (input.indeterminate = isIndeterminate)} // Actualizar indeterminado dinámicamente
        type="checkbox"
        id={item.id}
        className={`checkbox-frameworks ${isIndeterminate ? 'indeterminate' : ''}`}
        name="frameworks"
        data-id={item.id}
        data-parent-id={item.parent_id}
        onClick={(e) => { handleCheckItem(e, item) }}
        checked={isChecked} /> 
         &nbsp;
        <span style={{ color: '#3f3f3f', fontFamily: "Inter, serif", fontSize: "14px" }}>{item.id}</span> <span style={{ color: 'gray', fontFamily: "Inter, serif", fontSize: "14px" }}>{item.name}</span>

      {
        item.children.length > 0 && (
          <CollapsibleList items={item.children} level={level + 1} prefix={currentNumber} padding={20} show={isOpen ? 'block' : 'none'} />
        )
      }
    </li >
  );
};

export default CollapsibleList;
