import React,  { useState, useEffect } from 'react';
import CollapsibleList from './CollapsibleList';
import 'bootstrap/dist/css/bootstrap.min.css';
import {resolveTree} from '../../Helpers/CheckTreeHelper';
const DATA_TRADES = require('../../Constants/dataSourceTotal.json');
const dataField = [
  {
    id: 1,
    name: 'Parent 1',
    path: '0',
    parent_path: null,
    children: [
      { id: 2, name: 'Child 1.1', path: '0-0', parent_path: '0', children: [] },
      { id: 3, name: 'Child 1.2', path: '0-1', parent_path: '0', children: [] }
    ]
  },
  {
    id: 4,
    name: 'Parent 2',
    path: '1',
    parent_path: null,
    children: [
      { id: 5, name: 'Child 2.1', path: '1-0', parent_path: '1', children: [] },
      { id: 6, name: 'Child 2.2', path: '1-0', parent_path: '1', children: [] }
    ]
  },
  {
    id: 7,
    name: 'Parent 3',
    path: '2',
    parent_path: null,
    children: [
      { id: 8, name: 'Child 3.1', path: '2-0', parent_path: '2', children: [] },
      {
        id: 9,
        name: 'Child 3.2',
        path: '2-1',
        parent_path: '2',
        children: [{ id: 10, name: 'Child 3.2.1', path: '2-1-0', parent_path: '2-1', children: [ { id: 11, name: 'Child 3.2.1.1', path: '2-1-0-0', parent_path: '2-1-0', children: [ ] }] }]
      }
    ]
  }
];

function InteractivePanel() {

  const [path, setPath] = useState([[0,0],[0,0,0],[1,0],[1,0,0],[1,0,0,0,0],[1,0,0,0,1],[1,0,0,0,2],[1,0,0,1,0,1,0],[1,0,0,1,0,1,1],[1,0,0,1,0,1,2],[1,0,0,1,0,1,3],[1,0,0,1]]);
  const [data, setData] = useState(dataField);

  useEffect(() => {
    setData(resolveTree(path,DATA_TRADES));
  }, [])  
  console.log(data);
  
  return (
    <div className="App">
      <h1>Collapsible List</h1>
      <CollapsibleList items={data} />
    </div>
  );
}

export default InteractivePanel;
