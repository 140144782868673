import React from 'react'
import FrameworkCard from '../../Atoms/FrameworkCard'

function FrameworkList({frameworks,loading, selectFramework,removeFramework,updateFramework,showTableContent, selectedTab, downloadFramework,stopTour}) {
  return (
    <div className='mt-5'>
            {loading ? (
                <p>Loading frameworks...</p>
            ) : (
                <div className="" style={{display:'grid', gridTemplateColumns:'repeat(auto-fit, minmax(250px, 1fr))', gap:'32px'}}>
                    {frameworks.length > 0 ? (
                        frameworks.map((framework, index) => (
                            <div key={index} className="" style={{minWidth:'calc((100% - 2 * 35px) / 3)'}}>
                                <FrameworkCard
                                    {...framework}
                                    selectFramework={selectFramework}
                                    removeFramework={removeFramework}
                                    updateFramework={updateFramework}
                                    showTableContent={showTableContent}
                                    downloadFramework={downloadFramework}
                                    selectedTab={selectedTab} 
                                    stopTour={stopTour}                                  
                                />
                            </div>
                        ))
                    ) : (
                        <p>Frameworks not available.</p>
                    )}
                </div>
            )}
        </div>
  )
}

export default FrameworkList