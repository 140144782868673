import React from 'react';
import Button from '../Atoms/Button';

const ButtonGroup = ({ onCancel }) => {
    return (
        <div className="d-flex justify-content-around">
            <Button label="Guardar" type="submit" variant="primary" />
            <Button label="Cancelar" onClick={onCancel} type="button" variant="secondary" />
        </div>
    );
};

export default ButtonGroup;
