import React,{useEffect, useState} from 'react'

function NewTeam(props) {
    const [teamName, setTeamName] = useState(props.value);

     useEffect(() => {        

     }, []);
   
    const handleTeamName = (evt) => {
        setTeamName(evt.target.value);
       
    };

    const updateField =()=>{
        props.onChange(teamName);
    }

    return (
        <input key={'teamNameInput'} className="form-control"  value={teamName} onChange={handleTeamName} onBlur={updateField}></input>
    )
}

export default NewTeam