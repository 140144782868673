import React, { useState, useEffect } from 'react'
import './treeTrade.css'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
function TreeTrades({ data, saveGroup, pathArray, handleLastPath, type, showDescription, itemDetails, paidMembership, setShowProjectWorkflowNotification, scrollNotificationProject }) {


    const [dataSource, setData] = useState(data);
    const [pathList, setPathList] = useState(pathArray);
    const [showPopup, setShowPopup] = useState(false);
    const [addCustomParam, setAddCustomParam] = useState(false);
    const [typeView, setTypeView] = useState('only_selected');
    const [includeTables, setIncludeTables] = useState(paidMembership ? ['ALL'] : ['table_w', 'table_x', 'table_y']);

    const [currentNodeName, setCurrentNodeName] = useState('');
    const [parameterName, setParameterName] = useState('');
    const [typeParameter, setTypeParameter] = useState('');
    const [inputTextParamValue, setInputTextParamValue] = useState('');
    const [selectParamOptions, setSelectParamOptions] = useState('');
    // const handleCheckboxChange = (id) => {

    //     setData(prevData => prevData.map(service => {
    //         console.log(service);
    //         if (service.id === id) {
    //             service.checked = !service.checked;
    //         }
    //         if (service.children && service.children.length > 0) {
    //             service.children = toggleChecked(service.children, service.checked);
    //         }
    //         return service;
    //     }));
    // };

    // const toggleChecked = (children, checked) => {
    //     return children.map(child => {
    //         child.checked = checked;
    //         if (child.children && child.children.length > 0) {
    //             child.children = toggleChecked(child.children, checked);
    //         }
    //         return child;
    //     });
    // };

    useEffect(() => {
        saveGroup(null, pathList);
    }, [pathList])

    const handleChangeTypeParam = (evt) => {
        setTypeParameter(evt.target.value);
        setInputTextParamValue("");
        setSelectParamOptions("");
    }

    const updateNodeParams = () => {

        const customParams = {
            parameter_name: parameterName,
            type_parameter: typeParameter,
            value: typeParameter == 'input_select' ? selectParamOptions.split(',').map(opt => opt.trim()) : inputTextParamValue,
        };

    }

    const compareArrays = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((valor, indice) => valor === arr2[indice]);
    };

    const existArray = (newArray) => {
        return pathList.some(arr => compareArrays(arr, newArray));
    };

    const deleteArray = (nuevoArray) => {
        setPathList(pathList.filter(arr => !compareArrays(arr, nuevoArray)));
    };

    const handleCheckboxChange = (id, path, item, e) => {

        setShowProjectWorkflowNotification(e.target.checked);
        handleLastPath(path, item);

        setData(prevData => {

            const updatedData = toggleChecked(prevData, id, path);
            return [...updatedData]; // Devolvemos un nuevo array
        });

        let positionListChecked = path.split("-").map(Number);
        if (existArray(positionListChecked)) {
            deleteArray(positionListChecked);
        } else {
            setPathList([...pathList, positionListChecked]);
        }

        saveGroup(dataSource, null);
    };

    const toggleChecked = (services, id, path) => {


        return services.map(service => {
            if (service.id === id && service.path === path && service.children && service.children.length > 0) {
                service.checked = !service.checked;
            }

            if (service.id === id && service.path === path && service.children && service.children.length == 0) {
                service.checked = !service.checked;
            } else {
                service.children = toggleChecked(service.children, id, path);
            }

            return service;
        });
    };


    const showItemDescription = (data) => {
        showDescription();
        itemDetails(data)
    }

    const handleSupplementaryButton = (item) => {
        handleLastPath(item.path, item);
        setShowProjectWorkflowNotification(true);
        scrollNotificationProject();
    }


    const renderItems = (data) => {
        return (
            <ul>
                {data.map((item, index) => (

                    <li key={item.id}>
                        {/* Renderiza el nombre del item */}
                        {(!(includeTables.includes(item.next_level)) && item.children && item.children.length > 0) ?
                            <>

                                <input className='control-input' onChange={(e) => handleCheckboxChange(item.id, item.path, item, e)} checked={item.checked} type="checkbox" id={item.path + '-' + 1} />
                                <input className='control-node' type="checkbox" id={item.path + '-t' + 1} checked={item.visible} />
                                <label htmlFor={item.path + '-t' + 1} className={"tree_label l" + item.id}>
                                    &nbsp;&nbsp;{item.name}&nbsp;
                                    {item.checked && <button type="button" className='label-supplementary' onClick={() => { handleSupplementaryButton(item) }}>&nbsp;&nbsp;Supplementary&nbsp;</button>}
                                </label>
                                {item.checked &&
                                    <i className="bi bi-gear-fill icon-label d-none" style={{ color: "#1a67a3", fontSize: "13px", fontStyle: "normal", cursor: "pointer" }} onClick={() => { setShowPopup(!showPopup); setCurrentNodeName(item.name) }}>&nbsp;Params</i>
                                }

                            </>
                            :
                            <>

                                <input className='control-input' onChange={(e) => handleCheckboxChange(item.id, item.path, item, e)} checked={item.checked} type="checkbox" id={item.path + '-' + 1} />
                                <span className={"tree_label_child"}>&nbsp;&nbsp;{item.name}&nbsp;
                                    {item.checked && <button type="button" className='label-supplementary' onClick={() => { handleSupplementaryButton(item) }}>&nbsp;&nbsp;Supplementary&nbsp;</button>}
                                </span>
                            </>
                        }

                        {/* Si el item tiene children y no está vacío, renderiza una nueva lista */}
                        {!(includeTables.includes(item.next_level)) && item.children && item.children.length > 0 && renderItems(item.children)}

                    </li>
                ))}
            </ul>
        );

        return renderItems(data);
    }


    return (

        <div style={{ width: "max-content" }}>
            <div className={`modal small-popup ` + (showPopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className="modal-card ">
                    <section className="modal-card-body home-modal">
                        <div className="endos_panel2 params-cont clearfix">
                            <div className='title-tree-params'>
                                <h4>Params for {currentNodeName}</h4>
                                <span onClick={() => setShowPopup(!showPopup)} style={{ color: "red" }}>x</span>
                            </div>
                            <div className="form-tree-params mt-4">
                                <div class="form-group">
                                    <label htmlFor="inputParams">Description</label>
                                    <input type="text" id="inputParams" name="inputParams" className="form-control"></input>
                                </div>
                                <hr></hr>
                                <div className="form-group">
                                    <label htmlFor="selectParams">Predefined Parameters</label>
                                    <select className="form-control" id="selectParams">
                                        <option>Param 1</option>
                                        <option>Param 2</option>
                                        <option>Param 3</option>
                                        <option>Param 4</option>
                                        <option>Param 5</option>
                                    </select>
                                </div>
                                <hr></hr>
                                <div>
                                    {!addCustomParam &&
                                        <>
                                            {typeParameter === "" ?
                                                <span style={{ cursor: 'pointer', color: '#1a67a3', fontWeight: '600' }} onClick={() => setAddCustomParam(!addCustomParam)}>Add parameter</span>
                                                :
                                                <div>
                                                    {typeParameter === "input_text" &&
                                                        <div className="form-group">
                                                            <label htmlFor="inputParamName">{parameterName}</label>
                                                            <input type="text" id="inputParamName" name="inputParamName" className="form-control" value={inputTextParamValue}></input>
                                                        </div>

                                                    }
                                                    {typeParameter === "input_select" &&
                                                        <div className="form-group">
                                                            <label htmlFor="inputParamSelect">{parameterName}</label>
                                                            <select className="form-control" id="inputParamSelect">
                                                                {
                                                                    selectParamOptions.split(',').map((el, index) => {
                                                                        return (
                                                                            <option key={index}>{el}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>

                                                    }
                                                    <span className='link-update-param' onClick={() => setAddCustomParam(!addCustomParam)}>Update this param</span>
                                                </div>
                                            }
                                        </>
                                    }
                                    {
                                        addCustomParam &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="inputAddParams">Parameter Name</label>
                                                <input
                                                    type="text"
                                                    id="inputAddParams"
                                                    name="inputAddParams"
                                                    className="form-control"
                                                    value={parameterName}
                                                    onChange={(e) => setParameterName(e.target.value)}
                                                />
                                            </div>
                                            <div className="cont-input-param-check">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="inputAddText"
                                                        id="inputAddText"
                                                        value="input_text"
                                                        checked={typeParameter == 'input_text' ? true : false}
                                                        onChange={handleChangeTypeParam}
                                                    />

                                                    <label className="form-check-label" for="inputAddText">
                                                        Input Text
                                                    </label>

                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="inputAddSelect"
                                                        id="inputAddSelect"
                                                        value="input_select"
                                                        checked={typeParameter == 'input_select' ? true : false}
                                                        onChange={handleChangeTypeParam}
                                                    />

                                                    <label className="form-check-label" for="inputAddSelect">
                                                        Input Select
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                typeParameter == 'input_text' &&
                                                <div className="form-group">
                                                    <label htmlFor="inputAddParamsValue">Parameter Value</label>
                                                    <input
                                                        type="text"
                                                        id="inputAddParamsValue"
                                                        name="inputAddParamsValue"
                                                        className="form-control"
                                                        value={inputTextParamValue}
                                                        onChange={(e) => setInputTextParamValue(e.target.value)}
                                                    />
                                                </div>

                                            }

                                            {
                                                typeParameter == 'input_select' &&
                                                <div className="form-group mt-3">
                                                    <label htmlFor="inputAddParamsValue">Parameter Options (separated by commas)</label>
                                                    <input
                                                        type="text"
                                                        id="inputAddParamsValue"
                                                        name="inputAddParamsValue"
                                                        className="form-control"
                                                        value={selectParamOptions}
                                                        onChange={(e) => setSelectParamOptions(e.target.value)}
                                                    />
                                                </div>

                                            }


                                            {/* <button className='btn btn-warning' onClick={() => setAddCustomParam(!addCustomParam)}>Close add</button> */}
                                        </>
                                    }

                                </div>
                            </div>
                            <div className='map-button mt-5'>
                                <button type="button" className='btn-fill' onClick={() => { updateNodeParams(); setAddCustomParam(!addCustomParam) }}>Save</button>
                                <button type="button" className='btn-fill exit-trade-modal' onClick={() => { setAddCustomParam(!addCustomParam); setShowPopup(!showPopup) }}>Close</button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <ul className="tree">
                {dataSource[0] &&
                    <li>
                        <input className='control-node' type="checkbox" checked={true} id={dataSource[0].path} />
                        &nbsp;&nbsp;&nbsp;
                        {/* <input className='control-input' type="checkbox" id={dataSource[0].path + '-' + 0} /> */}
                        <label className="tree_label" htmlFor={dataSource[0].path}>{dataSource[0].name}</label>
                        {dataSource[0].children && dataSource[0].children.length > 0 && renderItems(dataSource[0].children)}
                    </li>
                }
            </ul>
        </div>
    )
}

export default TreeTrades