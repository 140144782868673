import React from 'react';

const DropDownMenu = ({ show, items }) => {  
    
  return (
    <div
      className={`dropdown-menu ${show ? 'show' : ''}`}
      style={{
        position: 'absolute',
        right: -15,
        left:-133,
        top:15,
        marginTop: '10px',
        display: show ? 'block' : 'none',
      }}
    >
      {items.map((item, index) => (
        <a
          key={index}          
          className="dropdown-item"
          onClick={() => item.onClick()}
        >
          {item.label}
        </a>
      ))}
    </div>
  );
};

export default DropDownMenu;
