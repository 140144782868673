import { Link, withRouter, useLocation } from "react-router-dom";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import { BASE_URL } from "../../Constants/serverConfig";
import { DATA, TAB_KEY } from "../../Constants/localStorageKeys";
import axios from "axios";
//import { compose } from "recompose";
import { compose } from "redux";
import { connect } from "react-redux";
import ProfileQrCode from "../ProfileQrCode";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileOptions: false,
      showNotification: false,
      activeServices: [],
      notificationMesages: [],
      showError: false,
      errorMessage: "",
    };
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
  }
  container = React.createRef();

  forceUpdateHandler() {
    const { token } = this.props;
    if (token !== null) {
      //this.getActiveServices(token);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.serviceProvider !== nextProps.serviceProvider ||
      this.props.token !== nextProps.token ||
      this.state.showProfileOptions !== nextState.showProfileOptions ||
      this.state.showNotification !== nextState.showNotification ||
      this.state.activeServices !== nextState.activeServices ||
      this.state.showError !== nextState.showError ||
      this.state.errorMessage !== nextState.errorMessage ||
      this.state.notificationMesages !== nextState.notificationMesages
    );
  }

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  componentWillUnmount() {
    this._mounted = false;
    const { childRef } = this.props;
    if (childRef) {
      childRef(undefined);
    }
    this.removeGlobalListener();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      //this.getActiveServices(this.props.token);
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchNotifications();
    setInterval(() => this.fetchNotifications(), 600000);
    const { token, childRef } = this.props;
    // document.addEventListener("mousedown", this.handleClickOutside);
    if (childRef) {
      childRef(this);
    }
    //this.getActiveServices(token);
  }

  // handleClickOutside = (event) => {
  //   if (
  //     this.container.current &&
  //     !this.container.current.contains(event.target)
  //   ) {
  //     this.setState({
  //       showProfileOptions: false,
  //       showNotification: false,
  //     });
  //   }
  // };

  removeGlobalListener() {
    document.removeEventListener("click", this.globalClickListener);
  }

  setGlobalListener() {
    document.addEventListener("click", this.globalClickListener);
  }

  globalClickListener = (e) => {
    this.setState({ showProfileOptions: false, showNotification: false });
    this.removeGlobalListener();
  };

  fetchNotifications = () => {
    const { token } = this.props;
    if (token !== null) {
      axios
        .get(`${BASE_URL}/notification-message`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              notificationMesages: response.data,
            });
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  };

  toggleProfileOptions = () => {
    if (!this.state.showProfileOptions) {
      this.setGlobalListener();
    } else {
      this.removeGlobalListener();
    }
    this.setState({
      showProfileOptions: !this.state.showProfileOptions,
    });
  };

  toggleNotification = () => {
    if (!this.state.showNotification) {
      this.setGlobalListener();
    } else {
      this.removeGlobalListener();
    }
    this.setState({
      showNotification: !this.state.showNotification,
    });
  };

  getActiveServices = (token) => {
    if (token !== null) {
      axios
        .get(`${BASE_URL}/supply-chain/selected-service`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          if (this._mounted) {
            this.setState({
              activeServices: response.data,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  goHome = () => {
    const { isSpLoggedIn } = this.props;
    if (isSpLoggedIn) {
      this.props.history.push("/vendor/dashboard");
    } else {
      this.props.history.push("/");
    }
  };

  // setZohoAction = () => {
  //   // window.zcAction("digital-profile-button");
  // };

  render() {
    // this._mounted === false &&
    //   document.removeEventListener("mousedown", this.handleClickOutside);
    const {
      showProfileOptions,
      showNotification,
      activeServices,
      showError,
      errorMessage,
      notificationMesages,
    } = this.state;
    const {
      isSpLoggedIn,
      handleLogout,
      metroArea,
      serviceProvider,
      isDark,
      location,
    } = this.props;
    let defaultService = activeServices.filter((x) => x.is_default === true);
    let darkTemplate =
      isDark === true ? "hero-head gray-hero-head" : "hero-head";
    let landingPage = location.pathname === "/home" ? "land-main-menu" : "";
    // console.log("serviceProvider: ", serviceProvider);
    return (
      <section className="hero">
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        <div
          ref={this.headerRef}
          className={`${darkTemplate} ${landingPage} siteHeader`}
        >
          <div className="container">
            <div className="columns is-mobile is-marginless">
              <div className="column left logo">
                <figure
                  onClick={this.goHome}
                  style={{ cursor: "pointer" }}
                  className="navbar-item image"
                >
                  <Link to="/">
                    <img
                      src={require(isDark === true
                        ? "../../assets/images/logo-new-yellow-no-text.png"
                        : "../../assets/images/logo-new-yellow-no-text.png")}
                      alt="Logo"
                    />
                    <span className="logoName">PropAgile</span>
                  </Link>
                  {/* <span className="logoInfo new_home_span"> //Alvaro requested this to be removed when user is logged in - requested via whatsapp
                    Services Marketplace{" "}
                    <span className="logoInfo_small"> Based on </span> UniFormat{" "}
                    <span className="logoInfo_small"> & </span> MasterFormat{" "}
                  </span> */}
                </figure>
              </div>
              <div
                className="column has-text-centered"
                style={{ alignItems: "center" }}
              >
                {isSpLoggedIn ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="back-dashboard">
                      <Link to="/vendor/dashboard">
                        <img
                          src={require(isDark === true
                            ? "../../assets/images/white-icon.png"
                            : "../../assets/images/back-icon.png")}
                          alt=""
                        />
                        <span style={{ marginLeft: "10px" }}>DASHBOARD</span>
                      </Link>
                    </div>
                    <div className="notification-sec" ref={this.container}>
                      <div
                        onClick={this.toggleNotification}
                        className="notification-iocn"
                      >
                        <i className="fa fa-bell" />
                        {notificationMesages.length > 0 ? <span /> : null}
                      </div>
                      <div
                        className={
                          showNotification
                            ? "notify-content is-active"
                            : "notify-content"
                        }
                      >
                        <div className="dropdown-content">
                          <ul className="notifica-inner">
                            {notificationMesages.length > 0 ? (
                              notificationMesages.map((x, index) => {
                                return (
                                  <li key={index}>
                                    <h4>{x.code}</h4>
                                    <p>{x.message}</p>
                                  </li>
                                );
                              })
                            ) : (
                              <li className="has-text-centered">
                                There are no notifications
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="dropdown login-user"
                      style={{ alignItems: "center" }}
                    >
                      <div className="dropdown-trigger">
                        <button
                          className="button"
                          onClick={this.toggleProfileOptions}
                          ref={this.container}
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <img
                              src={
                                serviceProvider &&
                                serviceProvider !== null &&
                                serviceProvider.profile_image && 
                                serviceProvider.profile_image[0]
                              }
                              alt="Service Provider"
                            />
                          </span>
                          <label className="hidden-xs">
                            {serviceProvider && serviceProvider !== null
                              ? serviceProvider.first_name
                              : ""}{" "}
                            {serviceProvider && serviceProvider !== null
                              ? serviceProvider.last_name
                              : ""}
                          </label>
                          <i className="togle-dots">
                            <p />
                            <p />
                            <p />
                          </i>
                        </button>
                      </div>
                      <div
                        className={
                          showProfileOptions
                            ? "dropdown-menu is-open"
                            : "dropdown-menu"
                        }
                      >
                        <div className="dropdown-content">
                          {serviceProvider !== null &&
                          serviceProvider.profile_status !== "incomplete" &&
                          defaultService.length > 0 ? (
                            <Link to={`/pro1/${serviceProvider.username}`}>
                              PROFILE
                            </Link>
                          ) : serviceProvider !== null &&
                            serviceProvider.profile_status === "incomplete" ? (
                            <a
                              onClick={() => {
                                this.showError(
                                  "Please complete your personal information"
                                );
                              }}
                            >
                              PROFILE
                            </a>
                          ) : (
                            <a
                              onClick={() => {
                                this.showError(
                                  "Please add an active service and make it default to create your digital profile"
                                );
                              }}
                            >
                              PROFILE
                            </a>
                          )}
                          <a
                            onClick={() => {
                              localStorage.setItem(TAB_KEY, "personalInfo");
                              this.props.history.push("/vendor/dashboard");
                            }}
                          >
                            PERSONAL INFORMATION
                          </a>
                          <a
                            onClick={() => {
                              localStorage.setItem(TAB_KEY, "services");
                              this.props.history.push("/vendor/dashboard");
                            }}
                          >
                            SERVICES
                          </a>
                          <a
                            onClick={() => {
                              localStorage.setItem(TAB_KEY, "promotion");
                              this.props.history.push("/vendor/dashboard");
                            }}
                          >
                            ONLINE REPUTATION
                          </a>
                          <a
                            onClick={() => {
                              localStorage.setItem(TAB_KEY, "portfolio");
                              this.props.history.push("/vendor/dashboard");
                            }}
                          >
                            PORTFOLIO MANAGEMENT
                          </a>
                          {serviceProvider.membership_information && serviceProvider.membership_information
                            .membership_id < 3 && (
                            <a
                              onClick={() => {
                                if (
                                  serviceProvider.membership_information
                                    .membership_id !== 3
                                ) {
                                  this.props.history.push(
                                    "/vendor/subscription-upgrade"
                                  );
                                } else if (
                                  serviceProvider.membership_information
                                    .membership_id === 3
                                ) {
                                  this.showError(
                                    "There are no memberships higher than Business"
                                  );
                                }
                              }}
                            >
                              UPGRADE
                            </a>
                          )}
                          <div style={{ borderBottom: "1px solid #f0f1f4" }}>
                            <ProfileQrCode
                              serviceProvider={serviceProvider}
                              isMenuBar={true}
                            />
                          </div>
                          <a onClick={handleLogout} href="#">
                            LOGOUT
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="back-dashboard">
                      <Link to="/customer/request">
                        <span>CUSTOMERS</span>
                      </Link>
                    </div>
                    <div className="back-dashboard">
                      <Link
                        to={
                          metroArea === undefined
                            ? "/vendor/lead/login"
                            : `/vendor/login?metro=${metroArea}`
                        }
                      >
                        <span>SERVICE PRO LOGIN</span>
                      </Link>
                    </div>

                    {/* <div className="back-dashboard">
                      <Link
                        to={"/Construction-Project-Management/login"}
                        params={{ isProjectManager: true }}
                      >
                        <span
                          style={{
                            border: "2px solid #f8fafb",
                            borderRadius: "9px",
                            padding: "5px 15px",
                          }}
                        >
                          P.M./Design Team
                        </span>
                      </Link>
                    </div> */}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(Header);
