import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Slide,
  styled,
} from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import { basePath } from "../../Constants/utils.jsx";
import logImage from "../../assets/images/QrCodeLogo.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyle = styled({
  anchortag: {
    "&:hover": {
      color: "white !important",
      backgroundColor: "#1a67a3",
    },
  },
});

const ProfileQrCode = ({ serviceProvider, isMenuBar, showQRModal }) => {
  const [openQrCodeModel, setOpenQrCodeModel] = useState(false);
  const classes = useStyle();

  useEffect(() => {
    setOpenQrCodeModel(showQRModal);
  }, [showQRModal])
  

  const downloadQR = () => {
    const canvas = document.getElementById("profileQrCodeDownload");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "profileQrCodeDownload.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      {/* <a
        className={isMenuBar && classes.anchortag}
        onClick={() => setOpenQrCodeModel(true)}
        style={{
          width: "100%",
          textDecoration: "none",
          textTransform: isMenuBar && "uppercase",
          color: isMenuBar && "#2f3033",
          fontWeight: "600",
          float: isMenuBar && "right",
          textAlign: isMenuBar && "right",
          padding: isMenuBar && "6px 15px",
          fontFamily: isMenuBar && "Mark",
          fontSize: isMenuBar && "13px",
        }}
      >
        Profile QR Code
      </a> */}      
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openQrCodeModel}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenQrCodeModel(false)}
      >
        <DialogTitle
          style={{ textAlign: "center", fontSize: "28px", fontWeight: "600" }}
        >
          {"Profile QR Code"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <QRCode
              id="profileQrCodeDownload"
              value={`${basePath}pro1/${serviceProvider.username}`}
              size={290}
              level={"H"}
              includeMargin={true}
              bgColor={"#fff"}
              fgColor={"#1a67a3"}
              logoImage={logImage}
              logoWidth={75}
              logoHeight={50}
              removeQrCodeBehindLogo
            />
            <a
              onClick={downloadQR}
              style={{
                color: "#1a67a3", //"#1a67a3"
              }}
            >
              {" "}
              Download QR{" "}
            </a>
          </div>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQrCodeModel(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProfileQrCode;
