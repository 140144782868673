import React from 'react';

const NavLink = ({ label, href, onClick , active}) => (
  <a
    className="nav-link px-3 py-1 mx-4"
    href={href || '#'}
    onClick={(e) => { e.preventDefault(); if (onClick) onClick(); }}
    style={{ 
      color: active ? 'white' : '#b5b5b5', 
      textDecoration: 'none', 
      borderRadius:'0px', 
      fontWeight:'600', 
      fontFamily:'Monserrat', 
      borderBottom: active ?'2px solid #ffc107':'0px', 
      fontSize: active?'18px':'16px'

    }}

    >
    {label}
  </a>
);

export default NavLink;
