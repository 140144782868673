import React, {useEffect, useState} from 'react'

function NewMember(props) {

    const [newMemberName, setNewMemberName] = useState(props.valueName);
    const [newMemberEmail, setNewMemberEmail] = useState(props.valueEmail);
    const [currentFocus, setCurrentFocus] = useState(props.currentFocus);

    

    const handleNewMemberName = (evt) => {        
        setNewMemberName(evt.target.value);
    };

    const handleNewMemberEmail = (evt) => {
        setNewMemberEmail(evt.target.value);
    };

    const updateNewMemberName = ()=>{       
        props.handleNewMemberName(newMemberName);
    }
    const updateNewMemberEmail = ()=>{
        props.handleNewMemberEmail(newMemberEmail);
    }
    

    return (
        <div className='px-2 mt-4 mt-md-2 ' >
            <h5 className='mt-4'>Add members</h5>
            <span className='d-none d-md-block' style={{ fontSize: "20px" }}>You can invite multiple members to this team</span>
            <p className='d-block d-md-none'>You can invite multiple members to this team</p>
            <form className="row g-3 mt-3 mb-3">
                <div className="col-12 col-md-12">
                    <label htmlFor="inputName" className="visually-hidden" >Name</label>
                    <input key="newMemberName" autoFocus={props.currentFocus == 1 ? true:false} type="text" className="form-control" id="inputName" placeholder="Name" value={newMemberName} onChange={handleNewMemberName} onBlur={updateNewMemberName} />
                </div>

                <div className="col-12 col-md-12">
                    <label htmlFor="inputEmail" className="visually-hidden">Email</label>
                    <input key="newMemberEmail" autoFocus={props.currentFocus == 2 ? true:false} type="email" className="form-control" id="inputEmail" placeholder="email" value={newMemberEmail} onChange={handleNewMemberEmail} onBlur={updateNewMemberEmail} />
                </div>
            </form>

        </div>
    )
}

export default NewMember