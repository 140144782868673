import React, { Component } from "react";
import { connect } from "react-redux";
//import { compose } from "recompose";
import { compose } from "redux";
import Header from "../Header/header";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

class Careers extends Component {
  render() {
    const {
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
      isUserLoggedIn,
    } = this.props;
    return (
      <div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <div className="landing-banner-body">
          <div className="container">
            <h2>Under-Construction</h2>
            <p className="has-text-grey-darker has-text-justified">
              These terms and conditions outline the rules and regulations for
              the use of PropAGILE's Website, located at www.propagile.com.
            </p>
          </div>
        </div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(connect(mapStateToProps, null))(Careers);
