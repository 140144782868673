import React, { useState, useEffect } from 'react';
import Header from '../Organisms/Header';
import LeftSection from '../Organisms/LeftSection';
import Breadcrumbs from '../Atoms/Breadcrumbs';
import ProjectsPage from './ProjectsLayout';
import { useSelector, useDispatch } from "react-redux";
import IndividualInformation from '../ServiceProviders/components/individualInformation';
import FormField from '../Molecules/FormField';


const PageLayout = ({ logout }) => {

    const [selectedPage, setSelectedPage] = useState('Projects');
    const [selectedPhase, setSelectedPhase] = useState('');
    const [isSelectedProject, setIsSelectedProject] = useState(0);
    const [projectName, setProjectName] = useState("");
    const [breadPath, setBreadPath] = useState(['Home']);


    const token = useSelector((state) => state.token.token);
    const user = useSelector((state) => state.serviceProvider.serviceProvider);


    const menuItems = [
        //{ label: 'Dashboard', onClick: () => setSelectedPage('Dashboard') },
        { label: 'Projects', onClick: () => setSelectedPage('Projects') },
        { label: 'Account', onClick: () => setSelectedPage('Account') },
    ];

    const menuItemsLeft = [
        { id:1, label: 'Feasibility Phase', onClick: () => handleSelectMenuLeftItems('Feasibility Phase') },
        { id:2, label: 'Planning Phase', onClick: () => handleSelectMenuLeftItems('Planning Phase') },
        { id:3, label: 'Construction Phase', onClick: () => handleSelectMenuLeftItems('Construction Phase') },
        { id:4, label: 'Handover / Closeout Phase', onClick: () => handleSelectMenuLeftItems('Handover / Closeout Phase') },
        { id:5, label: 'Work Breakdown Structure', onClick: () => handleSelectMenuLeftItems('Work Breakdown Structure') },
    ];

    const rightIcons = [
        { src: '/bell-icon.png', alt: 'Notifications', onClick: () => console.log('Bell clicked') },
        { src: '/user-icon.png', alt: 'User', onClick: () => console.log('User clicked') },
    ];


    useEffect(() => {
        if (isSelectedProject) setSelectedPhase('Feasibility Phase');       
    }, [isSelectedProject])

    useEffect(() => {
        setBreadPath(['Home', selectedPage]);
    }, [selectedPage])

    useEffect(() => {
        if (selectedPhase) setBreadPath(['Home', selectedPage, projectName, selectedPhase]);
    }, [selectedPhase])

    useEffect(() => {
        switch (breadPath.length) {
            case 2:
                setIsSelectedProject(false);
                setSelectedPhase('');
                break;

            default:
                break;
        }
    }, [breadPath])

    const handlePathChange = (paths) => {
        setBreadPath(paths);
    };

    const handleSelectMenuLeftItems = (item) => {
        if (isSelectedProject) {
            setSelectedPhase(item);
        }       
    }



    const renderPageContent = () => {
        switch (selectedPage) {
            case 'Dashboard':
                return <h1>Dashboard</h1>;
            case 'Account':
                return (
                    <IndividualInformation
                        token={token}
                        serviceProvider={user}
                    />
                );
            case 'Projects':
                return (
                    <ProjectsPage
                        selectedPage={selectedPage}
                        selectedPhase={selectedPhase}
                        setIsSelectedProject={setIsSelectedProject}
                        setProjectName={setProjectName}
                        token={token}
                        serviceProvider={user}
                    />
                );
            default:
                return <h1>Página no encontrada</h1>;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedPhase(value);
        console.log(value);

    }

    return (
        <div className="d-flex flex-column" style={{ marginBottom: '15px' }}>
            <Header
                logoSrc="/logo.png"
                logoAlt="App Logo"
                menuItems={menuItems}
                selectedPage={selectedPage}
                rightIcons={rightIcons}
                logout={logout} />

            <div className="d-flex ">

                <div className='d-none d-md-block' style={{ width: '20%', position: 'relative' }}>

                    <LeftSection
                        menuItems={menuItemsLeft}
                        selectedPhase={selectedPhase}
                        isSelectedProject={isSelectedProject}
                        subscription={user.subscription}
                        userEmail={user.email_address} />
                </div>

                <div className="flex-grow-1 px-4 pt-1" style={{ background: '#f9f9f9', width: '80%' }}>
                    <Breadcrumbs paths={breadPath} onPathChange={handlePathChange} />
                    {isSelectedProject &&
                        <div className='d-block d-md-none'>
                            <FormField
                                type="select"
                                label=""
                                name="main_building_use"
                                value={selectedPhase}
                                onChange={handleChange}
                                customValue='Select Construction phase'
                                options={menuItemsLeft}
                            />
                        </div>
                    }
                    {renderPageContent()}

                </div>
            </div>
        </div>
    );
};

export default PageLayout;

