import React from 'react';


const Modal = ({ isOpen, onClose, title, children, onSave, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal d-block " style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content rounded-0">
                    <div className="modal-header p-2">
                        <h5 className="modal-title m-0">{title}</h5>
                        <button type="button" className="btn-close p-0 m-0 border-0 bg-white" onClick={onClose}><i className="bi bi-x"></i></button>
                    </div>
                    <div className="modal-body">{children}</div>
                    <div className="modal-footer d-none">
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
