import React, { useEffect, useState } from 'react'
import './../team.css'
function ProjectParamsForm(props) {

    const initialFormData = {
        zip_code: '',
        gross_area: '',
        work_type: '',
        main_building_use: ''
    }


    const [projectData, setProjectData] = useState(initialFormData);
    const [areaName, setAreaName] = useState("");
    const [areaDescription, setAreaDescription] = useState("");
    const [showAddParamForm, setShowAddParamForm] = useState(false);


    useEffect(() => {
       
        if (!props.projectParams?.length) {
            setShowAddParamForm(true)
        }

    }, []);

    useEffect(() => {
        console.log('hola');
        
        if (!props.projectParams?.length) {
            setShowAddParamForm(true)
            props.showParamsForm(true)
        }

    }, [props.projectParams]);

    useEffect(() => {

        if (props.editMode) {
            setProjectData(props.projectData)
        } else {
            setProjectData(initialFormData)
        }

    }, [props.editMode]);

    useEffect(() => {

        if (areaName !== "" || areaDescription !== "") {
            props.updateParams(areaName, areaDescription, props.paramsIndex);
        }


    }, [areaName, areaDescription]);

    useEffect(() => {

        if (areaName !== "" || areaDescription !== "") {
            props.updateParams(areaName, areaDescription, props.paramsIndex,'UPDATE');
        }


    }, [areaName, areaDescription]);


    const handleRemoveParam=(index)=>{
        props.updateParams("", "",index,"DELETE");
    }


    const handleChangeName = (e) => {
        setAreaName(e.target.value);
    };

    const handleChangeDescription = (e) => {
        setAreaDescription(e.target.value);
    };

    return (
        <>

            {
                props.projectParams && !showAddParamForm &&
                <div>
                    {props.projectParams.map((el, index) => {
                        return (
                            <div className='params-list'>
                                <span>{el.area_name}</span>
                                <span>{el.area_description}</span>
                                <span style={{cursor:'pointer'}} onClick={()=>{handleRemoveParam(index)}}>x</span>
                            </div>
                        )
                    })}
                </div>
            }
            {
                showAddParamForm &&
                <form>
                    <div className='project-param-title'>
                        <h3>Area of construction</h3>
                        <span onClick={() => {setShowAddParamForm(!showAddParamForm);props.showParamsForm(!showAddParamForm)}}><i className="bi bi-arrow-left"></i>back</span>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="name">Name of area</label>
                        <input id="name" name="name" key={'name'} className="form-control form-control-sm" value={areaName} onChange={handleChangeName} ></input>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="description">Description</label>
                        <input id="description" name="description" key={'description'} className="form-control form-control-sm" value={areaDescription} onChange={handleChangeDescription} ></input>
                    </div>
                </form>
            }
            {
                !showAddParamForm &&
                <div className='div-add-param-link'>
                    <span onClick={() => {setShowAddParamForm(!showAddParamForm); props.showParamsForm(!showAddParamForm)}} >Add new param</span>
                </div>
            }
        </>
    )
}

export default ProjectParamsForm