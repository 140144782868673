import React, { useEffect, useState } from 'react'
import axios from "axios";
import { BASE_URL } from '../../../Constants/serverConfig';
import ProjectForm from './ProjectsForm/ProjectForm';
import ProjectParamsForm from './ProjectsForm/ProjectParamsForm';
import './team.css'
import TreeTrades from './TreeTrades';
import { Alert } from 'react-bootstrap';
import ReactFlow, { Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import Loader from '../../Loader/loader';
import WorkFlow from '../../WorkFlow/Index';
const DATA_TRADES = require('../../../Constants/dataSourceTotal.json');
const json = {
    "name": "parent",
    "children": [
        {
            "name": "children 1",
            "children": [
                {
                    "name": "children 1.1",
                    "children": []
                }
            ]
        },
        {
            "name": "children 2",
            "children": [
                {
                    "name": "children 2.1",
                    "children": []
                }
            ]
        },

    ]
};

function Projects({ token, serviceProvider, showProjectActive }) {

    const initialFormData = {
        zip_code: '',
        gross_area: '',
        work_type: '',
        main_building_use: '',
        is_valid: false
    }
    const [showPopup, setShowPopup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [currentProject, setCurrentProject] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [tradePathSelected, setTradePathSelected] = useState();
    const [supplementarySelected, setSupplementarySelected] = useState([]);
    const [currentTradeIndex, setCurrentTradeIndex] = useState(50);
    const [tradePathSelectedID, setTradePathSelectedID] = useState();
    const [currentTradeValue, setCurrentTradeValue] = useState(50);
    const [projectsList, setProjectsList] = useState([]);
    const [projectData, setProjectData] = useState(initialFormData);
    const [projectParams, setProjectParams] = useState([]);
    const [showProjectParams, setShowProjectParams] = useState(false);

    const [showFrameworkProject, setShowFrameworkProject] = useState(false);
    const [showProjectList, setShowProjectList] = useState(true);

    const [pathList, setPathList] = useState([]);
    const [workflow, setWorkflow] = useState();



    useEffect(() => {



        getAllProjects();


    }, []);

    useEffect(() => {

        setShowProjectList(true);
        setShowFrameworkProject(false);


    }, [showProjectActive]);

    useEffect(() => {

        if (showProjectList) {
            setCurrentProject();
        }


    }, [showProjectList]);

    useEffect(() => {
        let data = require('../../../Constants/dataSourceTotal.json');
        setWorkflow(JSON.parse(JSON.stringify(data)));
        if (!editMode) {
            setPathList([]);
        }
    }, [editMode]);

    useEffect(() => {
        if (deleteMode) {
            setCurrentStep(0);
            setShowPopup(true);
        }
    }, [deleteMode]);

    useEffect(() => {
        // console.log(projectParams);

    }, [projectParams]);


    const transformJsonToNodesAndEdges = (json, parentId = null, nodes = [], edges = [], position = { x: 0, y: 0 }, level = 0, index = 0) => {
        const id = `node-${json.name}`;
        const offsetX = 250; // Ajusta la distancia horizontal entre nodos
        const offsetY = 200; // Ajusta la distancia vertical entre nodos
        const node = {
            id,
            type: 'default',
            data: { label: json.name + " " + (position.x + offsetX) + "/" + (position.y + (offsetY * (index - json.children.length / 2))) },
            position,
        };
        if (parentId == null) {
            node.sourcePosition = 'right';
        } else {
            node.targetPosition = 'left';
            node.sourcePosition = 'right';
        }

        nodes.push(node);


        if (parentId) {
            const edge = {
                id: `edge-${parentId}-${id}`,
                source: parentId,
                target: id,
                type: 'smoothstep',
            };
            edges.push(edge);
        }

        json.children.forEach((child, index) => {
            // console.log((position.x + offsetX) + "/" + (position.y + (offsetY * (index - json.children.length / 2))));
            transformJsonToNodesAndEdges(child, id, nodes, edges, {
                x: position.x + offsetX,
                y: position.y + (offsetY * (index - json.children.length / 2)),
            }, level + 1, index);
        });

        return { nodes, edges };
    };


    const { nodes, edges } = transformJsonToNodesAndEdges(json);




    const handleProjectDetails = (project) => {
        setProjectData({ ...project });
        if(project.is_valid){
            setShowAlertMessage(false);
        }
    }

    const onChangeTradeItem = (index, value) => {
        setCurrentTradeIndex(index)
        setCurrentTradeValue(value)
    }

    const showEditProject = (project) => {

        setEditMode(true);
        const { zip_code, gross_area, work_type, main_building_use } = project;

        const index = DATA_TRADES.findIndex(item => item.name === project.main_item_selection);

        setCurrentTradeIndex(index);
        setTradePathSelected(checkedItemTree(JSON.parse(project.project_item_selection)));
        setTradePathSelectedID(project.id);
        setPathList(JSON.parse(project.project_item_selection));
        setProjectData({
            zip_code: zip_code,
            gross_area: gross_area,
            work_type: work_type,
            main_building_use: main_building_use
        });

        setCurrentStep(1);
        setShowPopup(true);
    }

    const showFrameworkPanel = (project) => {
        const { zip_code, gross_area, work_type, main_building_use, uuid } = project;

        setTradePathSelected(JSON.parse(project.project_item_selection));
        setSupplementarySelected(project.supplementary_list);
        setProjectData({
            zip_code: zip_code,
            gross_area: gross_area,
            work_type: work_type,
            main_building_use: main_building_use,
            id: project.id
        });

        setCurrentProject(project.id)
        setShowFrameworkProject(true);
        setShowProjectList(false);
    }

    const showProjectListPanel = () => {
        setShowFrameworkProject(false);
        setShowProjectList(true);
    }

    const showAddParamsProject = (project) => {
        setProjectParams(JSON.parse(project.params));
        setCurrentStep(3);
        setShowPopup(true);
    }

    const updateProjectParamsData = (name, description, index, action) => {

        if (action === "UPDATE") {

            let objectParam = { param: "area_of_construction", area_name: name, area_description: description }

            setProjectParams((prevParams) => {
                // Creamos una copia del array actual
                const objectParams = [...prevParams];

                // Verificamos si el índice existe
                if (objectParams[index]) {
                    // Modificar el objeto en el índice dado
                    objectParams[index] = objectParam;
                }

                return objectParams;
            });
        } else if (action === "DELETE") {

            console.log(index);

            setProjectParams((prevParams) => {
                const paramList = [...prevParams];
                paramList.splice(index, 1);
                return paramList;
            });

        }

    }



    const checkedItemTree = (paths) => {

        for (const [index, path] of paths.entries()) {
            let auxDataTrade = { ...workflow[0].children };
            //  console.log(path);
            //let path = [0,1];
            for (let i = 0; i < path.length; i++) {
                const pos = path[i];
                if ((i + 1) != path.length) {
                    auxDataTrade[pos].visible = true;
                }
                if (!auxDataTrade[pos]) {
                    auxDataTrade[pos] = { children: [] };
                }
                if (i === path.length - 1) {
                    auxDataTrade[pos].checked = true;
                } else {
                    auxDataTrade = auxDataTrade[pos].children;
                }
            }
        }


        return workflow;
    }

    const closePopup = () => {
        setEditMode(false);
        setDeleteMode(false);
        setCurrentTradeIndex(50)
        setProjectDescription('');
        setCurrentStep(0);
        setShowPopup(false);
    }

    const getAllProjects = () => {
        axios
            .get(`${BASE_URL}/project`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setProjectsList(response.data.data.projects);
                closePopup();
            })
            .catch((error) => {

            });
    }

    const saveProject = () => {

        if (!projectData.is_valid) {
            setShowAlertMessage(true);
            return;
        }
        setShowLoader(true);


        try {

            axios.post(`${BASE_URL}/project`,
                {
                    project_data: projectData,
                    //main_item_selection: DATA_TRADES[currentTradeIndex].name,
                    path_list: pathList,
                    //project_item_selection: JSON.stringify(tradePathSelected)
                }, {
                headers: {
                    authorization: `Bearer ${token}`
                },
            })
                .then((response) => {


                    getAllProjects();
                    setShowLoader(false);
                    closePopup();
                    showFrameworkPanel(response.data.data.project);
                })
                .catch((error) => {

                });

        } catch (error) {

        }

    }

    const updateProject = (projectID, data = {}, path = [], supplementaryPath = [], parentSupplementaryPath = []) => {



        if (parentSupplementaryPath.length) {
            parentSupplementaryPath = parentSupplementaryPath[0].split("-").map(Number);
        }

        setShowLoader(true);

        try {

            axios.patch(`${BASE_URL}/project/` + projectID,
                {
                    project_data: data,
                    project_item_selection: path,
                    project_supplementary_path: supplementaryPath,
                    project_parent_supplementary_path: parentSupplementaryPath
                }, {
                headers: {
                    authorization: `Bearer ${token}`
                },
            })
                .then((response) => {
                    getAllProjects();
                    setShowLoader(false);
                })
                .catch((error) => {

                });

        } catch (error) {

        }

    }

    const updateParams = (projectID) => {
        console.log(projectID);

        setShowLoader(true);
        try {

            axios.patch(`${BASE_URL}/project/` + projectID,
                {
                    project_params: projectParams
                }, {
                headers: {
                    authorization: `Bearer ${token}`
                },
            })
                .then((response) => {
                    getAllProjects();
                    setShowLoader(false);
                })
                .catch((error) => {

                });

        } catch (error) {

        }
    }

    const deleteProject = (uuid) => {
        setShowLoader(true);
        try {

            axios.delete(`${BASE_URL}/project/` + uuid,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    },
                })
                .then((response) => {
                    getAllProjects();
                    setShowProjectList(true);
                    setShowFrameworkProject(false)
                    closePopup();
                    setShowLoader(false);

                })
                .catch((error) => {

                });

        } catch (error) {

        }

    }

    const requestReport = (uuid) => {

        try {

            axios.post(BASE_URL + '/project/report',
                {
                    uuid: uuid
                }, {
                headers: {
                    authorization: `Bearer ${token}`
                },
            }
            ).then(response => {
                console.log(response);
                downloadReport(uuid);
            }).catch(err => {
                console.log(err);
            });

        } catch (error) {
            console.error('Error downloading Excel file:', error);
        }

    }

    const downloadReport = (uuid) => {

        try {

            axios.get(BASE_URL + '/project/download-report/' + uuid, {
                responseType: 'blob', // Configurar responseType como blob para manejar archivos binarios
                headers: {
                    authorization: `Bearer ${token}`
                },

            }).then(response => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', uuid + '.xlsx');
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                console.log(err);
            });

        } catch (error) {
            console.error('Error downloading Excel file:', error);
        }

    }

    const updateGroupSelection = (data, pathArray) => {

        if (data) {
            setTradePathSelected(data);
        }

        if (pathArray) {
            setPathList(pathArray);
        }

    }

    // const nodes = [
    //     {
    //         id: '1',
    //         position: { x: 0, y: 0 },
    //     },
    // ];
    return (
        <div className='services_main_body'>
            <Loader text="Loading..." open={showLoader} />
            <div className={`modal small-popup ` + (showPopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className="modal-card ">
                    <section className="modal-card-body home-modal">
                        <div className="endos_panel2 clearfix">
                            {currentStep == 1 &&
                                <div className="step-one form-group">
                                    <ProjectForm projectData={projectData} editMode={editMode} currentIndex={currentTradeIndex} listItems={DATA_TRADES} onChange={handleProjectDetails} onChangeInputTrade={onChangeTradeItem}></ProjectForm>
                                </div>
                            }
                            {currentStep == 2 &&

                                (editMode ?
                                    <TreeTrades data={tradePathSelected} saveGroup={updateGroupSelection} pathArray={pathList} paidMembership={serviceProvider.user_type == 40 ? true : false} ></TreeTrades>
                                    :
                                    <TreeTrades data={[workflow[0]]} saveGroup={updateGroupSelection} pathArray={pathList} paidMembership={serviceProvider.user_type == 40 ? true : false}></TreeTrades>
                                )
                            }
                            {currentStep == 3 &&
                                <div className="step-one form-group">
                                    <ProjectParamsForm projectData={projectData} projectParams={projectParams} updateParams={updateProjectParamsData} paramsIndex={0} showParamsForm={setShowProjectParams}></ProjectParamsForm>
                                </div>
                            }
                            {(currentStep == 0 && deleteMode) &&
                                <div className='delete-text'>
                                    <img src="../images/warningAlert.png" width={300}></img>
                                    <h4>Are you sure about deleting this project? It's permanent.</h4>
                                </div>
                            }
                            {
                                showAlertMessage &&
                               <div className='bg-danger p-1 text-light rounded mb-2 text-center'>
                                   Project information is not valid
                               </div>
                               
                            }
                            <div className="map-button tree-map-button">
                                {currentStep == 1 &&
                                    <button
                                        type="button"
                                        onClick={() => { editMode ? updateProject(tradePathSelectedID) : saveProject(); }}
                                        className="btn-fill"
                                    >

                                        Save
                                    </button>
                                }
                                {currentStep == 2 &&
                                    <button
                                        type="button"
                                        onClick={() => { editMode ? updateProject(tradePathSelectedID) : saveProject(); }}
                                        className="btn-fill"
                                    >

                                        Save
                                    </button>
                                }
                                {deleteMode &&
                                    <button
                                        type="button"
                                        onClick={() => { deleteProject(currentProject) }}
                                        className="btn-fill"
                                    >

                                        Confirm
                                    </button>

                                }
                                {
                                    currentStep == 3 &&
                                    <>{showProjectParams &&
                                        <button
                                            type="button"
                                            onClick={() => { updateParams(tradePathSelectedID) }}
                                            className="btn-fill"
                                        >

                                            Confirm
                                        </button>
                                    }
                                        <button
                                            type="button"
                                            onClick={() => { closePopup(); setShowProjectParams(false) }}
                                            className="btn-fill bg-danger exit-trade-modal"
                                        >
                                            Close
                                        </button>

                                    </>
                                }

                                {currentStep !== 3 &&
                                    <button
                                        type="button"
                                        onClick={closePopup}
                                        className="btn-fill exit-trade-modal"
                                    >
                                        Close
                                    </button>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="container">
                <div className="title-module" style={{ marginBottom: "15px" }}>
                    {`Projects`}
                </div>
                <div className='d-flex flex-row justify-content-between title-panel'>
                    <h3 className=''></h3>
                    <div className=''>
                        {((serviceProvider.subscription.plan == 'Free' && projectsList.length == 0) || (serviceProvider.subscription.plan == 'Basic' && projectsList.length < 3)) &&
                            <span onClick={() => { setShowPopup(true); setCurrentStep(1) }}><i className="bi bi-plus-circle" > New Project</i></span>
                        }

                        {(currentProject) &&
                            < span onClick={() => { setCurrentStep(0); setDeleteMode(true); setShowPopup(true); }}><i className="bi bi-plus-circle text-danger" > Delete Project</i></span>

                        }

                    </div>

                </div>

                {showProjectList &&
                    <div className={`row-card-list ${projectsList.length > 3 ? "" : "no-scroll"}`}>

                        {projectsList.length > 0 &&
                            <>
                                {projectsList.map((item, index) => {
                                    return (
                                        <div className={`project card-license-info team-card `} onClick={() => showFrameworkPanel(item)} style={{ cursor: 'pointer' }}>

                                            <div className="team-item">
                                                <span>Zip code</span>
                                                <span>{item.zip_code}</span>
                                            </div>
                                            <div className="team-item ">
                                                <span>Gross area</span>
                                                <span>{item.gross_area}</span>
                                            </div>
                                            <div className="team-item ">
                                                <span>Work type</span>
                                                <span>{item.work_type}</span>
                                            </div>
                                            <div className="team-item ">
                                                <span>Main building use</span>
                                                <span>{item.main_building_use}</span>
                                            </div>


                                            <div className="team-item project-item py-2">

                                                {/* <span onClick={() => { showEditProject(item) }}>Edit</span> */}
                                                {/* <span onClick={() => { showFrameworkPanel(item) }}>Edit</span> */}
                                                {/* <span onClick={() => { showAddParamsProject(item); setTradePathSelectedID(item.id) }}>Params</span>
                                                <span onClick={() => { requestReport(item.project_uuid) }}>Download</span>
                                                <span onClick={() => { setDeleteMode(true); setCurrentProject(item.project_uuid) }}>Delete</span> */}

                                            </div>
                                        </div>
                                    )
                                })}
                            </>

                        }
                    </div>
                }

                {
                    showFrameworkProject &&
                    <div>
                        <WorkFlow
                            uuid={window.self.crypto.randomUUID()}
                            isChildren={true}
                            showFrameworkPanel={true}
                            data={projectData}
                            pathFramework={tradePathSelected}
                            pathSupplementary={supplementarySelected}
                            token={token}
                            serviceProvider={serviceProvider}
                            handleUpdate={updateProject} />
                    </div>
                }
                {/* <div style={{ height: '100vh' }}>
                    <ReactFlow nodes={nodes} edges={edges}>
                        <Background />
                        <Controls />
                    </ReactFlow>
                </div> */}

            </div>

        </div >
    )
}

export default Projects