import React, { useEffect, useState } from 'react'

function ProjectForm(props) {

    const initialFormData = {
        zip_code: '',
        gross_area: '',
        work_type: '',
        main_building_use: '',
        is_valid:false
    }

    const [projectData, setProjectData] = useState(initialFormData);
    const [currentIndex, setCurrentIndex] = useState(props.currentIndex);
    const [dataItems, setDataItems] = useState(props.listItems);

    const [isValidZipCode, setIsValidZipCode] = useState(true);


    useEffect(() => {

        if (props.editMode) {
            setProjectData(props.projectData)
        } else {
            setProjectData(initialFormData)
        }

    }, [props.editMode]);

    useEffect(() => {
        props.onChange(projectData);
    }, [projectData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        let changeValid = true;
        if (name === "zip_code") {
            if (!(/^\d*$/.test(value) && value.length <= 6 && value !== '')) {
                changeValid = false;
                setIsValidZipCode(changeValid);
            } else {
                changeValid = true;
                setIsValidZipCode(changeValid);
            }
        }

        setProjectData((projectData) => ({
            ...projectData,
            [name]: value,
            is_valid: changeValid
        }));
    };

    const onChange = (evt) => {
        props.onChangeInputTrade(evt.target.options[evt.target.selectedIndex].id, evt.target.value)
    }


    return (
        <>
            <form>
                <div className='form-group'>
                    <label htmlFor="zip_code">Zip code</label>
                    <input type="text" maxlength="6" pattern="\d*" id="zip_code" name="zip_code" key={'zip_code'} className="form-control" value={projectData.zip_code} onChange={handleChange} style={{ borderColor: isValidZipCode ? '' : 'red' }}></input>
                </div>
                <div className='form-group'>
                    <label htmlFor="gross_area">Gross area</label>
                    <input id="gross_area" name="gross_area" key={'gross_area'} className="form-control" value={projectData.gross_area} onChange={handleChange} ></input>
                </div>
                <div className='form-group'>
                    <label htmlFor="work_type">Work type</label>
                    <select className={"form-control"} id="work_type" name="work_type" value={projectData.work_type} onChange={handleChange}>
                        <option selected={true}>{(projectData.main_building_use == "" ? "Select work type" : projectData.work_type)}</option>
                        {/* <option selected={true}>Select work type</option> */}
                        <option>New Construction</option>
                        <option>Remodeling</option>
                        <option>Renovation/Repair</option>
                        <option>Enlargement</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor="main_building_use">Main building use</label>
                    <select className="form-control" id="main_building_use" name="main_building_use" key={'main_building_use'} value={projectData.main_building_use} onChange={handleChange} >
                        {/* Mapea el array de opciones y crea un elemento option por cada elemento */}
                        <option id={50} value={50} disabled selected>Choose an option</option>
                        <option selected={true}>{(projectData.main_building_use == "" ? "Add infrastructure" : projectData.main_building_use)}</option>
                        <option>Single / Two family home</option>
                        <option>Multifamily property (3+ units)</option>
                        <option>Commercial: (Offices, Flex space, Medical offices).</option>
                        <option>Retail</option>
                        <option>Hospitality: (Hotel, Restaurants, Event venues)</option>
                        <option>Warehouses, logistics, and storage</option>
                        <option>Data Centers</option>
                        <option>Manufacturing</option>
                        <option>Schools </option>
                        <option>Universities</option>
                        <option>Health care facilities</option>
                        <option>Infrastructure</option>
                    </select>
                </div>
            </form>
        </>
    )
}

export default ProjectForm