import React from "react";

const ServiceSteps = ({ moveToStep, service }) => {
  return (
    <div>
      <div className="p_services_selected clearfix">
        <ul id="progressbar">
          <li className={service === "selection" ? "active" : null}>
            <a
              onClick={() => {
                if (moveToStep) {
                  moveToStep(2);
                }
              }}
            >
              <span style={{ fontWeight: "700", fontSize: "18px" }}>
                Step 1
              </span>{" "}
              <br />
              Services Selection
            </a>
          </li>
          <li className={service === "portfolios" ? "active" : null}>
            <a
              onClick={() => {
                if (moveToStep) {
                  moveToStep(3);
                }
              }}
            >
              <span style={{ fontWeight: "700", fontSize: "18px" }}>
                Step 2
              </span>{" "}
              <br />
              Portfolios
            </a>
          </li>
          <li className={service === "coverage" ? "active" : null}>
            <a
              onClick={() => {
                if (moveToStep) {
                  moveToStep(4);
                }
              }}
            >
              <span style={{ fontWeight: "700", fontSize: "18px" }}>
                Step 3
              </span>{" "}
              <br />
              coverage
            </a>
          </li>
          <li className={service === "paremeters" ? "active" : null}>
            <a
              onClick={() => {
                if (moveToStep) {
                  moveToStep(5);
                }
              }}
            >
              <span style={{ fontWeight: "700", fontSize: "18px" }}>
                Step 4
              </span>{" "}
              <br />
              Service Paremeters
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ServiceSteps;
