import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons"; */
import styles from "./VendorDetails.module.css";
import axios from "axios";
// import { useToasts } from "react-toast-notifications";
import ShowProfile from "./ShowProfile";
import { BASE_URL } from "../../../Constants/serverConfig";
import CompanyInfo from "./CompanyInfo";
/* import moment from "moment";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"; */

const LicencesAdded = (props) => {
  const [licencesAdded, setLicencesAdded] = useState([]);
  const [disableDeactivate, setDisableDeactivate] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [name, setName] = useState(null);
  const { token, serviceProvider } = props;
  // const { addToast } = useToasts();

  useEffect(() => {
    fetchLicences();
  }, []);

  useEffect(() => {
    fetchLicences();
  }, [selectedProfile]);

  useEffect(() => {
    const _name = licencesAdded.map((item) => {
      return item.first_name.trim() + " " + item.last_name.trim() ;
    });
    setName(_name);
  }, [licencesAdded]);

  const handleShowNewAccountButon = () => {
    props.handleShowNewAccountButon(false);
  }

  const fetchLicences = async () => {
    try {
      let res = await axios.get(
        `${BASE_URL}/license-management/request`,
        // `${BASE_URL}/license-management/account`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        if (res.data.length !== 0) {
          console.log("License fetched in getCall: ", res.data);
          setLicencesAdded(res.data.request_list);
        }
      }
    } catch (err) {
      console.error("Something went wrong while fetching Licence added: ", err);
      // addToast("Could not fetch added licences.", {
      //   appearance: "error",
      // });
    }
  };

  const handeDeactivate = (accountId) => {
    setDisableDeactivate(true);
    axios
      .patch(
        `${BASE_URL}/license-management/inactive/account/${accountId}`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          fetchLicences();
          // addToast("Licence Deactivated.", {
          //   appearance: "success",
          // });
        }
        setDisableDeactivate(false);
      })
      .catch((err) => {
        console.error(
          "Something went wrong while de-activating a licence: ",
          err
        );
        // addToast("Could not deactivate licence.", {
        //   appearance: "error",
        // });
        setDisableDeactivate(false);
      });
  };

  const handeDelete = (accountId) => {
    setDisableDelete(true);
    axios
      .delete(
        `${BASE_URL}/license-management/account/${accountId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          fetchLicences();
          // addToast("Licence Deleted.", {
          //   appearance: "success",
          // });
        }
        setDisableDelete(false);
      })
      .catch((err) => {
        console.error("Something went wrong while deleting a licence: ", err);
        // addToast("Could not delete licence.", {
        //   appearance: "error",
        // });
        setDisableDelete(false);
      });
  };

  const fetchData = (accountId) => {
    let account = licencesAdded.filter(
      (licence) => licence.id === accountId
    )[0];
    console.log("account ==> ", account);
    setSelectedProfile(account);
    // axios
    //   .patch(
    //     `${BASE_URL}/account/licensed-profile/${account.id}`,
    //     {
    //       first_name: "testing-fname",
    //       last_name: "testing-lname",
    //       email_address: "testing-Email",
    //       street_address: "testing-String",
    //       city: "testing-String",
    //       state: "testing-String",
    //       zip_code: 0,
    //     }
    //   )
    //   .then((res) => {
    //     console.log("vendor response ==> ", res);
    //   })
    //   .catch((err) => {
    //     console.log("Vendor error ==> ", err);
    //   });
  };

  const handleSeeProfile = (accountId) => {
    handleShowNewAccountButon();
    setShowProfile(true);
    let account = licencesAdded.filter(
      (licence) => licence.id === accountId
    )[0];
    console.log("account ==> ", account);
    setSelectedProfile(account);
    fetchData(accountId);
  };

  const vendorUpdated = (status = true) => {
    if (!!status) {
      fetchLicences();
      // addToast("Vendor details updated successfully.", {
      //   appearance: "success",
      // });
    }
  };
  return (
    <div>      

      {!selectedProfile &&
        // <table className="table is-striped" style={{ width: "100%" }}>
        //   <thead style={{ border: "0px solid #a9a9b7" }}>
        //     <tr>
        //       <th className={Styles.tableStyle}>Name</th>
        //       {/* <th className={Styles.tableStyle}>Last Name</th> */}
        //       <th className={Styles.tableStyle}>Email</th>
        //       <th className={Styles.tableStyle}>Coverage Area</th>
        //       <th className={Styles.tableStyle}>Trade</th>
        //       {/* <th className={Styles.tableStyle}>Exp. date</th>
        //     <th className={Styles.tableStyle}>Status</th> */}
        //       <th className={Styles.tableStyle}>
        //         <div
        //           style={{
        //             display: "flex",
        //             width: "100%",
        //             justifyContent: "center",
        //           }}
        //         >
        //           Actions
        //         </div>
        //       </th>
        //     </tr>
        //   </thead>
        //   <tbody style={{ border: "0px solid #a9a9b7" }}>

        //     {!!licencesAdded &&
        //       !!licencesAdded.length &&
        //       !!name &&
        //       licencesAdded.map((licence, index) => {
        //         return (
        //           <tr key={index}>
        //             <td className={Styles.tableStyle}>
        //               {name[index]}
        //               {/*  {licence.user_id.first_name} */}
        //             </td>
        //             {/* <td className={Styles.tableStyle}>
        //             // {licence.user_id.last_name}
        //           </td> */}
        //             <td className={Styles.tableStyle}>
        //               {licence.receiver_email}
        //               {/*  {licence.user_id.email_address} */}
        //             </td>
        //             <td className={Styles.tableStyle}>
        //               {licence.last_login}
        //               {/*  {licence.user_id.email_address} */}
        //             </td>
        //             {/* <td className={Styles.tableStyle}>
        //             {" "}
        //             {moment(licence.expiry_at).format("MMM Do YY")}
        //           </td>

        //           <td className={Styles.tableStyle}>
        //             {" "}
        //             {!!licence.active ? "Active" : "Pending"}
        //           </td> */}
        //             <td className={Styles.tableStyle}>
        //               <div
        //                 style={{
        //                   display: "flex",
        //                   marginRight: "5px",
        //                   alignItems: "center",
        //                   justifyContent: "space-around",
        //                 }}
        //               >
        //                 <div
        //                   style={{
        //                     color: "#35309f",
        //                     textDecoration: "underline",
        //                     cursor: "pointer",
        //                   }}
        //                   onClick={() => handleSeeProfile(licence.id)}
        //                 >
        //                   See profile
        //                 </div>                        
        //               </div>
        //             </td>
        //           </tr>
        //         );
        //       })}
        //   </tbody>
        // </table>
        <div className={`` + styles.rowCardList}>
          {!!licencesAdded &&
            !!licencesAdded.length &&
            !!name &&
            licencesAdded.map((licence, index) => {
              return (
                <div className={`` + styles.cardLicenseInfo} onClick={() => handleSeeProfile(licence.id)}>

                  <div className={styles.fields}>
                    <span>Name</span>
                    <span data-type="card-text" className="card-text"> {name[index]}</span>
                  </div>
                  <div className={styles.fields}>
                    <span>Email</span>
                    <span data-type="card-text" className="card-text">{licence.email_address}</span>
                  </div>
                  <div className={styles.fields}>
                    <span>Coverage Area</span>
                    <span data-type="card-text" className="card-text">New York</span>
                  </div>
                  <div className={styles.fields}>
                    <span>Trades</span>
                  </div>
                  <div className={styles.tradeList}>
                    <span>Electrical</span>

                    <span>Concrete</span>
                    <span>Fire Suppression</span>
                    <span>Plumbing</span>
                  </div>
                </div>

              )
            })}
        </div>
      }

      {
        false &&
        licencesAdded.map((licence, index) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0px 20px' }}>
              <span style={{ textTransform: 'capitalize' }}>{licence.first_name}</span>
              <span style={{
                color: "#35309f",
                textDecoration: "underline",
                cursor: "pointer",
              }}
                onClick={() => handleSeeProfile(licence.id)}
              >
                See profile</span>
            </div>
          )
        })
      }

      {selectedProfile && (
        <ShowProfile
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}          
          token={token}
          vendorUpdated={vendorUpdated}
          serviceProvider={serviceProvider}
        />
        // <CompanyInfo
        //   serviceProvider={selectedProfile}
        //   token={token}
        //   saveServiceProvider={
        //     ()=>{}
        //   }
        //   isLicense={true}
        // />
      )}
    </div>
  );
};

export default LicencesAdded;
