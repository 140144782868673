import React, { useState, useEffect } from "react";
import Endorsement from "../../HelperComponents/Endorsements/endorsement";
import Reviews from "../../HelperComponents/Reviews/reviews";
import axios from "axios";
import { BASE_URL } from "../../../Constants/serverConfig";
import Loader from "../../Loader/loader";
// import RemoveIcon from "@mui/icons-material/Remove";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Typography } from "@mui/material";
import { isMobile } from 'react-device-detect';
import styles from "../../Dashboard/components/CompanyInfo.module.css";
import "../../Dashboard/components/dashboard.css";
const OnlineReputationAndPromotionalTools = ({
  servicesPromoted,
  token,
  showSuccess,
  clearMessage,
  serviceProvider,
  showPromotionalPanel,
  handlePromotionalPanel,
  currentPromotionalForm
}) => {
  const [articleWriting, setArticleWriting] = useState(false);
  const [currentPromotionStep, setCurrentPromotionStep] = useState(1);
  const [qa, setQa] = useState(false);
  const [workInTeam, setWorkInTeam] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showBestPractices, setShowBestPractices] = useState(false);
  const [showEndorsementForm, setShowEndorsementForm] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [showSurveyForm, setshowSurveyForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projectName, setProjectName] = useState(
    "68-19 Woodhaven Blvd. Queens"
  );

  useEffect(() => {
    if (showEndorsementForm || showReviewForm || showSurveyForm) {
      handlePromotionalPanel(false);

      if (showEndorsementForm) {
        currentPromotionalForm("Endorsement")
      } else if (showReviewForm) {
        currentPromotionalForm("Review")
      }
      else if (showSurveyForm) {
        currentPromotionalForm("Survey")
      }
    }

  }, [showEndorsementForm, showReviewForm, showSurveyForm])

  useEffect(() => {
    if (showPromotionalPanel) {
      setShowEndorsementForm(false);
      setShowReviewForm(false);
      setshowSurveyForm(false);
    }
  }, [showPromotionalPanel])

  const startLoader = () => {
    setShowLoader(true);
  };

  const stopLoader = () => {
    setShowLoader(false);
  };
  const handleShowError = (errorMessage) => {
    setShowError(true);
    setErrorMessage(errorMessage);
  };
  const clearError = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const savePreferences = () => {
    if (token !== null) {
      const body = {
        write_article: articleWriting ? 1 : 0,
        replying_user: qa ? 1 : 0,
        join_team: workInTeam ? 1 : 0,
      };
      startLoader();
      axios
        .post(`${BASE_URL}/user-preference`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            showSuccess("Preferences updated successfully");
          }
        })
        .catch((error) => {
          stopLoader();
          handleShowError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
        });
    }
  };

  return (
    <>
      <Loader text="Loading..." open={showLoader} />
      {showError && (
        <div className="modal is-active custom-modal error-message">
          <div className="modal-background" />
          <div className="modal-content">
            <div className="error-message-modal">
              <button onClick={clearError} className="modal-close" />
              <p>{errorMessage}</p>
              <a onClick={clearError} className="btn-fill">
                OK
              </a>
            </div>
          </div>
        </div>
      )}

      {showBestPractices &&

        <div className="modal is-active custom-modal">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="success-message-modal" style={{ backgroundColor: "white" }}>
              <button
                onClick={() => { setShowBestPractices(false); setCurrentPromotionStep(1) }}
                className="modal-close"
              ></button>
              <div className="">

                {currentPromotionStep == 1 ?
                  <div className={styles.promotionStepOne}>
                    <img src="../images/reviewsendorsement.png" width={400} />
                    <p className="has-text-justified">
                      By using this tool you can build the reputation that will lead
                      potential clients to trust you and decide to make the call. You can
                      request endorsements from people who can certify that you have the
                      skills, request reviews from previous or current customers. And for
                      those who are foremen or subcontractors who would benefit from showing
                      to have large teams, we have a tool to invite laborers to join your
                      team.
                    </p>
                    <br />
                    <button type="button" className="btn btn-primary" onClick={() => setCurrentPromotionStep(2)}>
                      Best Practices
                    </button>
                    <br />
                  </div>
                  :
                  <div className={styles.promotionStepTwo}>
                    <p style={{ textAlign: "center", fontSize: "20px", fontWeight: 500 }}>
                      Best Practices
                    </p>
                    <Box style={{ display: "flex" }}>
                      {/* <RemoveIcon fontSize="small" /> */}
                      <ArrowRightAltIcon className="arrow-icon" />
                      <Typography style={{ textAlign: 'justify' }}>
                        <b>For most effective promotion results,</b> we recommended that you{" "}
                        <b>
                          request endorsements and reviews from the most influential people
                          and companies you know{" "}
                        </b>
                        in the industry to have a significant network of contacts, those
                        will most likely also share your profile with their networks
                      </Typography>
                    </Box>
                    <br></br>
                    <Box style={{ display: "flex" }}>
                      {/* <RemoveIcon fontSize="small" /> */}
                      <ArrowRightAltIcon className="arrow-icon"  />
                      <Typography style={{ textAlign: 'justify' }}>
                        It is wise to <b>follow up with a call</b> to everyone you send a
                        request to, just in case they are too busy or have missed the email.
                      </Typography>
                    </Box>
                    <br></br>
                    <Box style={{ display: "flex" }}>
                      {/* <RemoveIcon fontSize="small" /> */}
                      <ArrowRightAltIcon className="arrow-icon"  />
                      <Typography style={{ textAlign: 'justify' }}>
                        Confirm that the email was received. Some email settings send
                        unknown emails to the spam folder. Let them know this request comes
                        from <a href="mailto:info@propagile.com">info@propagile.com</a> on
                        your behalf.
                      </Typography>
                    </Box>
                    <br></br>
                    <Box style={{ display: "flex" }}>
                      {/* <RemoveIcon fontSize="small" /> */}
                      <ArrowRightAltIcon className="arrow-icon"  />
                      <Typography style={{ textAlign: 'justify' }}>
                        A ranking system will rank the most trustworthy and upvoted
                        profiles. Keeping yours relevant and current with updated
                        endorsements and reviews will help you be listed at the top of the
                        list.
                      </Typography>
                    </Box>
                    <br />
                  </div>
                }
              </div>

            </div>
          </div>
        </div>

      }

      {/* Main Content */}
      {/* <Box style={{ textAlign: "center" }}>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          Online Reputation Management
        </Typography>
      </Box> */}
      {/* <div className="promo-main clearfix"> */}
      <div className={styles.promotionMainPanel}>
        <div className={styles.profileInformationPanel + " d-none"}>
          <div className={styles.itemsReputationPanel}>


            <p className="has-text-justified">
              By using this tool you can build the reputation that will lead
              potential clients to trust you and decide to make the call. You can
              request endorsements from people who can certify that you have the
              skills, request reviews from previous or current customers. And for
              those who are foremen or subcontractors who would benefit from showing
              to have large teams, we have a tool to invite laborers to join your
              team.
            </p>
            <br />
            <p style={{ fontWeight: 600, color: "#c00000" }}>
              Select any of the forms below to invite by simply entering names and
              email addresses.
            </p>
            <br />
            <p style={{ textAlign: "center", fontSize: "20px", fontWeight: 500 }}>
              Best Practices
            </p>
            <Box style={{ display: "flex" }}>
              {/* <RemoveIcon fontSize="small" /> */}
              <ArrowRightAltIcon />
              <Typography style={{ textAlign: 'justify' }}>
                <b>For most effective promotion results,</b> we recommended that you{" "}
                <b>
                  request endorsements and reviews from the most influential people
                  and companies you know{" "}
                </b>
                in the industry to have a significant network of contacts, those
                will most likely also share your profile with their networks
              </Typography>
            </Box>
            <br></br>
            <Box style={{ display: "flex" }}>
              {/* <RemoveIcon fontSize="small" /> */}
              <ArrowRightAltIcon />
              <Typography style={{ textAlign: 'justify' }}>
                It is wise to <b>follow up with a call</b> to everyone you send a
                request to, just in case they are too busy or have missed the email.
              </Typography>
            </Box>
            <br></br>
            <Box style={{ display: "flex" }}>
              {/* <RemoveIcon fontSize="small" /> */}
              <ArrowRightAltIcon />
              <Typography style={{ textAlign: 'justify' }}>
                Confirm that the email was received. Some email settings send
                unknown emails to the spam folder. Let them know this request comes
                from <a href="mailto:info@propagile.com">info@propagile.com</a> on
                your behalf.
              </Typography>
            </Box>
            <br></br>
            <Box style={{ display: "flex" }}>
              {/* <RemoveIcon fontSize="small" /> */}
              <ArrowRightAltIcon />
              <Typography style={{ textAlign: 'justify' }}>
                A ranking system will rank the most trustworthy and upvoted
                profiles. Keeping yours relevant and current with updated
                endorsements and reviews will help you be listed at the top of the
                list.
              </Typography>
            </Box>
            <br />
            <br />
          </div>
        </div>

        {/* {!(showEndorsementForm || showReviewForm || showSurveyForm) && */}
        {showPromotionalPanel ?
          <>
            <div className="title-panel">
              <div></div>
              <div>
                <span onClick={() => setShowBestPractices(true)}><i className="bi bi-question-circle">&nbsp;Learn more</i></span>
              </div>
            </div>
            <div className="account-options account-reputation">
              <div className="option-panel reputation" onClick={() => setShowEndorsementForm(true)} >
                <img src="../images/endorsementx80.png" alt="profile" width={50} />
                <span>Endorsement Request</span>
              </div>
              <div className="option-panel reputation" onClick={() => setShowReviewForm(true)} >

                <img src="../images/reviewx80.png" alt="profile" width={50} />
                <span>Review Request</span>

              </div>
              {/* pending 2024 */}
              {/* <div className="option-panel" onClick={() => setshowSurveyForm(true)}>

                <img src="../images/surveyx80.png" alt="profile" width={50} />
                <span>Aplication Survey</span>

              </div> */}
              
            </div>
          </>
          :
          <>
            <div className="promo-main clearfix">
              {/* Endorsement Form */}
              {showEndorsementForm &&
                <Endorsement
                  showSuccess={showSuccess}
                  clearMessage={clearMessage}
                  activeServices={servicesPromoted}
                  token={token}
                  type={"endorsement"}
                  hasMembership={
                    serviceProvider !== null
                      ? serviceProvider.membership_information.membership_name !==
                        undefined
                        ? true
                        : false
                      : false
                  }
                  serviceProvider={serviceProvider}
                />
              }
              {/* Review Form */}
              {showReviewForm &&
                <Endorsement
                  showSuccess={showSuccess}
                  clearMessage={clearMessage}
                  activeServices={servicesPromoted}
                  token={token}
                  type={"review"}
                  hasMembership={
                    serviceProvider !== null
                      ? serviceProvider.membership_information.membership_name !==
                        undefined
                        ? true
                        : false
                      : false
                  }
                  serviceProvider={serviceProvider}
                />
              }

              {/* Team Invitation Form will come here. It does not exist as of yet - Dec 13th, 2022 */}
              <div className="form-group d-none">

                {isMobile ?
                  <>
                    <label style={{ textAlign: 'center' }}>
                      Request laborers to join your team{" "}
                    </label>
                    <h6>
                      Only laborers with a free acoount can join you team
                    </h6>
                  </>
                  :
                  <label>
                    Request laborers to join your team{" "}
                    <span>(Only laborers with a free acoount can join you team)</span>
                  </label>
                }
                <button

                  className="btn-empty"
                >
                  Team Invitation Form
                </button>

              </div>



              {/* 3 CheckBoxes Above submit button */}
              {showSurveyForm &&
                <div className="account-options" >

                  <div className="option-panel-survey">
                    <div className="form-check survey-options">
                      <input
                        id="check1"
                        className="form-check-input"
                        type="checkbox"
                      // checked={articleWriting}
                      />
                      <label className="form-check-label" htmlFor="check1">

                        I would like to write
                        articles relevant to my expertise and publish them here
                      </label>
                    </div>
                    <div className="form-check survey-options">
                      <input
                        id="check2"
                        className="form-check-input"
                        type="checkbox"
                      // checked={articleWriting}
                      />
                      <label className="form-check-label" htmlFor="check2">

                        I would like to be part of
                        Q&A and answer customer questions via text
                      </label>
                    </div>
                    <div className="form-check survey-options">
                      <input
                        id="check3"
                        className="form-check-input"
                        type="checkbox"
                      // checked={articleWriting}
                      />
                      <label className="form-check-label" htmlFor="check3">

                        I would like to form teams
                        and be found online also as a team
                      </label>
                    </div>
                    {/* <div
                      onClick={() => setArticleWriting(!articleWriting)}
                      className="checkkk clearfix"
                    >
                      <div onClick={(e) => e.preventDefault()} className="checkbox">
                        <label
                          onClick={(e) => e.preventDefault()}
                          className={articleWriting ? "is-active-checkbox" : "checkbox"}
                        >
                          <input
                            onChange={() => { }}
                            onClick={(e) => e.preventDefault()}
                            checked={articleWriting}
                            type="checkbox"
                          />
                          <span className="checkmark"></span>I would like to write
                          articles relevant to my expertise and publish them here
                        </label>
                      </div>
                    </div>
                    <div onClick={() => setQa(!qa)} className="checkkk clearfix">
                      <div onClick={(e) => e.preventDefault()} className="checkbox">
                        <label
                          onClick={(e) => e.preventDefault()}
                          className={qa ? "is-active-checkbox" : "checkbox"}
                        >
                          <input
                            onChange={() => { }}
                            onClick={() => (e) => e.preventDefault()}
                            checked={qa}
                            type="checkbox"
                          />
                          <span className="checkmark"></span>I would like to be part of
                          Q&A and answer customer questions via text
                        </label>
                      </div>
                    </div>
                    <div
                      onClick={() => setWorkInTeam(!workInTeam)}
                      className="checkkk clearfix"
                    >
                      <div onClick={(e) => e.preventDefault()} className="checkbox">
                        <label
                          onClick={(e) => e.preventDefault()}
                          className={workInTeam ? "is-active-checkbox" : "checkbox"}
                        >
                          <input
                            onChange={() => { }}
                            onClick={(e) => e.preventDefault()}
                            checked={workInTeam}
                            type="checkbox"
                          />
                          <span className="checkmark"></span>I would like to form teams
                          and be found online also as a team
                        </label>
                      </div>
              </div> */ }
                    <div className="survey-options">
                      <button
                        type="button"
                        onClick={savePreferences}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </>
        }
      </div>
    </>
  );
};

export default OnlineReputationAndPromotionalTools;
