import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const CollapsibleList = ({ items, level = 0, prefix = '' }) => {
  return (
    <ol style={{ paddingLeft: '20px', listStyleType: 'none' }}>
      {items.map((item, index) => {
        const currentNumber = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        return <CollapsibleItem key={item.id} item={item} level={level} currentNumber={currentNumber} />;
      })}
    </ol>
  );
};

const CollapsibleItem = ({ item, level, currentNumber }) => {
  const [isOpen, setIsOpen] = useState(false); // true if all open

  const toggle = () => setIsOpen(!isOpen);

  return (
    <li style={{ marginLeft: `${0}px` }}>
      <Button variant="link" onClick={toggle} style={{ textDecoration: 'none' }}>
         <input type="checkbox" id="horns" name="horns" /> {currentNumber} {item.name}
      </Button>
      {isOpen && item.children.length > 0 && (
        <CollapsibleList items={item.children} level={level + 1} prefix={currentNumber} />
      )}
    </li>
  );
};

export default CollapsibleList;
