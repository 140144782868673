import React, { useState, useEffect } from "react";
import styles from "./VendorDetails.module.css";
import { bindActionCreators, compose } from "redux";
import {
  setNewRequestList,
  setLicenseAvailable,
} from "../../../Actions/Vendors";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { compose } from "recompose";
import { fetchNewInvitees } from "../../../utils/fetchNewInvitees";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BASE_URL } from "../../../Constants/serverConfig";

const NewInvitees = (props) => {
  const { token } = props;

  useEffect(() => {
    // fetchNewInvitees(token, props.setNewRequestList, props.setLicenseAvailable);
  }, []);

  //old flow remove when new flow is approved.
  // const onDeleteRequest = (id) => {
  //   axios
  //     .delete(`${BASE_URL}/license-management/request/${id}`, {
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         fetchNewInvitees(
  //           token,
  //           props.setNewRequestList,
  //           props.setLicenseAvailable
  //         );
  //       }
  //     })
  //     .catch((err) => console.log("Something went wrong: ", err));
  // };

  const onDeleteRequest = (invitee) => {
    let newreqList = props.requestList.filter(
      (request) => request.email_address !== invitee.email_address
    );
    props.setNewRequestList([...newreqList]);
  };

  return (
    <div>
      <div
        className={styles.heading}
        style={{ marginTop: "1.5em", marginBottom: "0.5em" }}
      >{`Users to invite`}</div>
      <table className={styles.tableDetails} >
        <thead style={{ border: "1px solid #a9a9b7" }}>
          <tr>
            <th className={styles.tableStyle}>Name</th>
            <th className={styles.tableStyle}>Email</th>
            <th className={styles.tableStyle}>Actions</th>
          </tr>
        </thead>
        <tbody style={{ border: "1px solid #a9a9b7" }}>
          {!!props.requestList.length &&
            props.requestList.map((request, index) => {
              return (
                <tr style={{ border: "1px solid #a9a9b7" }} key={index}>
                  <td className={styles.tableStyle}>{request.guest_name}</td>
                  <td className={styles.tableStyle}>{request.email_address}</td>
                  <td
                    className={styles.tableStyle}
                    style={{
                      color: "#35309f",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => onDeleteRequest(request)}
                  >
                    Delete
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    requestList: state.Vendors.requestList,
    licenseAvailable: state.Vendors.licenseAvailable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setNewRequestList,
      setLicenseAvailable,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NewInvitees);
