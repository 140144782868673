import React, { useState, useEffect, useRef } from 'react';
import CollapsibleList from './CollapsibleList';
import CollapsibleListView from './CollapsibleListView';
import 'bootstrap/dist/css/bootstrap.min.css';
import { resolveTree } from '../../../Helpers/CheckTreeHelper';


function InteractivePanel({ propsData, close, save, download, type, format = 'framework' }) {

  const [path, setPath] = useState([]);
  const [data, setData] = useState(propsData);

  const [seconds, setSeconds] = useState(0); // Contador de segundos
  const [lastCompletedTime, setLastCompletedTime] = useState(null); // Última vez que se cumplió 60 segundos

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => {
        if (prev === 3599) {
          const now = new Date();
          setLastCompletedTime(now); // Guardar la hora actual cuando se cumplen los 60 segundos
          handleAutoSave();
          return 0; // Reiniciar contador
        }        
        return prev + 1;
      });
    }, 1000); // Incrementar cada segundo

    return () => clearInterval(interval); // Limpiar intervalo al desmontar
  }, []);

  // Calcular hace cuántos minutos fue la última vez
  const timeAgo = lastCompletedTime
    ? Math.floor((new Date().getTime() - lastCompletedTime.getTime()) / 60000)
    : null;


  const collapsibleRef = useRef(null);

  useEffect(() => {
    if (path.length) setData(resolveTree(path, data));
  }, [])

  useEffect(() => {
    setData(propsData)
  }, [propsData])

  const handleSave = () => {
    if (collapsibleRef.current) {
      const selectedItems = collapsibleRef.current.saveCheckedItems();
      save(selectedItems, false);
      //console.log('Selected Items:', selectedItems);
    }
  };
  const handleAutoSave = () => {
    if (collapsibleRef.current) {
      const selectedItems = collapsibleRef.current.saveCheckedItems();
      save(selectedItems, true);
      //console.log('Selected Items:', selectedItems);
    }
  };

  return (
    <>
      <div className='d-flex flex-row justify-content-between pl-md-4 '>
        {lastCompletedTime && 
        <div className='d-flex flex-row justify-content-start w-100 align-items-center'>
          <i class="bi bi-floppy-fill" style={{ fontSize: '14px', color: 'gray' }}> </i>
          <span className="d-flex flex-row justify-content-start w-100 align-items-center" style={{ fontSize: '14px', margin: '0px', color: 'gray', fontStyle: 'italic' }}>&nbsp;&nbsp;Auto Save - {timeAgo} min ago</span>
        </div>
        }

        <div className='d-flex flex-row justify-content-end w-100 align-items-center'>
          {type === 'edit' && <span style={{ border: '1px solid white', borderRadius: '5px', margin: '5px 20px', padding: '5px 10px', backgroundColor: '#1a67a3', color: 'white', cursor: 'pointer' }} onClick={handleSave}>Save Framework</span>}
          {(type === 'show' && format === 'framework') && <span style={{ border: '1px solid white', borderRadius: '5px', margin: '5px 20px', padding: '5px 10px', backgroundColor: '#1a67a3', color: 'white', cursor: 'pointer' }} onClick={() => { download(data.id) }}>Download Framework</span>}
          {(type === 'show' && format === 'wbs') && <span style={{ border: '1px solid white', borderRadius: '5px', margin: '5px 20px', padding: '5px 10px', backgroundColor: '#1a67a3', color: 'white', cursor: 'pointer' }} onClick={() => { download(data.id) }}>Download Table of Content</span>}
          <span style={{ color: 'red', cursor: 'pointer' }} onClick={close}>Close</span>
        </div>
      </div>
      <div className="p-0 pl-md-4">
        {type === 'edit' && <CollapsibleList ref={collapsibleRef} items={data.children} padding={0} />}
        {type === 'show' && <CollapsibleListView items={data.children ?? []} padding={0} format={format} />}
      </div>
    </>
  );
}

export default InteractivePanel;
