import React from 'react';
import SectionItem from '../Atoms/SectionItem';
import SubscriptionInformation from './SubscriptionInformation';
import InviteToSignup from './InviteToSignup';

const SectionMenu = ({ items, selectedPhase, isSelectedProject, subscription, userEmail, handleShowSubscriptionsWall }) => {

  return (
    <div className="section-menu">
      {items.map((item, index) => (
        <SectionItem
          id={item.id}
          label={item.label}
          onClick={item.onClick}
          active={item.label === selectedPhase}
          isSelectedProject={isSelectedProject}
          subscription={subscription.plan}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        />
      ))}
      {
        subscription.plan === "Free" &&
        <SubscriptionInformation userEmail={userEmail} />
      }
      {
        subscription.plan === "no-plan" &&
        <InviteToSignup handleShowSubscriptionsWall={handleShowSubscriptionsWall} />
      }

    </div>
  )
};

export default SectionMenu;
