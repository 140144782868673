/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../../Constants/serverConfig";
import ServiceSteps from "./ServiceSteps";

class ServicesKeywordsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionType: "",
      selectedLineOfServiceTab: 0,
      selectedTab: "promoted",
      selectedPromotedServices: [],
      selectedActiveKeywords: [],
      selectedInactiveKeywords: [],
      serviceType: "promoted",
      selectedActiveServices: [],
      selectedInactiveServices: [],
      showLoader: false,
      showSuccess: false,
      showError: false,
      loadingMessage: "",
      message: "",
      errorMessage: "",
      showDeleteConfirmation: false,
      showPassword: false,
      password: "",
      serviceToDelete: null,
    };
    this.serviceChildRef = React.createRef();
  }

  componentDidMount() {
    let elements = document.getElementsByClassName("popup-item-boxes");
    this.equalHeight(elements);
  }

  componentDidUpdate() {
    let elements = document.getElementsByClassName("popup-item-boxes");
    this.equalHeight(elements);
  }

  equalHeight = (elements) => {
    let tallest = 0;
    for (let index = 0; index < elements.length; index++) {
      let thisHeight = elements[index].offsetHeight;
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    }
    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      element.style.height = `${tallest}px`;
    }
  };

  showDeleteConfirmationMessage = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  hideDeleteConfirmationMessage = () => {
    this.setState({
      showDeleteConfirmation: false,
      serviceToDelete: null,
    });
  };

  showPasswordBox = () => {
    this.setState({
      showDeleteConfirmation: false,
      showPassword: true,
      password: "",
    });
  };

  hidePasswordBox = () => {
    this.setState({
      showPassword: false,
      password: "",
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setActionType = (action) => {
    console.log("action ==> ", action);
    const {
      selectedPromotedServices,
      selectedActiveKeywords,
      selectedInactiveKeywords,
    } = this.state;

    const { showError } = this.props;
    if (selectedPromotedServices.length > 1 && action === "move_to_active") {
      showError("Can only select one as a default.");
    } else if (
      action === "profile_default" &&
      selectedPromotedServices.length === 1
    ) {
      this.setState({
        actionType: action,
      });
    } else if (
      action === "move_to_active" &&
      selectedInactiveKeywords.length > 0
    ) {
      this.setState({
        actionType: action,
      });
    } else if (
      action === "move_to_inactive" &&
      selectedActiveKeywords.length > 0
    ) {
      this.setState({
        actionType: action,
      });
    } else if (
      /* Ticket # 740 : make my profile's default service not working. (date: Oct 6th, 2022) */
      /* 
      (action === "move_to_inactive" || action === "move_to_active") &&
        selectedPromotedServices.length > 0
      ) {
        showError(
          "Cannot perform this action, Promoted Services can be deleted or marked as profile default."
        );
      } */
      (action === "move_to_inactive" || action === "move_to_active") &&
      selectedPromotedServices.length > 0
    ) {
      this.setState({
        actionType: action,
      });
    } else if (
      (action === "move_to_inactive" || action === "move_to_active") &&
      selectedPromotedServices.length > 0
    ) {
      showError(
        "Cannot perform this action, Promoted Services can be deleted or marked as profile default."
      );
    } else if (
      action === "delete" &&
      (selectedPromotedServices.length > 0 ||
        selectedActiveKeywords.length > 0 ||
        selectedInactiveKeywords.length > 0)
    ) {
      this.setState({
        actionType: action,
      });
    } else if (
      action === "profile_default" &&
      selectedPromotedServices.length > 1
    ) {
      showError("Choose one service to mark as profile default");
    } else {
      showError("Please choose services before choosing an action");
    }
  };

  performAction = () => {
    const { actionType } = this.state;
    switch (actionType) {
      case "move_to_active":
        this.makeActive();
        console.log("Case Activated");
        break;
      case "move_to_inactive":
        this.makeInactive();
        console.log("Case makeInactive");
        break;
      case "delete":
        this.showDeleteConfirmationMessage();
        console.log("Case showDeleteConfirmationMessage");
        break;
      case "profile_default":
        this.makeDefault();
        console.log("Case makeDefault");
        break;
      default:
        console.log("case nothing happened");
        break;
    }
  };

  cancel = () => {
    this.setState({
      actionType: "",
      selectedPromotedServices: [],
      selectedActiveKeywords: [],
      selectedInactiveKeywords: [],
      showDeleteConfirmation: false,
    });
  };

  makeActive = () => {
    const {
      selectedInactiveKeywords,
      selectedActiveKeywords,
      selectedPromotedServices,
    } = this.state;
    const {
      token,
      headerChildRef,
      getServices,
      showError,
      startLoader,
      stopLoader,
      showSuccess,
    } = this.props;
    if (token !== null) {
      startLoader();
      let ids = null;
      if (selectedInactiveKeywords.length > 0) {
        ids = selectedInactiveKeywords.map((x) => ({
          selected_service_id: x.selected_service_id,
        }));
      } else if (selectedPromotedServices.length > 0) {
        ids = selectedPromotedServices.map((x) => ({
          selected_service_id: x.selected_service_id,
        }));
      }
      let body = {
        selected_services: ids,
      };

      axios
        /* .patch(`${BASE_URL}/service/activate`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }) */
        .patch(
          `${BASE_URL}/supply-chain/selected-service/default/${ids[0].selected_service_id}`,
          JSON.stringify(body),
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            headerChildRef.forceUpdateHandler();
            showSuccess("Service marked as default successfully.");
            // showSuccess("Keywords marked as active successfully.");  //should be this and not the one above but is kept like this because client said it was working fine.
            setTimeout(this.clearMessage, 5000);
            this.setState({
              actionType: "",
              selectedActiveKeywords: [],
              selectedInactiveKeywords: [],
            });
            getServices();
          }
        })
        .catch((error) => {
          stopLoader();
          showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
          console.error(error);
        });

      stopLoader();
    }
  };

  makeInactive = () => {
    const { selectedActiveKeywords } = this.state;
    const {
      token,
      getServices,
      headerChildRef,
      showError,
      startLoader,
      stopLoader,
      showSuccess,
    } = this.props;
    if (token !== null) {
      startLoader();
      let ids = selectedActiveKeywords.map((x) => ({
        selected_service_id: x.selected_service_id,
      }));
      let body = {
        selected_services: ids,
      };
      axios
        .patch(`${BASE_URL}/service/deactivate`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            headerChildRef.forceUpdateHandler();
            showSuccess("Keywords marked as inactive successfully.");
            setTimeout(this.clearMessage, 5000);
            this.setState({
              actionType: "",
              selectedActiveKeywords: [],
              selectedInactiveKeywords: [],
            });
            getServices();
          }
        })
        .catch((error) => {
          stopLoader();
          showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
          console.error(error);
        });
    }
  };

  makeDefault = () => {
    const { selectedPromotedServices } = this.state;
    const {
      token,
      getServices,
      headerChildRef,
      showError,
      startLoader,
      stopLoader,
      showSuccess,
    } = this.props;
    if (token !== null) {
      startLoader();
      axios
        .post(
          `${BASE_URL}/supply-chain/selected-service/default/${selectedPromotedServices[0].selected_service_id}`,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            headerChildRef.forceUpdateHandler();
            showSuccess("Service marked as default successfully.");
            setTimeout(this.clearMessage, 5000);
            this.setState({
              actionType: "",
              selectedPromotedServices: [],
              selectedActiveKeywords: [],
              selectedInactiveKeywords: [],
            });
            getServices();
          }
        })
        .catch((error) => {
          stopLoader();
          showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
          console.error(error);
        });
    }
  };

  deleteLineOfService = () => {
    const {
      selectedPromotedServices,
      selectedActiveKeywords,
      selectedInactiveKeywords,
      password,
    } = this.state;
    const {
      token,
      getServices,
      headerChildRef,
      showError,
      stopLoader,
      showSuccess,
    } = this.props;
    if (token != null) {
      const body = {
        services_id:
          selectedPromotedServices.length > 0
            ? selectedPromotedServices.map((x) => x.selected_service_id)
            : selectedActiveKeywords.length > 0
            ? selectedActiveKeywords.map((x) => x.selected_service_id)
            : selectedInactiveKeywords.map((x) => x.selected_service_id),
        password: password,
      };
      axios
        .delete(`${BASE_URL}/business-service/services`, {
          data: JSON.stringify(body),
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            headerChildRef.forceUpdateHandler();
            showSuccess("Service deleted successfully.");
            setTimeout(this.clearMessage, 5000);
            this.setState({
              actionType: "",
              serviceToDelete: null,
              password: "",
              showPassword: false,
              showDeleteConfirmation: false,
              selectedPromotedServices: [],
              selectedActiveKeywords: [],
              selectedInactiveKeywords: [],
            });
            getServices();
          }
        })
        .catch((error) => {
          stopLoader();
          showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
          console.error(error);
        });
    }
  };

  switchTab = (tab) => {
    this.setState({
      selectedTab: tab,
      selectedPromotedServices: [],
      selectedActiveKeywords: [],
      selectedInactiveKeywords: [],
      serviceType: tab,
      showDeleteConfirmation: false,
      showPassword: false,
      password: "",
      serviceToDelete: null,
      actionType: "",
    });
  };

  addRemoveToPromotedServices = (item) => {
    const { selectedPromotedServices } = this.state;
    let promotedServices = selectedPromotedServices.map(
      (x) => `level:${x.level}:serviceId:${x.selected_service_id}`
    );
    if (
      !promotedServices.includes(
        `level:${item.level}:serviceId:${item.selected_service_id}`
      )
    ) {
      this.setState({
        actionType: "",
        showDeleteConfirmation: false,
        showPassword: false,
        selectedPromotedServices: [
          ...this.state.selectedPromotedServices,
          item,
        ],
      });
    } else {
      let index = promotedServices.indexOf(
        `level:${item.level}:serviceId:${item.selected_service_id}`
      );
      selectedPromotedServices.splice(index, 1);
      this.setState({
        actionType: "",
        showDeleteConfirmation: false,
        showPassword: false,
        selectedPromotedServices: [...selectedPromotedServices],
      });
    }
  };

  renderPromotedServices = () => {
    const { selectedPromotedServices } = this.state;
    const { servicesPromoted } = this.props;
    let promotedServices = selectedPromotedServices.map(
      (x) => `level:${x.level}:serviceId:${x.selected_service_id}`
    );
    let html = [];
    servicesPromoted.forEach((x) => {
      html.push(
        <div
          key={`level:${x.level}:serviceId:${x.selected_service_id}`}
          className="popup-item-boxes"
        >
          <div className="tbl">
            <div className="tbl-cell md_ch_2">
              <div className="tbl">
                <div className="tbl-cell">{x.name}</div>
              </div>
            </div>
            <div
              onClick={() => this.addRemoveToPromotedServices(x)}
              className="tbl-cell md_ch_1"
            >
              <div
                onClick={(event) => event.preventDefault()}
                className="md-checkbox"
              >
                <input
                  onClick={(event) => event.preventDefault()}
                  onChange={() => null}
                  checked={promotedServices.includes(
                    `level:${x.level}:serviceId:${x.selected_service_id}`
                  )}
                  id={`level:${x.level}:serviceId:${x.selected_service_id}`}
                  type="checkbox"
                />
                <label
                  onClick={(event) => event.preventDefault()}
                  htmlFor={`level:${x.level}:serviceId:${x.selected_service_id}`}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  addRemoveToActiveKeywords = (item) => {
    const { selectedActiveKeywords } = this.state;
    let activeKeywordsFormatted = selectedActiveKeywords.map(
      (x) => `level:${x.level}:serviceId:${x.selected_service_id}`
    );
    if (
      !activeKeywordsFormatted.includes(
        `level:${item.level}:serviceId:${item.selected_service_id}`
      )
    ) {
      this.setState({
        actionType: "",
        showDeleteConfirmation: false,
        showPassword: false,
        selectedActiveKeywords: [...this.state.selectedActiveKeywords, item],
      });
    } else {
      let index = activeKeywordsFormatted.indexOf(
        `level:${item.level}:serviceId:${item.selected_service_id}`
      );
      selectedActiveKeywords.splice(index, 1);
      this.setState({
        actionType: "",
        showDeleteConfirmation: false,
        showPassword: false,
        selectedActiveKeywords: [...selectedActiveKeywords],
      });
    }
  };

  renderActiveKeywords = () => {
    const { selectedActiveKeywords } = this.state;
    const { activeKeywords } = this.props;
    let activeKeywordsFormatted = selectedActiveKeywords.map(
      (x) => `level:${x.level}:serviceId:${x.selected_service_id}`
    );
    let html = [];
    activeKeywords.forEach((x) => {
      html.push(
        <div
          key={`level:${x.level}:serviceId:${x.selected_service_id}`}
          className="popup-item-boxes"
        >
          <div className="tbl">
            <div className="tbl-cell md_ch_2">
              <div className="tbl">
                <div className="tbl-cell">{x.name}</div>
              </div>
            </div>
            <div
              onClick={() => this.addRemoveToActiveKeywords(x)}
              className="tbl-cell md_ch_1"
            >
              <div
                onClick={(event) => event.preventDefault()}
                className="md-checkbox"
              >
                <input
                  onClick={(event) => event.preventDefault()}
                  onChange={() => null}
                  checked={activeKeywordsFormatted.includes(
                    `level:${x.level}:serviceId:${x.selected_service_id}`
                  )}
                  id={`level:${x.level}:serviceId:${x.selected_service_id}`}
                  type="checkbox"
                />
                <label
                  onClick={(event) => event.preventDefault()}
                  htmlFor={`level:${x.level}:serviceId:${x.selected_service_id}`}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  addRemoveToInactiveKeywords = (item) => {
    const { selectedInactiveKeywords } = this.state;
    let inActiveKeywordsFormatted = selectedInactiveKeywords.map(
      (x) => `level:${x.level}:serviceId:${x.selected_service_id}`
    );
    if (
      !inActiveKeywordsFormatted.includes(
        `level:${item.level}:serviceId:${item.selected_service_id}`
      )
    ) {
      this.setState({
        actionType: "",
        showDeleteConfirmation: false,
        showPassword: false,
        selectedInactiveKeywords: [
          ...this.state.selectedInactiveKeywords,
          item,
        ],
      });
    } else {
      let index = inActiveKeywordsFormatted.indexOf(
        `level:${item.level}:serviceId:${item.selected_service_id}`
      );
      selectedInactiveKeywords.splice(index, 1);
      this.setState({
        actionType: "",
        showDeleteConfirmation: false,
        showPassword: false,
        selectedInactiveKeywords: [...selectedInactiveKeywords],
      });
    }
  };

  renderInativeKeywords = () => {
    const { selectedInactiveKeywords } = this.state;
    const { inActiveKeywords } = this.props;
    let inActiveKeywordsFormatted = selectedInactiveKeywords.map(
      (x) => `level:${x.level}:serviceId:${x.selected_service_id}`
    );
    let html = [];
    inActiveKeywords.forEach((x, index) => {
      html.push(
        <div
          key={`${index}-level:${x.level}:serviceId:${x.selected_service_id}`}
          className="popup-item-boxes"
        >
          <div className="tbl">
            <div className="tbl-cell md_ch_2">
              <div className="tbl">
                <div className="tbl-cell">{x.name}</div>
              </div>
            </div>
            <div
              onClick={() => this.addRemoveToInactiveKeywords(x)}
              className="tbl-cell md_ch_1"
            >
              <div
                onClick={(event) => event.preventDefault()}
                className="md-checkbox"
              >
                <input
                  onClick={(event) => event.preventDefault()}
                  onChange={() => null}
                  checked={inActiveKeywordsFormatted.includes(
                    `level:${x.level}:serviceId:${x.selected_service_id}`
                  )}
                  id={`level:${x.level}:serviceId:${x.selected_service_id}`}
                  type="checkbox"
                />
                <label
                  onClick={(event) => event.preventDefault()}
                  htmlFor={`level:${x.level}:serviceId:${x.selected_service_id}`}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  render() {
    const {
      selectedTab,
      showDeleteConfirmation,
      showPassword,
      password,
      actionType,
    } = this.state;
    const { showSideBar } = this.props;
    return (
      <React.Fragment>
        <div
          className={`services_main_body c_p clearfix ${
            !showSideBar ? "trigger_services_main_body" : ""
          }`}
        >
          <div className="container">
            <ServiceSteps
              moveToStep={this.props.moveToStep}
              service={"selection"}
            />
            {/* <div className="p_services_selected clearfix">
              <ul id="progressbar">
                <li className="active">
                  <a>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 1
                    </span>{" "}
                    <br />
                    Services Selection
                  </a>
                </li>
                <li>
                  <a onClick={() => this.props.moveToStep(3)}>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 2
                    </span>{" "}
                    <br />
                    Portfolios
                  </a>
                </li>
                <li>
                  <a onClick={() => this.props.moveToStep(4)}>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 3
                    </span>{" "}
                    <br />
                    coverage
                  </a>
                </li>
                <li>
                  <a onClick={() => this.props.moveToStep(5)}>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 4
                    </span>{" "}
                    <br />
                    Service Paremeters
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="services_custom_top">
              <h3>1. Service Selection</h3>
              <a onClick={() => this.props.moveToStep(1)}>Add services</a>
            </div>
            <div className="clearfix new_active_inactive_services">
              <h2>Current services under promotion</h2>
              {/* <a
                onClick={() => this.switchTab("promoted")}
                className={
                  selectedTab === "promoted"
                    ? "new_active_inactive_services_active"
                    : ""
                }
              >
                Promoted Services
              </a>
              <a
                onClick={() => this.switchTab("active")}
                className={
                  selectedTab === "active"
                    ? "new_active_inactive_services_active"
                    : ""
                }
              >
                Active Keyword
              </a>
              <a
                onClick={() => this.switchTab("inactive")}
                className={
                  selectedTab === "inactive"
                    ? "new_active_inactive_services_active"
                    : ""
                }
              >
                Inactive Keyword
              </a> */}
            </div>
            <div className="segment_steps_body clearfix">
              {selectedTab === "promoted" && this.renderPromotedServices()}
              {selectedTab === "active" && this.renderActiveKeywords()}
              {selectedTab === "inactive" && this.renderInativeKeywords()}
            </div>
            {showDeleteConfirmation === true && (
              <div className="clearfix further_options has-text-left">
                <br />
                <p>Are you sure to delete this line of service?</p>
                <div className="map-buton has-text-left">
                  <button onClick={this.showPasswordBox} className="btn-fill">
                    Yes
                  </button>
                  <button onClick={this.cancel} className="btn-empty">
                    No
                  </button>
                </div>
              </div>
            )}
            {showPassword === true && (
              <div className="clearfix further_options has-text-left">
                <br />
                <p>To delete a service please enter your password</p>
                <div className="form-group">
                  <input
                    style={{ width: "30%" }}
                    className="form-control"
                    type="password"
                    value={password}
                    name="password"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div className="map-buton has-text-left">
                  <button
                    onClick={() => this.deleteLineOfService()}
                    style={{ marginLeft: 0 }}
                    className="btn-fill"
                  >
                    Delete
                  </button>
                </div>
                <label className="has-text-danger">
                  NOTE: This action will remove all previously selected services
                  along with related review / endorsement and portfolio files.
                </label>
              </div>
            )}
            {showDeleteConfirmation !== true && showPassword !== true && (
              <div className="clearfix further_options">
                <label
                  onClick={() => this.setActionType("move_to_active")}
                  // onClick={() => this.setActionType("profile_default")} //Ticket 799 states that it should be like this but above one is working correctly so is kept like so.
                  className="control control--checkbox"
                >
                  <input
                    onChange={() => {}}
                    onClick={(e) => e.preventDefault()}
                    checked={actionType === "move_to_active"}
                    // checked={actionType === "profile_default"} //Ticket 799 states that it should be like this but above one is working correctly so is kept like so.
                    type="checkbox"
                  />
                  <div className="control__indicator bounceUp" />
                  <span>Make my profile's default service</span>
                  {/* </label>
                <label
                  onClick={() => this.setActionType("move_to_inactive")}
                  className="control control--checkbox"
                >
                  <input
                    onChange={() => {}}
                    onClick={(e) => e.preventDefault()}
                    checked={actionType === "move_to_inactive"}
                    type="checkbox"
                  />
                  <div className="control__indicator bounceUp" />
                  <span>Move to Inactive Keywords</span> */}
                </label>
                <label
                  onClick={() => this.setActionType("delete")}
                  className="control control--checkbox"
                >
                  <input
                    onChange={() => {}}
                    onClick={(e) => e.preventDefault()}
                    checked={actionType === "delete"}
                    type="checkbox"
                  />
                  <div className="control__indicator bounceUp" />
                  <span>Remove service</span>
                </label>
                {/* <label
                  onClick={() => this.setActionType("profile_default")}
                  className="control control--checkbox"
                >
                  <input
                    onChange={() => {}}
                    onClick={(e) => e.preventDefault()}
                    checked={actionType === "profile_default"}
                    type="checkbox"
                  />
                  <div className="control__indicator bounceUp" />
                  <span>Profile Default</span>
                </label> */}
                {actionType !== "" && (
                  <div
                    onClick={this.performAction}
                    className="clearfix select_line_services_opt"
                  >
                    <a className="select_line_services_opt_a">Perform</a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServicesKeywordsManagement;
