import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader.jsx";
import { Box } from "@mui/material";
import DropdownTreeSelect from 'react-dropdown-tree-select'
import Axios from 'axios';
import 'react-dropdown-tree-select/dist/styles.css'
import './newIndex.css';
import { BASE_URL } from '../../Constants/serverConfig';
const datas = [
    {
        "label": "Existing conditions",
        "value": "Existing conditions"
    },
    {
        "label": "Concrete",
        "value": "Concrete"
    },
    {
        "label": "Masonry",
        "value": "Masonry"
    },
    {
        "label": "Metals",
        "value": "Metals"
    },
    {
        "label": "Wood",
        "value": "Wood"
    },
    {
        "label": "Thermal and Moisture Protection: ",
        "value": "Thermal and Moisture Protection: "
    },
    {
        "label": "Openings",
        "value": "Openings"
    },
    {
        "label": "Finishes",
        "value": "Finishes"
    },
    {
        "label": "Conveying equipment ",
        "value": "Conveying equipment "
    },
    {
        "label": "Fire suppression ",
        "value": "Fire suppression "
    },
    {
        "label": "Plumbing",
        "value": "Plumbing"
    },
    {
        "label": "HVAC",
        "value": "HVAC"
    },
    {
        "label": "Electrical",
        "value": "Electrical"
    },
    {
        "label": "Earthwork",
        "value": "Earthwork"
    },
    {
        "label": "Exterior Improvements",
        "value": "Exterior Improvements"
    }
]

let data = require('../../Constants/dataSource.json');

const options = [
    {
        "label": "Existing conditions",
        "name": "Existing conditions",
        "id": 1

    },
    {
        "label": "Concrete",
        "name": "Concrete",
        "id": 2

    },
    {
        "label": "Masonry",
        "name": "Masonry",
        "id": 3
    },
    {
        "label": "Metals",
        "name": "Metals",
        "id": 4

    },

    {
        "label": "Wood",
        "name": "Wood",
        "id": 5

    },

    {
        "label": "Thermal and Moisture Protection: ",
        "name": "Thermal and Moisture Protection: ",
        "id": 6

    },
    {
        "label": "Openings",
        "name": "Openings",
        "id": 7

    },
    {
        "label": "Finishes",
        "name": "Finishes",
        "id": 8

    },
    {
        "label": "Conveying equipment ",
        "name": "Conveying equipment ",
        "id": 19

    },
    {
        "label": "Fire suppression ",
        "name": "Fire suppression ",
        "id": 11

    },
    {
        "label": "Plumbing",
        "name": "Plumbing",
        "id": 12

    },
    {
        "label": "HVAC",
        "name": "HVAC",
        "id": 13

    },

    {
        "label": "Electrical",
        "name": "Electrical",
        "id": 14

    },
    {
        "label": "Earthwork",
        "name": "Earthwork",
        "id": 15

    },
    {
        "label": "Exterior Improvements",
        "name": "Exterior Improvements",
        "id": 16

    }
]

const listTrades = [];

export default class newIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showLoader: false, show: false, selectedTrades: [], disabledInputTrade: false };
        /**inputs for signup form */
        this.buttonRef = React.createRef();
        this.buttonLoad = React.createRef();
        this.buttonSubmit = React.createRef();
        this.labelWarning = React.createRef();
        this.inputTradeRef = React.createRef();
        this.inputEmployeSizeCheck1 = React.createRef();
        this.inputEmployeSizeCheck2 = React.createRef();
        this.inputCompanyName = React.createRef();
        this.inputContactName = React.createRef();
        this.inputEmail = React.createRef();
        this.inputZipCode = React.createRef();

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.show === nextState.show) {
            return false;
        }
        return true;
    }



    handleClose = () => {

        this.setState({
            show: false
        })

    }

    handleShow = () => {

        this.setState({
            show: true
        })

    }

    handleUpdateList = (list) => {
        this.setState({
            selectedTrades: list
        })

    }

    onChange = (currentNode, selectedNodes) => {



        if (selectedNodes.length >= 5) {
            this.buttonRef.current.click();
        }

        console.log('onChange::', currentNode, selectedNodes)
    }

    onAction = (node, action) => {

        console.log('onAction::', action, node)
    }
    onNodeToggle = currentNode => {
        console.log('onNodeToggle::', currentNode)
    }

    submitData = () => {

        
        if (this.inputTradeRef.current.state.tags.length > 5) {
            this.labelWarning.current.classList.remove('d-none');
            return;
        } else {
            this.labelWarning.current.classList.add('d-none');
        }

        this.buttonLoad.current.classList.remove('d-none');
        this.buttonSubmit.current.classList.add('d-none');

      
        Axios.post(BASE_URL + "/searcher/customer", {
            company_employe_size: this.inputEmployeSizeCheck1.current.checked ? '< 500' : this.inputEmployeSizeCheck2.current.checked ? 'Over 500' : '',
            company_name: this.inputCompanyName.current.value,
            contact_name: this.inputContactName.current.value,
            email: this.inputEmail.current.value,
            zip_code: this.inputZipCode.current.value,
            trade: JSON.stringify(this.inputTradeRef.current.state.tags),
        }).then(res => {
            this.buttonLoad.current.classList.add('d-none');
            this.buttonSubmit.current.classList.remove('d-none');

        }).catch(res => {
            this.buttonLoad.current.classList.add('d-none');
            this.buttonSubmit.current.classList.remove('d-none');
        })

    }

    updateRefTrade = () => {


        //console.log(this.inputTradeRef.current.state.tags);
        this.setState((prevState) => ({
            selectedTrades: [...prevState.selectedTrades, this.inputTradeRef.current.state.tags], // Insertar un nuevo número
        }));


    }

    ModalForm = (show, disabled) => {
        return (
            <Modal show={show} onHide={this.handleClose} centered animation={false} className='bg-modal-customers'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-white'>Join Construction Training Hub</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form className='form-customer'>

                        <div className='d-flex flex-column justify-content-start'>
                            <label className='label-form-customer'>Select Construction employee size</label>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check ref={this.inputEmployeSizeCheck1} className="me-2" inline label="< 500" value="< 500" name="group1" type={type} id={`inline-${type}-1`} bsPrefix="form-check form-check-inline check-customers-input" />
                                    <Form.Check ref={this.inputEmployeSizeCheck2} inline label="Over 500" value="Over 500" name="group1" type={type} id={`inline-${type}-2`} bsPrefix="form-check form-check-inline check-customers-input" />
                                </div>
                            ))}
                        </div>

                        <div className="form-group">
                            <label className='label-form-customer' htmlFor="companyNameInput">Company Name</label>
                            <input ref={this.inputCompanyName} type="text" className="form-control" id="companyNameInput" />
                        </div>
                        <div className="form-group">
                            <label className='label-form-customer' htmlFor="contactNameInput">Contact Name</label>
                            <input ref={this.inputContactName} type="text" className="form-control" id="contactNameInput" />
                        </div>
                        <div className="form-group">
                            <label className='label-form-customer' htmlFor="emailInput">Email</label>
                            <input ref={this.inputEmail} type="email" className="form-control" id="emailInput" />
                        </div>
                        <div className="form-group">
                            <label className='label-form-customer' htmlFor="zipcodeInput">Zip Code</label>
                            <input ref={this.inputZipCode} type="number" className="form-control" id="zipcodeInput" />
                        </div>

                        <div className="">
                            <label className='label-form-customer' htmlFor="tradeInput">Main Trades <small>(Enable selection of up to 5)</small> </label>

                            <DropdownTreeSelect ref={this.inputTradeRef} id="tradeInput" className='bootstrap-demo' texts={{ placeholder: 'SELECT TRADE', inlineSearchPlaceholder: "Do not find the trade? Search here" }} data={data} disabled={disabled} inlineSearchInput={true} keepTreeOnSearch={true} onChange={this.onChange} onAction={this.onAction} onNodeToggle={this.onNodeToggle} onBlur={this.updateRefTrade}></DropdownTreeSelect>

                        </div>
                        <div ref={this.labelWarning} className="d-none alert alert-danger" role="alert">
                            Only up to 5 trades can be selected.
                        </div>

                    </Form>

                </Modal.Body>
                <Modal.Footer className='d-flex flex-row justify-content-center'>
                    <Button ref={this.buttonSubmit} className="btn-join-builder-modal" variant="primary" onClick={this.submitData}>
                        JOIN
                    </Button>
                    <Button ref={this.buttonLoad} className="d-none btn-join-builder-modal" variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                         Loading...
                    </Button>
                    <Button ref={this.buttonRef} className="d-none" variant="primary" >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

        )
    }



    render() {
        const { show, disabledInputTrade, showLoader } = this.state;

        return (
            <>

                <NewHeader />
                <div className='container-fluid p-0 sections'>
                    {/* <Button variant="primary" onClick={this.handleShow}>
                    Launch demo modal
                </Button> */}
                    {this.ModalForm(show, disabledInputTrade)}

                    {/* Section 1 */}
                    <div className='container-fluid section-1'>
                        <div className='head mb-3 row'>
                            <div className='head-col col-12 col-xl-7'>
                                <h1>Close Skill Gaps:</h1>
                                <h2>Self-Paced Training</h2>
                                <h3>for Lean Construction</h3>
                                <h5>Boost Proficiency, Retain Talent, Build Trust, and Outpace Competitors.</h5>
                            </div>
                            <div className='head-col-img display-md col-12 col-xl-5 text-center'>
                                <img src='/images/mobileGifCrop.gif' width="220" height="300"></img>
                            </div>

                        </div>
                        <div className='section-one-body'>

                            <div className='section-one-body-cards d-flex flex-column  flex-xl-column justify-content-center align-items-center '>
                                <p>
                                    <h6 className='text-center'>Transform Construction Skills into a Path for Growth:</h6>
                                    Bridging the skill gap in construction is essential. Offering self-paced, customized training is the path for efficiency. More than just training, this is about raising every worker's potential to bring the best out of them.
                                </p>
                                <div className='d-flex flex-row justify-content-center align-items-center w-100'>
                                    <button className='btn btn-success btn-section-one  mx-1 mx-xl-4' onClick={this.handleShow}>Join Construction Training Hub</button>

                                </div>

                            </div>

                        </div>
                        {/* <button className='btn btn-success btn-section-one'>Join Construction Training Hub</button> */}

                    </div>
                    {/* Section 2 */}
                    <div className='section-2'>

                        <div className='head-section-2'>
                            <h2>Inefficiencies in Construction <br></br>Will Eat Up Your Profit</h2>
                            <strong className='display-md'>Adopting technology to address inefficiencies <br></br>is the deciding factor between leading and lagging</strong>
                            <strong className='display-xs'>Adopting technology to address inefficiencies is the deciding factor between leading and lagging</strong>

                        </div>
                        <div className='section-two-body d-flex flex-column flex-xl-row'>
                            <div className='section-two-p row'>
                                <div className='col-12 col-xl-6'>
                                    <p>
                                        Skill gaps, poor leadership, and operational errors, more than internal issues, are profit drains and advantages to your competition. </p>
                                    <p>Every moment of inaction amplifies your competitors' edge and chips away from the bottom line, losing employees to the competition, damaging customer trust, and ultimately your reputation.
                                    </p>
                                </div>
                                <ul className='col-12 col-xl-6 ul-section-2  d-flex flex-column justify-content-between align-items-center'>
                                    <li className='list-section-2'>Claim Your Competitive Edge: Neglecting team skill development is more than a setback; it's conceding ground to competitors.</li>
                                    <li className='list-section-2'>Invest to Stay Ahead: Skill development is a strategic necessity, not just an expense. It's vital to  stay competitive and building loyalty.</li>
                                    <li className='list-section-2'>Lead, Don't Lag: Failing to address skill gaps risks falling behind. Proactive action to upskill your workforce is the path to a leading position.</li>
                                </ul>
                            </div>


                        </div>
                        <div className='d-flex flex-row justify-content-center align-items-center'>
                            <button className='btn btn-success btn-section-two mx-1  mx-xl-4' onClick={this.handleShow}>Join Construction Training Hub</button>
                        </div>

                    </div>
                    {/* Section 3 */}
                    <div className='section-3'>
                        <div className='head-section-3'>
                            <h2 className='display-md'>Empowering Opportunities<br></br> by Empowering Your Teams</h2>
                            <h2 className='display-xs'>Empowering Opportunities by Empowering Your Teams</h2>
                            <h5>Adopt a Strategy That Will Transform Inefficiencies into Construction Proficiency</h5>
                        </div>
                        <div className='section-three-body d-flex flex-column justify-content-center align-items-center'>

                            <div className='section-three-p row '>
                                <div className='col-12 col-xl-6'>
                                    <p>
                                        Lead the industry by leading your teams with knowledge-driven strategies that advance their careers.
                                    </p>
                                    <p>
                                        Turn challenges into opportunities for building better and gaining recognition, all while paving the way for a new chapter geared by innovation
                                    </p>
                                </div>
                                <ul className='col-12 col-xl-6 ul-section-2 d-flex flex-column justify-content-between'>
                                    <li className='list-section-3'>Increase Profits by Empowering Teams to Advance at Their Own Pace with Simple Clicks.</li>
                                    <li className='list-section-3'>Upgrade Your Team's Skills in Safety, Math, Technical & Soft Skills, Compliance, Management, and Environmental Knowledge.</li>
                                    <li className='list-section-3'>Challenge Teams with Quizzes Based on Theory, Techniques, Tips, Tricks, and Practical Applications.</li>
                                    <li className='list-section-3'>Create Custom Training Paths to Level Up from Beginner, Skilled, Intermediate, to Proficient Ranking Levels.</li>
                                    <li className='list-section-3'>Lead Today into a Better Tomorrow through Innovation with Tech-Driven Training.</li>
                                </ul>
                            </div>



                        </div>
                        <div className='d-flex flex-row justify-content-center align-items-center'>
                            <button className='btn btn-success btn-section-one mt-5' onClick={this.handleShow}>Join Construction Training Hub</button>
                        </div>
                    </div>
                    <div className='section-4'>
                        <div className='head-section-4'>
                            <h2>Partnering for Progress</h2>
                            <h3>Pioneering the Future of Construction Training: <br></br>Join Our BETA Launch</h3>
                            <h5>Be Among the First to Shape and Benefit from the Next Wave of Construction Training</h5>
                        </div>
                        <div className='section-four-body d-flex flex-row'>

                            <div className='section-four-p d-flex flex-column justify-content-center align-items-center'>
                                <p>
                                    Welcome to the Construction Training Hub. As a pivotal feature of PropAgile, the Hub enables customization of local and trade-specialized training paths to suit any company's training goals at scale, designed to advance skills from beginner to proficient levels.
                                </p>
                                <p>
                                    With a self-paced, quiz-based format, the Hub aligns with the diverse needs, understanding, and educational capacities of every construction professional. It promotes consistent paths to growth, while offering companies a trackable system to monitor and support their training process.
                                </p>
                                <p>
                                    As we embark on our BETA launch, we extend a warm invitation to join this transformative experience. Be a guiding force in shaping this training tool to best suit your needs, tackling inefficiencies and granting your team and company a formidable competitive edge.
                                </p>
                                <p>
                                    <b>Seize the opportunity</b> to lead your teams into efficiency, securing a significant advantage over the competitors by joining us during BETA.
                                </p>


                                <ul className='list-section-four'>
                                    <li className='list-section-4'>Exclusive Access to Innovation: Joining us during BETA gives you unique access to cutting-edge training methods.</li>
                                    <li className='list-section-4'>Influence Development: This is an opportunity to provide feedback and shape the development of the Training Hub to best suit your company's goals. </li>
                                    <li className='list-section-4'>Expert-Led Insights: Led by a diverse team of seasoned professionals and innovators, with training modules that seamlessly blend bottom-up and top-down approaches. </li>
                                    <li className='list-section-4'>Early Competitive Advantage: Our BETA releases grant first adopters a unique edge with advanced training and technology to outpace competitors, and seize opportunities earlier.</li>
                                    <li className='list-section-4'>Commitment to Improvement: We focus on forging synergistic relationships to unlock the full potential of our technology. Through active collaboration, we aim for mutual growth and ongoing enhancement, turning this tool into a significant competitive advantage for your company.</li>
                                </ul>


                                <button className='btn btn-success btn-section-two mt-3' onClick={this.handleShow}>Join Construction Training Hub</button>
                            </div>


                        </div>
                    </div>
                    <div className='section-5 row justify-content-center align-items-center'>
                        <div className='head-col col-12'>
                            <h2 className='text-center'>Easy to Join, Easy to Use</h2>
                            <h3>Unlock Your Team’s Potential in Three Simple Steps</h3>
                            <h5>Each Step: A Transformative Move into Competitive Advantage</h5>

                            <div className='section-five-body d-flex flex-column justify-content-left align-items-left w-100'>
                                <p className=''>
                                    <b>Important Note on BETA:</b> The BETA phase is limited to three large (500+ employees) and three smaller firms per trade in each U.S. Metro area, with availability on a first-come, first-served basis. Applicants are informed if their desired trade is no longer available prior to commitment.
                                </p>
                                <p className='mb-5'>
                                    <b>Onboarding Your Business for Success:</b> We offer a one-month, no-charge onboarding period, crucial for setting up the system and fostering effective one-to-one collaboration and support, thus ensuring your business is primed for success.
                                </p>
                            </div>

                        </div>
                        <div className='section-five-body d-flex flex-column col-12'>
                            <h5 className='text-center'>Our 3-Step Plan:</h5>
                            <div className='section-five-p row'>
                                <div className='col-12 col-xl-4 px-4 px-xl-1'>
                                    <h5 className='title-card-section-5'><b>Step 1</b><br></br>Seed the Way: </h5>
                                    <div className=' card-section-five'>
                                        {/* <strong>Seed the Way Onboard Your Business for Workforce Success</strong> */}
                                        <ul className='list-section-five'>
                                            <li className='list-section-5'>Join and create one of more business profiles based on Standard or Business membership.</li>
                                            <li className='list-section-5'><i>Added Advantages:</i> <br></br>Amplify your brand's multichannel online presence across every profile and construction team you create.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-12 col-xl-4 px-4 px-xl-1 mt-4 mt-xl-0'>
                                    <h5 className='title-card-section-5'><b>Step 2</b><br />Enable Connections </h5>
                                    <div className=' card-section-five'>
                                        {/* <strong>Connect Your Teams to Targeted Self Paced Training</strong> */}
                                        <ul className='list-section-five'>
                                            <li className='list-section-5'>Create construction teams online, a novel concept to qualify and align teams' skills with your company's needs.</li>
                                            <li className='list-section-5'><i>Added Advantages:</i> <br></br> Real-time prospect matching and notifications to set team sourcing on autopilot.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-12 col-xl-4 px-4 px-xl-1 mt-4 mt-xl-0'>
                                    <h5 className='title-card-section-5'><b>Step 3</b><br /> Set the Path to Proficiency</h5>
                                    <div className='card-section-five'>
                                        {/* <strong>Automate Team Improvement Oversight</strong> */}
                                        <ul className='list-section-five'>
                                            <li className='list-section-5'>Assign a trade-specific training path to each team and add the corresponding employees.</li>
                                            <li className='list-section-5'><i>Added Advantages:</i> <br></br> Tailor training paths to suit local and trade-related needs, to meet diversified and scalable company goals.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p className='mt-5 text-light text-center footer-section-5'>
                                Begin your transformation journey now; a new path to construction efficiency is just a few clicks away.
                            </p>
                        </div>
                        <button className='btn btn-success btn-section-two mx-4 mt-2 mt-xl-4' onClick={this.handleShow}>Join Construction Training Hub</button>

                    </div>
                    <div className='section-risk'>
                        <div className='head-risk mb-4'>
                            <h2>100% RISK-FREE Commitment</h2>
                            <strong className=''>We stand by the value of our tool. If we don’t surpass your expectations by the end of the BETA phase, rest assured, we've got you covered. We'll provide a complimentary 6-month membership, complete with a dedicated manager, to ensure your investment in us is nothing short of a success story.</strong>
                        </div>
                    </div>
                    <div className='section-6'>
                        {/* <div className='head'>
                        <h2>Empower Your Teams, Empower Your Future</h2>
                        <strong>Transform Training Overheads into Investments in Excellence</strong>
                    </div> */}
                        <div className='section-six-body d-flex flex-column'>
                            <h2>The PropAgile Advantage - Simplified and Strategic</h2>

                            <div className='section-six-p d-flex flex-column justify-content-center align-items-center mt-5'>
                                <p>
                                    We understand the need for upskilling teams and your aspiration for leadership, not mere participation.
                                </p>
                                <p>
                                    Traditional methods have proven to be expensive and inefficient.
                                </p>
                                <p>
                                    Our BETA program presents an agile, cost-effective, advanced, and user-centric alternative.
                                </p>

                                <p className='core-tittle'>
                                    Core Highlights:
                                </p>
                                <ul className='list-section-six'>
                                    <li className='list-section-6'><b>Quick Setup:</b> Seamlessly register and embark on a journey to leadership, innovation, and recognition.</li>
                                    <li className='list-section-6'><b>Tailored Training:</b> Connect your teams with our MasterFormat-structured, level-specific, quiz-based learning.</li>
                                    <li className='list-section-6'><b>Effortless Tracking:</b> Track progress at individual, team, and company levels through reports and weekly insights.</li>
                                    <li className='list-section-6'><b>Competitive Advantage:</b> Align your teams with knowledge that capitalizes on opportunities, and fosters efficiency, innovation, retention, loyalty, and advocacy.</li>
                                </ul>

                                <p className='my-2 my-xl-5 '>
                                    Click to propel your teams into a new era of innovation, skills, and efficiency.
                                </p>
                                <button className='btn btn-success btn-section-one' onClick={this.handleShow}>Join Construction Training Hub</button>
                            </div>
                        </div>
                    </div>
                    <div className='section-7 d-none'>

                        <div className='section-seven-body d-flex flex-column'>

                            <div className='section-seven-p d-flex flex-column justify-content-center align-items-center'>
                                <p>
                                    Show a the creation of a construction team, allocation of trade and upon selection show a popup to select the path of training from the cascade, click save training path then prompt message “Your team's training path is ready. Click to copy the invite link, email it to your employees, and ensure they sign up to start their self-paced training immediately.” Then show a person clicking in an email link, creating entering the information and joining porpagile and immediately having access to the Quizzes, clicking in the bottom to answer, clicking on the correct answer showing a success note, then displaying another quiz, finally show a computer screen showing a progress dashboard.
                                </p>


                            </div>
                        </div>

                    </div>
                    <div className='section-8'>
                        <div className='head-section-8'>
                            <h2>Align Your Training with Your Ambitions</h2>
                            <h3>Dynamic BETA Memberships for Every Stage of Growth</h3>
                            <h5 className='display-md'>Choose between Standard or Business memberships, <br /> each structured for convenience and scalability.</h5>
                            <h5 className='display-xs'>Choose between Standard or Business memberships,  each structured for convenience and scalability.</h5>
                        </div>
                        <div className='section-eight-body row'>

                            <div className='col-12 col-xl-6 section-eight-p '>
                                <p className='title-membership m-0'>
                                    Standard Membership
                                </p>
                                <p className='body-membership body-membership-1 bg-light'>
                                    Ideal for small companies, offering one license covering 5 teams in up to 5 trades, training up to 250 employees within a 50-mile radius. <br />  <br />Perfect for local or metro area coverage. Additional employees can be added at a cost of $4 / Employee / Mo.
                                </p>
                            </div>

                            <div className='col-12 col-xl-6 section-eight-p  '>
                                <p className='title-membership m-0'>
                                    Business Membership
                                </p>
                                <p className='body-membership body-membership-2 bg-light'>
                                    Includes 5 licenses out of the box for training up to 1,250 employees, each license with the same privileges as described in the Standard. <br />  <br /> Licenses can be assigned to different regions and managers, ideal for nationwide companies seeking scalability. Additional employees can be added at a cost of $3.2 / Employee / Mo.
                                </p>
                            </div>



                        </div>
                        <div>

                            <div className='head-section-8 mt-3 mt-xl-5'>
                                <h5>Added Benefits of Each License:</h5>
                            </div>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <ul className='list-section-eight'>
                                    <li className='list-section-8'>An online profile link to the website</li>
                                    <li className='list-section-8'>A multichannel strategy to display portfolios and for increased branding and visibility.</li>
                                    <li className='list-section-8'>Online reputation building with easy-to-request reviews and endorsements.</li>
                                    <li className='list-section-8'>Seamless sharing via email and social networks.</li>
                                    <li className='list-section-8'>A unique QR code to lead print media audiences to a profile.</li>
                                    <li className='list-section-8'>Multiple funnels leading to the company's website with every construction team creation.</li>
                                </ul>
                            </div>
                            <p className='section-eight-footer-p'>
                                <b>Cost Structure: </b>Training costs as low as $3.2 per employee per Month. An initial configuration fee and monthly subscription apply. Detailed pricing will be provided upon approval.
                            </p>
                            <p className='section-eight-footer-p-action text-center'>
                                Join <b>PropAgile's BETA</b> now and start building the skilled, future-ready workforce you will need to support growth tomorrow
                            </p>
                            <div className='d-flex flex-row justify-content-center align-items-center mt-3 mt-xl-5'>
                                <button className='btn btn-success btn-section-one' onClick={this.handleShow}>Join Construction Training Hub</button>

                            </div>
                        </div>
                    </div>
                </div>
                <Box style={{ marginTop: "auto" }} className='border-0 border-xl-top'>
                    <Footer />
                </Box>
            </>
        )
    }
}
