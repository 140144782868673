import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import imageCompression from "browser-image-compression";
// import ReactHtmlParser from "react-html-parser";
import {
  DEMO_PROFILE_DATA,
  DEMO_PROFILE_PORTFOLIO_1,
  DEMO_PROFILE_PORTFOLIO_2,
  DEMO_PROFILE_PORTFOLIO_3,
  DEMO_PROFILE_PORTFOLIO_4,
  DEMO_PROFILE_PORTFOLIO_5,
  DEMO_PROFILE_PORTFOLIO_6,
} from "../../Constants/localStorageKeys";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import Loader from "../Loader/loader.jsx";
import SupplyChainMasterFormat from "../SupplyChain/supplyChainMasterFormat";
import { SupplyChainMode } from "../SupplyChain/utils/constants.tsx";
import { BASE_URL } from "../../Constants/serverConfig";
import {
  leadershipFunctionOptions,
  workFunctionOptions,
  managementFunctionOptions,
} from "./utils/commons";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";
import { Container } from "@mui/material";

class DummyDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      companyWebsite: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      state: "",
      file: null,
      portfolioFiles: [],
      licensed: "",
      insured: "",
      is24Hours: "",
      unionWorker: "",
      baseWork: [],
      leadership: [],
      management: [],
      showError: false,
      errorMessage: "",
      showLoader: false,
      maxPromotedSkills: 0,
      accessToken: "",
      activeKeywords: [],
      servicesPromoted: [],
    };
  }

  componentDidMount() {
    const { isSpLoggedIn, isUserLoggedIn } = this.props;
    if (isSpLoggedIn === true || isUserLoggedIn === true) {
      this.props.history.push("/");
    }
    axios
      .post(
        `${BASE_URL}/entrance/login`,
        {
          username: process.env.REACT_APP_DEMO_USER,
          password: process.env.REACT_APP_DEMO_PASSWORD,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            accessToken: response.data.access_token,
            maxPromotedSkills:
              response.data.user.membership_information
                .max_promoted_services_allowed,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          accessToken: "",
          maxPromotedSkills: 0,
        });
      });
  }

  validatePersonalInfo = () => {
    let urlValidation = /^(www\.\/\/www\.)?[a-z0-9]+([\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let companyNameValidation = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/;
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    var numbersOnlyValidation = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
    const {
      companyName,
      companyWebsite,
      firstName,
      lastName,
      email,
      phoneNumber,
    } = this.state;
    if (companyName !== "") {
      if (!companyNameValidation.test(companyName.trim())) {
        this.showError(
          `<p>Please enter a valid Company name, your company name should <b>not</b> contain characters like <b>\\ ^ ! @ # $ * ~ < > ?</b>. See examples below</p><ul><li><b>PropAgile</b></li><li><b>Geeky Bugs</b></li><li><b>Google</b></li></ul>`
        );
        return false;
      }
    }
    if (companyWebsite !== "") {
      if (!urlValidation.test(companyWebsite.trim())) {
        this.showError(
          "<p>Please enter a valid Company webite url, see example below</p><ul><li><b>www.propagile.com</b></li></ul>"
        );
        return false;
      }
    }
    if (firstName !== "") {
      if (!nameValidation.test(firstName.trim())) {
        this.showError(
          "<p>Please enter a valid first name, <b>first name should have 3 to 50 alphabets only</b></p>"
        );
        return false;
      }
    }
    if (lastName !== "") {
      if (!nameValidation.test(lastName.trim())) {
        this.showError(
          "<p>Please enter a valid last name, <b>last name should have 3 to 50 alphabets only</b></p>"
        );
        return false;
      }
    }
    if (email !== "") {
      if (!emailValidation.test(email.trim())) {
        this.showError(
          "<p>Please enter a valid email address. See example below</p><ul><li><b>john@test.com</b></li></ul>"
        );
        return false;
      }
    }
    if (phoneNumber !== "") {
      if (!numbersOnlyValidation.test(phoneNumber)) {
        let reasonString = phoneNumber
          .split("(")
          .join("")
          .split(")")
          .join("")
          .split(" ")
          .join("")
          .split(".")
          .join("")
          .split("-")
          .join("");
        let isANumber = isNaN(Number(reasonString));
        if (reasonString.length < 10) {
          this.showError(
            "<p>The phone number must contain <b>10 digits</b>, you have entered <b>" +
              reasonString.length +
              " digits</b></p>"
          );
        } else if (isANumber) {
          this.showError(
            "<p>You have entered some invalid characters, the phone number can have <b>. - ( ) or space</b> for more information see sample below.</p><ul><li><b>(123)-456 7890</b></li><li><b>(123)-456-7890</b></li><li><b>(123)-4567890</b></li></ul>"
          );
        } else {
          this.showError(
            "<p>We are unable to identify the problem with your entered phone number. Please see samples below or call support for more assistance</p><ul><li><b>(123)-456 7890</b></li><li><b>(123)-456-7890</b></li><li><b>(123)-4567890</b></li></ul>"
          );
        }
        return false;
      }
    }
    this.clearError();
    return true;
  };

  validateServiceSelection = () => {
    const { servicesPromoted } = this.state;
    const { portfolioFiles } = this.state;
    if (servicesPromoted.length === 0) {
      this.showError("<p>Please choose a service.</p>");
      return false;
    }
    if (portfolioFiles.length === 0) {
      this.showError("<p>Please upload atleast one portfolio picture.</p>");
      return false;
    }
    this.clearError();
    return true;
  };

  validateServiceCharacteristics = () => {
    const {
      baseWork,
      leadership,
      management,
      licensed,
      insured,
      is24Hours,
      unionWorker,
    } = this.state;
    if (baseWork === "") {
      this.showError("<p>Please tell whether you are a base worker.</p>");
      return false;
    }
    if (leadership === "") {
      this.showError("<p>Please tell whether you do leadership.</p>");
      return false;
    }
    if (management === "") {
      this.showError("<p>Please tell whether you are a manager.</p>");
      return false;
    }
    if (licensed === "") {
      this.showError(
        "<p>Please tell whether you are licensed for this service.</p>"
      );
      return false;
    }
    if (insured === "") {
      this.showError(
        "<p>Please tell whether you are insured for this service.</p>"
      );
      return false;
    }
    if (unionWorker === "") {
      this.showError(
        "<p>Please tell whether you are a union worker for this service.</p>"
      );
      return false;
    }
    if (is24Hours === "") {
      this.showError("<p>Please tell whether you provide this skill 24x7.</p>");
      return false;
    }
    this.clearError();
    return true;
  };

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSelectChange = (name) => (value) => {
    const { baseWork, leadership, management } = this.state;
    let totalCount = 0;
    if (name === "baseWork") {
      totalCount = leadership.length + management.length + value.length;
    }
    if (name === "leadership") {
      totalCount = baseWork.length + management.length + value.length;
    }
    if (name === "management") {
      totalCount = baseWork.length + leadership.length + value.length;
    }
    if (totalCount <= 3) {
      this.setState({
        [name]: value,
      });
    } else {
      this.showError(
        "<p>You can only select <b>3 functions</b> from work, leadership and management functions</p>"
      );
    }
  };

  handleSubmit = async () => {
    if (
      this.validatePersonalInfo() &&
      this.validateServiceSelection() &&
      this.validateServiceCharacteristics()
    ) {
      const {
        companyName,
        companyWebsite,
        firstName,
        lastName,
        email,
        phoneNumber,
        state,
        file,
        licensed,
        insured,
        is24Hours,
        unionWorker,
        activeKeywords,
        servicesPromoted,
        portfolioFiles,
        baseWork,
        leadership,
      } = this.state;
      this.startLoader();
      let options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      let compressedFile = "";
      let profileBase64 = "";
      if (file !== null) {
        compressedFile = await imageCompression(file, options);
        profileBase64 = await imageCompression.getDataUrlFromFile(
          compressedFile
        );
      }
      const dummyProfile = {
        companyName,
        companyWebsite,
        firstName,
        lastName,
        email,
        phoneNumber,
        state,
        profilePicture: profileBase64,
        licensed,
        insured,
        is24Hours,
        unionWorker,
        activeKeywords,
        servicesPromoted,
        jobFunctions: `${baseWork.map((x) => x.value).join(" / ")} ${
          leadership.length > 0 ? " / " : ""
        } ${leadership.map((x) => x.value).join(" / ")}`,
      };
      let portfolioBase64 = [];
      for (let i = 0; i < portfolioFiles.length; i++) {
        const imageFile = portfolioFiles[i];
        const compressedFile = await imageCompression(imageFile, options);
        let base64 = await imageCompression.getDataUrlFromFile(compressedFile);
        portfolioBase64.push(base64);
      }
      localStorage.setItem(DEMO_PROFILE_DATA, JSON.stringify(dummyProfile));
      localStorage.setItem(DEMO_PROFILE_PORTFOLIO_1, portfolioBase64[0]);
      localStorage.setItem(DEMO_PROFILE_PORTFOLIO_2, portfolioBase64[1]);
      localStorage.setItem(DEMO_PROFILE_PORTFOLIO_3, portfolioBase64[2]);
      localStorage.setItem(DEMO_PROFILE_PORTFOLIO_4, portfolioBase64[3]);
      localStorage.setItem(DEMO_PROFILE_PORTFOLIO_5, portfolioBase64[4]);
      localStorage.setItem(DEMO_PROFILE_PORTFOLIO_6, portfolioBase64[5]);
      this.stopLoader();
      setTimeout(() => {
        this.props.history.push("/profile/view");
      }, 100);
    }
  };

  handleFileUpload = (event) => {
    let file = event.target.files[0];
    this.setState({
      file,
    });
  };

  handleFilesUpload = async (event) => {
    const { portfolioFiles } = this.state;
    let totalPictures = portfolioFiles.length + event.target.files.length;
    if (totalPictures > 6) {
      this.showError("<p>Cannot upload more than 6 pictures</p>");
    } else {
      this.setState({
        [event.target.name]: [...portfolioFiles, ...event.target.files],
      });
    }
  };

  readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  removePicture = (index) => {
    let { portfolioFiles } = this.state;
    portfolioFiles.splice(index, 1);
    this.setState({
      portfolioFiles: [...portfolioFiles],
    });
  };

  saveServices = (services, keywords) => {
    this.setState({
      servicesPromoted: services,
      activeKeywords: keywords,
    });
  };

  render() {
    const {
      companyName,
      companyWebsite,
      firstName,
      lastName,
      email,
      phoneNumber,
      state,
      file,
      portfolioFiles,
      licensed,
      insured,
      is24Hours,
      unionWorker,
      baseWork,
      leadership,
      management,
      showError,
      errorMessage,
      showLoader,
      maxPromotedSkills,
      accessToken,
    } = this.state;
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        {/* {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header isDark={true} />
        ) : (
          <NewHeader />
        )} */}{" "}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <Container maxWidth="lg">
          <section className="form-wrapper select-area-main clearfix new_sept_wrapper">
            <div className="form-inner">
              <div className="form-main">
                <h1 className="first_h1">Your Services Online… In Minutes</h1>
                <p className="first_p">
                  <strong>For sampling purposes only,</strong> all information
                  will delete when you close this window, Standard Membership
                  example.
                </p>
                <div className="metropolitan-area">
                  <form>
                    <h5 className="area-title">
                      PERSONAL AND/OR COMPANY INFORMATION:{" "}
                    </h5>
                    <div className="clearfix" />
                    <div className="pro-pic visible-767">
                      <div className="dashboard-pic is-140x140">
                        <figure
                          style={{
                            backgroundImage: `url(${
                              file !== null ? URL.createObjectURL(file) : ""
                            })`,
                          }}
                        />
                        <div className="image">
                          <div className="pic-btn">
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="profile-picture-mobile"
                              name="profile-picture"
                              type="file"
                              onChange={this.handleFileUpload}
                            />
                            <label htmlFor="profile-picture-mobile">
                              <i className="fa fa-upload" aria-hidden="true" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-9-desktop is-9-tablet is-12-mobile">
                        <div className="columns is-multiline">
                          <div className="column is-3 is-3-destop is-12-mobile">
                            <input
                              type="text"
                              value={companyName}
                              placeholder="Company Name"
                              style={{
                                height: "auto",
                                padding: 10,
                                width: "100%",
                              }}
                              name="companyName"
                              onChange={this.handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="column is-3 is-3-destop is-12-mobile">
                            <input
                              type="text"
                              value={companyWebsite}
                              placeholder="Company Website"
                              style={{
                                height: "auto",
                                padding: 10,
                                width: "100%",
                              }}
                              name="companyWebsite"
                              onChange={this.handleChange}
                              className="form-control"
                            />
                          </div>
                          <div className="column is-3 is-3-destop is-12-mobile">
                            <input
                              type="text"
                              value={firstName}
                              placeholder="Name"
                              style={{
                                height: "auto",
                                padding: 10,
                                width: "100%",
                              }}
                              name="firstName"
                              onChange={this.handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="hidden-767">
                            <div className="column is-3 is-3-destop is-12-mobile" />
                          </div>
                          <div className="column is-3 is-3-destop is-12-mobile">
                            <input
                              type="text"
                              value={lastName}
                              placeholder="Last Name"
                              style={{
                                height: "auto",
                                padding: 10,
                                width: "100%",
                              }}
                              name="lastName"
                              onChange={this.handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="column is-3 is-3-destop is-12-mobile">
                            <input
                              type="email"
                              value={email}
                              placeholder="Email"
                              style={{
                                height: "auto",
                                padding: 10,
                                width: "100%",
                              }}
                              name="email"
                              onChange={this.handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="column is-3 is-3-destop is-12-mobile">
                            <input
                              type="phone"
                              value={phoneNumber}
                              placeholder="Phone Number"
                              style={{
                                height: "auto",
                                padding: 10,
                                width: "100%",
                              }}
                              name="phoneNumber"
                              onChange={this.handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="column is-3 is-3-destop is-12-mobile">
                            <input
                              type="text"
                              value={state}
                              placeholder="State"
                              style={{
                                height: "auto",
                                padding: 10,
                                width: "100%",
                              }}
                              name="state"
                              onChange={this.handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="clearfix" />
                        <div className="columns" />
                      </div>
                      <div className="column is-3-desktop is-3-tablet is-0-mobile">
                        <div className="pro-pic hidden-767">
                          <div className="dashboard-pic is-140x140">
                            <figure
                              style={{
                                backgroundImage: `url(${
                                  file !== null ? URL.createObjectURL(file) : ""
                                })`,
                              }}
                            />
                            <div className="image">
                              <div style={{ right: -10 }} className="pic-btn">
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id="profile-picture-desktop"
                                  name="profile-picture"
                                  type="file"
                                  onChange={this.handleFileUpload}
                                />
                                <label htmlFor="profile-picture-desktop">
                                  <i
                                    className="fa fa-upload"
                                    aria-hidden="true"
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <h5 className="area-title">
                      SERVICE SELECTION:{" "}
                      <span>
                        {" "}
                        Select service to promote on your sample PropAgile
                        Profile{" "}
                      </span>
                    </h5>
                    <div style={{ margin: 0 }} className="new-service-main">
                      <SupplyChainMasterFormat
                        maxHardSkillAllowed={maxPromotedSkills}
                        token={accessToken}
                        mode={SupplyChainMode.DEMO}
                        showError={this.showError}
                        saveServices={this.saveServices}
                      />
                      <div className="clearfix" />
                      <br />
                      <p>
                        <span className="has-text-weight-semibold">
                          Upload Related Portfolio For This Service:
                        </span>
                      </p>
                      <div style={{ maxWidth: "35%" }} className="file-upload">
                        <span>
                          <i className="fa fa-upload" />
                        </span>
                        <p>choose one or more files (max 6)</p>
                        <input
                          multiple={true}
                          type="file"
                          onClick={this.verifyServiceSelected}
                          onChange={this.handleFilesUpload}
                          name="portfolioFiles"
                          accept="image/jpeg,image/gif,image/png"
                        />
                      </div>
                      <div>
                        {portfolioFiles.map((file, index) => (
                          <div
                            key={index}
                            style={{ width: "inherit" }}
                            className="upload-img-view"
                          >
                            <span>
                              <img
                                className="image is-128x128"
                                src={URL.createObjectURL(file)}
                                alt=""
                              />
                            </span>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              className="remove-image"
                              onClick={() => this.removePicture(index)}
                            >
                              X
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="clearfix" />
                    <h5 className="area-title">Service parameters</h5>
                    <label className="sep_label">
                      <span className="has-text-weight-bold is-size-6">
                        Job functions:{" "}
                      </span>
                      <span className="has-text-grey is-size-7">
                        {" "}
                        Select up to 3 from drop down(s)
                      </span>
                    </label>
                    <div className="columns">
                      <div className="column is-3">
                        <label>Work Functions</label>
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            control: (base) => ({
                              ...base,
                              background: "white none repeat scroll 0 0",
                              border: "1px solid #ececec",
                            }),
                          }}
                          value={baseWork}
                          isClearable={true}
                          onChange={this.handleSelectChange("baseWork")}
                          name="baseWork"
                          id="baseWork"
                          isMulti={true}
                          options={workFunctionOptions}
                          placeholder="Please choose option(s)"
                        />
                      </div>
                      <div className="column is-3">
                        <label>Leadership Functions</label>
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            control: (base) => ({
                              ...base,
                              background: "white none repeat scroll 0 0",
                              border: "1px solid #ececec",
                            }),
                          }}
                          value={leadership}
                          isClearable={true}
                          onChange={this.handleSelectChange("leadership")}
                          name="leadership"
                          id="leadership"
                          isMulti={true}
                          options={leadershipFunctionOptions}
                          placeholder="Please choose option(s)"
                        />
                      </div>
                      <div
                        onClick={() => {
                          this.showError(
                            "<p>The demo is based on <b>standard membership</b>, and this option is only available in <b>business membership</b>. Please <b>sign up</b> with business membership to unlock full set of features.</p>"
                          );
                        }}
                        className="column is-3"
                      >
                        <label>Management Functions</label>
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            control: (base) => ({
                              ...base,
                              background: "white none repeat scroll 0 0",
                              border: "1px solid #ececec",
                            }),
                          }}
                          isDisabled={true}
                          value={management}
                          isClearable={true}
                          onChange={this.handleSelectChange("management")}
                          name="management"
                          id="management"
                          isMulti={true}
                          options={managementFunctionOptions}
                          placeholder="Please choose option(s)"
                        />
                      </div>
                    </div>
                    <label className="sep_label">
                      <span className="has-text-weight-bold">
                        Additional parameters specific to selection
                      </span>
                    </label>
                    <div className="columns">
                      <div className="column is-3">
                        <label>Licensed?</label>
                        <div className="select is-fullwidth">
                          <select
                            name="licensed"
                            onChange={this.handleChange}
                            value={licensed}
                          >
                            <option value="">Choose an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="column is-3">
                        <label>Insured?</label>
                        <div className="select is-fullwidth">
                          <select
                            name="insured"
                            onChange={this.handleChange}
                            value={insured}
                          >
                            <option value="">Choose an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="column is-3">
                        <label>Union?</label>
                        <div className="select is-fullwidth">
                          <select
                            name="unionWorker"
                            onChange={this.handleChange}
                            value={unionWorker}
                          >
                            <option value="">Choose an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="column is-3">
                        <label>24-Hour Service?</label>
                        <div className="select is-fullwidth">
                          <select
                            name="is24Hours"
                            onChange={this.handleChange}
                            value={is24Hours}
                          >
                            <option value="">Choose an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: 20, marginBottom: 10 }}
                      className="next-btn has-text-centered"
                    >
                      <button
                        type="button"
                        onClick={this.handleSubmit}
                        className="next-butn"
                      >
                        See Profile
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Container>
        <NewFooter />
        {showError && (
          <div className="modal is-active custom-modal small-popup error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                {/* {ReactHtmlParser(errorMessage)} */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(DummyDashboard);
