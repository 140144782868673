// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { withRouter } from "react-router-dom";
// import Select from "react-select";
// import ReactMapboxGl, { GeoJSONLayer, ZoomControl } from "react-mapbox-gl";

// import * as turf from "@turf/turf";
// import ServiceSteps from "./ServiceSteps";
// import Loader from "../../Loader/loader";
// import { BASE_URL } from "../../../Constants/serverConfig";
// import { useHistory } from "react-router-dom";
// import CustomPrettoSlider from "./CustomPrettoSlider";
// import { Button, Grid, TextField, Typography } from "@mui/material";
// import { baseApiPath } from "../../../Constants/utils";

// const Map = ReactMapboxGl({
//   accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
// });

// const style = "mapbox://styles/muhammad-asad-266/cjus8d2q83bsf1gqw8dbriqvm";

// const mapStyle = {
//   height: "100%",
//   width: "100%",
// };

// const CoverageManagement = ({
//   servicesPromoted,
//   membershipInfo,
//   startLoader,
//   stopLoader,
//   moveToStep,
//   token,
//   // showError,
//   // showSuccessState,
//   getServices,
// }) => {
//   const [showSuccess, setShowSuccess] = useState("");
//   const [message, setMessage] = useState("");
//   const [showError, setShowError] = useState(false);
//   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [showLoader, setShowLoader] = useState(false);
//   const [loadingMessage, setLoadingMessage] = useState("");
//   const [showMap, setShowMap] = useState(false);
//   const [showMapError, setShowMapError] = useState(false);
//   const [errorMapMessage, setErrorMapMessage] = useState("");
//   const [zipCodesRemaining, setZipCodesRemaining] = useState(0);
//   const [zipCodes, setZipCodes] = useState(null);
//   const [selectedPolygons, setSelectedPolygons] = useState([]);
//   const [counties, setCounties] = useState([]);
//   const [countySelected, setCountySelected] = useState([]);
//   const [
//     alreadyAddedCoverageSelected,
//     setAlreadyAddedCoverageSelected,
//   ] = useState(null);
//   const [selectedZipCode, setSelectedZipCode] = useState([]);
//   const [selectedActiveService, setSelectedActiveService] = useState({});
//   const [selectedMetroArea] = useState("NYMA");
//   const [center, setCenter] = useState([]);
//   /* ------------------------------------- */
//   const [newZipCodes, setNewZipCodes] = useState(null);
//   const [oldZipCode, setOldZipCode] = useState(null);
//   const [currentRadius, setCurrentRadius] = useState(1);
//   const [oldRadius, setOldRadius] = useState(-1);
//   const [disableSelectService, setDisableSelectService] = useState(true);

//   /* ------------------------------------- */
//   // const [selectedMap, setSelectedMap] = useState(-1);
//   // const [selectedCoverage, setSelectedCoverage] = useState(null);
//   // const [showCoverageDropdown, setShowCoverageDropdown] = useState(false);
//   // const [mapSelected, setMapSelected] = useState(false);
//   // const [coverageName, setCoverageName] = useState("");

//   const history = useHistory();

//   useEffect(() => {
//     setZipCodesRemaining(membershipInfo.zip_code_remaining);
//     getCountiesListForMetroArea(selectedMetroArea);
//     getGeoJsonForMap(selectedMetroArea);
//   }, []);

//   useEffect(() => {
//     let elements = document.getElementsByClassName("popup-item-boxes");
//     equalHeight(elements);
//   });

//   const handleShowSuccess = (message) => {
//     setShowSuccess(true);
//     setMessage(message);
//   };
//   /* Done */
//   const clearMessage = () => {
//     setShowSuccess(false);
//     setMessage("");
//   };

//   /*   componentDidUpdate() {
//     let elements = document.getElementsByClassName("popup-item-boxes");
//     this.equalHeight(elements);
//   } */

//   /* Done */
//   const equalHeight = (elements) => {
//     let tallest = 0;
//     for (let index = 0; index < elements.length; index++) {
//       let thisHeight = elements[index].offsetHeight;
//       if (thisHeight > tallest) {
//         tallest = thisHeight;
//       }
//     }
//     for (let index = 0; index < elements.length; index++) {
//       let element = elements[index];
//       element.style.height = `${tallest}px`;
//     }
//   };
//   /* Done */
//   const closePopup = () => {
//     setShowMap(false);
//     // setMapSelected(true);
//     setSelectedZipCode([]);
//     setSelectedPolygons([]);
//     setZipCodesRemaining(membershipInfo.zip_code_remaining);
//     setSelectedActiveService({});
//   };

//   /* Done */
//   const showConfirmation = () => {
//     setShowDeleteConfirmation(true);
//   };
//   /* Done */
//   const clearConfirmation = () => {
//     setShowDeleteConfirmation(false);
//   };

//   const handleShowMapError = (errorMapMessage) => {
//     setShowMapError(true);
//     setErrorMapMessage(errorMapMessage);
//   };

//   /* Done */
//   const clearMapError = () => {
//     setShowMapError(false);
//     setErrorMessage("");
//   };

//   const handleClick = (map, event) => {
//     // queryRenderedFeatures: to pick the details of areas mouse is hovering on.
//     // But since this is inside a click function it will only get details of area that is clicked on
//     const features = map.queryRenderedFeatures(event.point);

//     // console.log("Features => ", features);
//     if (
//       // features.length > 0
//       features[0] !== undefined &&
//       features[0].properties !== undefined &&
//       features[0].properties.zipCode !== undefined
//     ) {
//       let index;
//       features.find((item) => {
//         index = selectedZipCode.findIndex(
//           (obj) => obj.zipCode === item.properties.zipCode
//         );
//       });
//       console.log("index => ", index);
//       console.log("selectedZipCode => ", selectedZipCode);
//       if (index === -1) {
//         //adding the zipcode that was clicked on
//         if (zipCodesRemaining > 0) {
//           const rawZipCodes = zipCodes.features.find(
//             (feature) =>
//               feature.properties.zipCode === features[0].properties.zipCode
//           );
//           console.log("rawZipCodes => ", rawZipCodes);
//           var myGeoJSON = {};
//           myGeoJSON.type = "FeatureCollection";
//           myGeoJSON.features = [rawZipCodes];
//           myGeoJSON.radius = currentRadius;
//           myGeoJSON.rootZipCode = rawZipCodes.properties.zipCode;
//           setSelectedPolygons([...selectedPolygons, myGeoJSON]);
//           setSelectedZipCode([
//             ...selectedZipCode,
//             {
//               metroArea: selectedMetroArea,
//               zipCode: features[0].properties.zipCode,
//               radius: currentRadius,
//               rootZipCode: features[0].properties.zipCode,
//             },
//           ]);
//           console.log("setting:");
//           setNewZipCodes(features[0].properties.zipCode);
//           setZipCodesRemaining(zipCodesRemaining - 1);
//           /* -------------------------------------- */

//           // axios
//           //   .get(
//           //     `${baseApiPath}zip-code/geojson?zip_code=${features[0].properties.zipCode}&radius=${currentRadius}`
//           //   )
//           //   .then((res) => {
//           //     console.log("selectedPolygons => ", selectedPolygons);
//           //     console.log("Res of zip and Radius => ", res);
//           //     setSelectedPolygons([...selectedPolygons, res.data]);
//           //     // setSelectedPolygons([...selectedPolygons, res.data]);
//           //     let tempZipCode = res.data.features.map((item) => {
//           //       console.log(Object.keys(item.properties).length);
//           // if (Object.keys(item.properties).length !== 0) {
//           //   return {
//           //     metroArea: selectedMetroArea,
//           //     zipCode: item.properties.zipCode,
//           //   };
//           // }
//           // });
//           // setSelectedZipCode([...selectedZipCode, tempZipCode]);
//           // setSelectedZipCode([
//           //   ...selectedZipCode,
//           //   {
//           //     metroArea: selectedMetroArea,
//           //     zipCode: features[0].properties.zipCode,
//           //   },
//           // ]);

//           //   setZipCodesRemaining(
//           //     zipCodesRemaining - res.data.features.length
//           //   );

//           //   setShowLoader(false);
//           // })
//           // .catch((err) => {
//           //   console.log("Err of zip and radius => ", err);
//           //   setShowLoader(false);
//           // });
//         } else {
//           handleShowMapError(
//             `You have reached your ${membershipInfo.max_zip_code_allowed} zip code limit, to increase coverage you must upgrade`
//           );
//         }
//       } else {
//         //removing already selected zipcode that was clicked on
//         console.log("removing ");
//         setNewZipCodes(null);
//         var array1 = [...selectedPolygons];
//         var array2 = [...selectedZipCode];
//         array1.splice(index, 1);
//         array2.splice(index, 1);
//         setSelectedPolygons(array1);
//         setSelectedZipCode(array2);
//         setZipCodesRemaining(zipCodesRemaining + 1);
//       }
//     }
//   };
//   /*  useEffect(() => {
//     console.log("In effect selectedPolygons: ", selectedPolygons);
//     console.log("In effect selectedZipCode: ", selectedZipCode);
//   }, [selectedZipCode]); */
//   /* 
//   saving onClick function here
//    if (
//       features[0] !== undefined &&
//       features[0].properties !== undefined &&
//       features[0].properties.zipCode !== undefined
//     ) {
//       const index = selectedZipCode.findIndex(
//         (obj) => obj.zipCode === features[0].properties.zipCode
//       );
//       if (index === -1) {
//         //adding the zipcode that was clicked on
//         if (zipCodesRemaining > 0) {
//           const rawZipCodes = zipCodes.features.find(
//             (feature) =>
//               feature.properties.zipCode === features[0].properties.zipCode
//           );
//           console.log("zipCodes => ", zipCodes);
//           console.log("rawZipCodes => ", rawZipCodes);
//           var myGeoJSON = {};
//           myGeoJSON.type = "FeatureCollection";
//           myGeoJSON.features = [rawZipCodes];
//           setSelectedPolygons([...selectedPolygons, myGeoJSON]);
//           setSelectedZipCode([
//             ...selectedZipCode,
//             {
//               metroArea: selectedMetroArea,
//               zipCode: features[0].properties.zipCode,
//             },
//           ]);
//           setNewZipCodes(features[0].properties.zipCode);
//           setZipCodesRemaining(zipCodesRemaining - 1);
//         } else {
//           handleShowMapError(
//             `You have reached your ${membershipInfo.max_zip_code_allowed} zip code limit, to increase coverage you must upgrade`
//           );
//         }
//       } else {
//         //removing already selected zipcode that was clicked on
//         var array1 = [...selectedPolygons];
//         var array2 = [...selectedZipCode];
//         array1.splice(index, 1);
//         array2.splice(index, 1);
//         setSelectedPolygons(array1);
//         setSelectedZipCode(array2);
//         setZipCodesRemaining(zipCodesRemaining + 1);
//       }
//     }
//   */

//   /* Done */
//   const handleSelectChange = (value) => {
//     console.log("value in handleSelectChange: ", value);
//     console.log("alreadySelectedCounty: ", alreadySelectedCounty);
//     const alreadySelectedCounty = [...countySelected];
//     setCountySelected(value);
//     value.forEach((county) => {
//       const index = alreadySelectedCounty.findIndex(
//         (x) => x.value === county.value
//       );
//       alreadySelectedCounty.splice(index, 1);
//       if (index === -1) {
//         populateZipCodes(county);
//       }
//     });
//     if (alreadySelectedCounty.length === 1) {
//       removeSelectedCoverage(alreadySelectedCounty[0]);
//     }
//   };
//   /* Done */
//   const handleAddedCoverageSelected = (value) => {
//     console.log("value in handleAddedCoverageSelected: ", value);
//     if (alreadyAddedCoverageSelected === null && value !== null) {
//       const zipCodesToAdd = servicesPromoted.find((x) => x.id === value.value)
//         .zip_code;
//       if (
//         zipCodesToAdd.length + selectedZipCode.length >
//         membershipInfo.zip_code_remaining
//       ) {
//         handleShowMapError(
//           `You have reached your ${membershipInfo.max_zip_code_allowed} zip code limit, to increase coverage you must upgrade`
//         );
//       } else {
//         const { polygons, zipcodes } = getNewPolygonsAndZipCodes(
//           zipCodesToAdd,
//           selectedZipCode
//         );
//         setSelectedPolygons([...selectedPolygons, ...polygons]);
//         setSelectedZipCode([...selectedZipCode, ...zipcodes]);
//         setZipCodesRemaining(zipCodesRemaining - zipcodes.length);
//       }
//     } else if (
//       value !== null &&
//       alreadyAddedCoverageSelected.value !== value.value
//     ) {
//       const zipCodesToAdd = servicesPromoted.find((x) => x.id === value.value)
//         .zip_code;
//       const zipCodesToRemove = servicesPromoted.find(
//         (x) => x.id === alreadyAddedCoverageSelected.value
//       ).zip_code;
//       if (
//         zipCodesToAdd.length -
//           zipCodesToRemove.length +
//           selectedZipCode.length >
//         membershipInfo.zip_code_remaining
//       ) {
//         handleShowMapError(
//           `You have reached your ${membershipInfo.max_zip_code_allowed} zip code limit, to increase coverage you must upgrade`
//         );
//       } else {
//         removeSelectedCoverageZipCodes(zipCodesToRemove);
//         setTimeout(() => {
//           const { polygons, zipcodes } = getNewPolygonsAndZipCodes(
//             zipCodesToAdd,
//             selectedZipCode
//           );
//           setSelectedPolygons([...selectedPolygons, ...polygons]);
//           setSelectedZipCode([...selectedZipCode, ...zipcodes]);
//           setZipCodesRemaining(zipCodesRemaining - zipcodes.length);
//         }, 50);
//       }
//     } else if (value === null) {
//       const zipCodesToRemove = servicesPromoted.find(
//         (x) => x.id === alreadyAddedCoverageSelected.value
//       ).zip_code;
//       removeSelectedCoverageZipCodes(zipCodesToRemove);
//     }
//     setAlreadyAddedCoverageSelected(value);
//   };
//   /* --------------- */
//   /* Done */
//   const getNewPolygonsAndZipCodes = (zipCodesToAdd, selectedZipCode) => {
//     let polygons = [];
//     let zipcodes = [];
//     zipCodesToAdd.forEach((zipCodeToAdd) => {
//       if (
//         selectedZipCode.findIndex(
//           (zipCode) => zipCodeToAdd.zip_code === zipCode.zipCode
//         ) === -1
//       ) {
//         const rawZipCodes = zipCodes.features.find(
//           (feature) => feature.properties.zipCode === zipCodeToAdd.zip_code
//         );
//         polygons.push({
//           type: "FeatureCollection",
//           features: [rawZipCodes],
//         });
//         zipcodes.push({
//           metroArea: selectedMetroArea,
//           zipCode: zipCodeToAdd.zip_code,
//         });
//       }
//     });
//     return { polygons, zipcodes };
//   };
//   const handleShowMap = () => {
//     setShowMap(true);
//   };
//   /* Done */
//   const getCountiesListForMetroArea = (name) => {
//     startLoader();
//     let url = `${BASE_URL}/zip-code/metropolitan-area/${name}/county-list`;
//     axios
//       .get(url)
//       .then((response) => {
//         setCounties(
//           response.data.map((x) => ({
//             value: x.county,
//             label: x.county,
//           }))
//         );
//         stopLoader();
//       })
//       .catch((error) => {
//         console.error(error);
//         setCounties([]);
//         stopLoader();
//       });
//   };
//   /* Done */
//   const getGeoJsonForMap = (areaName) => {
//     startLoader();
//     let url = `${BASE_URL}/zip-code/metropolitan-area/${areaName}/county`;
//     axios
//       .get(url)
//       .then((response) => {
//         const point =
//           response.data.features[0].geometry.geometries[0].coordinates;
//         setCenter(point);
//         setZipCodes(response.data);
//         setDisableSelectService(false);
//         console.log("Zipcodes fetched", response.data);
//         // setShowMap(true);
//         setShowMap(false);
//         stopLoader();
//       })
//       .catch((error) => {
//         console.error(error);
//         stopLoader();
//       });
//   };
//   /* Done */
//   const removeSelectedCoverageZipCodes = (zipCodes) => {
//     for (let i = 0; i < zipCodes.length; i++) {
//       let index = selectedPolygons.findIndex(
//         (x) =>
//           x.features[0].properties.zipCode.toLowerCase() ===
//           zipCodes[i].zip_code.toLowerCase()
//       );
//       if (index !== -1) {
//         selectedPolygons.splice(index, 1);
//       }
//     }
//     const newZipCodes = selectedPolygons.map((x) => ({
//       metroArea: selectedMetroArea,
//       zipCode: x.features[0].properties.zipCode,
//     }));
//     setSelectedPolygons([...selectedPolygons]);
//     setSelectedZipCode([...newZipCodes]);
//     setZipCodesRemaining(
//       membershipInfo.zip_code_remaining - newZipCodes.length
//     );
//   };
//   /* Done */
//   const removeSelectedCoverage = (county) => {
//     const newZipCodes = selectedPolygons
//       .filter(
//         (x) =>
//           x.features[0].properties.county.toLowerCase() !==
//           county.value.toLowerCase()
//       )
//       .map((x) => ({
//         metroArea: selectedMetroArea,
//         zipCode: x.features[0].properties.zipCode,
//       }));
//     const newPolygons = selectedPolygons.filter(
//       (x) =>
//         x.features[0].properties.county.toLowerCase() !==
//         county.value.toLowerCase()
//     );
//     setSelectedPolygons([...newPolygons]);
//     setSelectedZipCode([...newZipCodes]);
//     setZipCodesRemaining(
//       membershipInfo.zip_code_remaining - newZipCodes.length
//     );
//   };
//   /* Done */
//   const populateZipCodesHavingRadius = (value) => {
//     startLoader();
//     let url = `${BASE_URL}/zip-code/metropolitan-area/${selectedMetroArea}/county/${value.value}`;
//     axios
//       .get(url)
//       .then((response) => {
//         console.log("county response => ", response);
//         if (
//           response.data.features.length + selectedZipCode.length >
//           membershipInfo.zip_code_remaining
//         ) {
//           handleShowMapError(
//             `You have reached your ${membershipInfo.max_zip_code_allowed} zip code limit, to increase coverage you must upgrade`
//           );
//         } else {
//           const points = turf.points(
//             response.data.features.map(
//               (x) => x.geometry.geometries[0].coordinates
//             )
//           );
//           const center = turf.center(points);
//           const polygons = [];
//           const zipCodes = [];
//           response.data.features.forEach((feature) => {
//             if (
//               selectedZipCode.findIndex(
//                 (zipCode) => feature.properties.zipCode === zipCode.zipCode
//               ) === -1
//             ) {
//               polygons.push({
//                 type: "FeatureCollection",
//                 features: [feature],
//               });
//               zipCodes.push({
//                 metroArea: selectedMetroArea,
//                 zipCode: feature.properties.zipCode,
//               });
//             }
//           });
//           setSelectedPolygons([...selectedPolygons, ...polygons]);
//           setSelectedZipCode([...selectedZipCode, ...zipCodes]);
//           setZipCodesRemaining(zipCodesRemaining - zipCodes.length);
//           setCenter(center.geometry.coordinates);
//         }
//         stopLoader();
//       })
//       .catch((error) => {
//         console.error(error);
//         stopLoader();
//       });
//   };
//   const populateZipCodes = (value) => {
//     startLoader();
//     let url = `${BASE_URL}/zip-code/metropolitan-area/${selectedMetroArea}/county/${value.value}`;
//     axios
//       .get(url)
//       .then((response) => {
//         console.log("county response => ", response);
//         if (
//           response.data.features.length + selectedZipCode.length >
//           membershipInfo.zip_code_remaining
//         ) {
//           handleShowMapError(
//             `You have reached your ${membershipInfo.max_zip_code_allowed} zip code limit, to increase coverage you must upgrade`
//           );
//         } else {
//           const points = turf.points(
//             response.data.features.map(
//               (x) => x.geometry.geometries[0].coordinates
//             )
//           );
//           console.log("points => ", points);
//           const center = turf.center(points); // returns absolute center point of all feature/featuresCollections
//           console.log("center => ", center);
//           const polygons = [];
//           const zipCodes = [];
//           response.data.features.forEach((feature) => {
//             if (
//               selectedZipCode.findIndex(
//                 (zipCode) => feature.properties.zipCode === zipCode.zipCode
//               ) === -1
//             ) {
//               polygons.push({
//                 type: "FeatureCollection",
//                 features: [feature],
//               });
//               zipCodes.push({
//                 metroArea: selectedMetroArea,
//                 zipCode: feature.properties.zipCode,
//               });
//             }
//           });
//           setSelectedPolygons([...selectedPolygons, ...polygons]);
//           setSelectedZipCode([...selectedZipCode, ...zipCodes]);
//           setZipCodesRemaining(zipCodesRemaining - zipCodes.length);
//           setCenter(center.geometry.coordinates);
//         }
//         stopLoader();
//       })
//       .catch((error) => {
//         console.error(error);
//         stopLoader();
//       });
//   };
//   /* Done */
//   const getFormattedService = (service) => {
//     let formattedZipCodes = [];
//     for (let i = 0; i < selectedZipCode.length; i++) {
//       if (selectedZipCode[i].zipCode !== undefined) {
//         formattedZipCodes.push({
//           zip_code: selectedZipCode[i].zipCode,
//           metropolitan_area: selectedZipCode[i].metroArea,
//         });
//       }
//     }
//     console.log("formattedZipCodes: ", formattedZipCodes);
//     return {
//       selected_service_id: service.selected_service_id,
//       zip_code: formattedZipCodes,
//       metropolitan_area: [{ name: "NYMA" }],
//     };
//   };
//   /* Done */
//   const selectActiveService = (service) => {
//     const isAlreadySelected =
//       selectedActiveService.selected_service_id === service.selected_service_id;
//     if (service.zip_code !== null) {
//       const zipCodesToAdd = service.zip_code.map((x) => {
//         return {
//           metroArea: x.metropolitan_area,
//           zipCode: x.zip_code,
//         };
//       });
//       const newGeoJSONS = getNewGeoJsonWithAddedZipCodes(
//         zipCodesToAdd,
//         "zipCode"
//       );
//       if (
//         Object.keys(selectedActiveService).length === 0 ||
//         selectedActiveService.selected_service_id !==
//           service.selected_service_id
//       ) {
//         setSelectedActiveService(service);
//         setSelectedZipCode([...zipCodesToAdd]);
//         setSelectedPolygons([...newGeoJSONS]);
//         setZipCodesRemaining(
//           membershipInfo.zip_code_remaining - zipCodesToAdd.length
//         );
//       } else if (isAlreadySelected) {
//         setSelectedActiveService({});
//         setSelectedZipCode([]);
//         setSelectedPolygons([]);
//         setZipCodesRemaining(membershipInfo.zip_code_remaining);
//       }
//     } else {
//       setSelectedActiveService(service);
//       setSelectedZipCode([]);
//       setSelectedPolygons([]);
//       setZipCodesRemaining(membershipInfo.zip_code_remaining);
//       handleShowMap();
//     }
//   };

//   /* Done */
//   const assignCoverage = () => {
//     closePopup();
//     if (token !== null) {
//       if (selectedZipCode.length === 0) {
//         showError("Please choose some zipcodes to assign to service");
//         return;
//       }
//       if (Object.keys(selectedActiveService).length === 0) {
//         showError("Please choose service to assign to coverage");
//         return;
//       }
//       let services = [];
//       services.push(getFormattedService(selectedActiveService));
//       let body = {
//         selected_services: services,
//       };
//       startLoader();
//       axios
//         .patch(`${BASE_URL}/service/coverage`, JSON.stringify(body), {
//           headers: {
//             authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         })
//         .then((response) => {
//           stopLoader();
//           if (response.status === 200) {
//             handleShowSuccess("Coverage assigned successfully.");
//             setSelectedPolygons([]);
//             setSelectedZipCode([]);
//             // setMapSelected(false);
//             // setSelectedCoverage(null);
//             // setShowCoverageDropdown(false);
//             setZipCodesRemaining(membershipInfo.zip_code_remaining);
//             setSelectedActiveService({});
//             setCountySelected([]);
//             setAlreadyAddedCoverageSelected(null);

//             getServices();
//             moveToStep(5);
//           }
//         })
//         .catch((error) => {
//           stopLoader();
//           if (error.response && typeof error.response.data === "string") {
//             showError(error.response.data);
//           }
//           console.error(error);
//         });
//     }
//   };
//   /* Done */
//   const getNewGeoJsonWithAddedZipCodes = (zipCodesToAdd, key) => {
//     const newGeoJSONS = [];
//     // console.log("zipCodes => ", zipCodes);
//     for (let index = 0; index < zipCodesToAdd.length; index++) {
//       const element = zipCodesToAdd[index];
//       const rawZipCodes = zipCodes.features.find(
//         (feature) => feature.properties.zipCode === element[key]
//       );
//       const myGeoJSON = {};
//       myGeoJSON.type = "FeatureCollection";
//       myGeoJSON.features = [rawZipCodes];
//       newGeoJSONS.push(myGeoJSON);
//     }
//     return newGeoJSONS;
//   };

//   //To show the total services that are added for individual users
//   const renderPromotedServices = (services) => {
//     return services.map((x) => {
//       return (
//         <div
//           key={`level:${x.level}:serviceId:${x.serviceId}`}
//           className="popup-item-boxes"
//         >
//           <div
//             onClick={() => {
//               if (!disableSelectService) {
//                 selectActiveService(x);
//               }
//             }}
//             className="tbl"
//           >
//             <div
//               onClick={(event) => event.preventDefault()}
//               className="tbl-cell md_ch_2"
//             >
//               <div className="tbl">
//                 <div className="tbl-cell">{x.name}</div>
//               </div>
//             </div>
//             <div
//               onClick={(event) => event.preventDefault()}
//               className="tbl-cell md_ch_1"
//             >
//               <div className="md-checkbox">
//                 <input
//                   id={`level:${x.level}:serviceId:${x.serviceId}`}
//                   type="checkbox"
//                   onChange={() => {}}
//                   onClick={(event) => event.preventDefault()}
//                   checked={
//                     selectedActiveService.serviceId === x.serviceId &&
//                     selectedActiveService.level === x.level &&
//                     selectedActiveService.selected_service_id ===
//                       x.selected_service_id
//                   }
//                 />
//                 <label htmlFor={`level:${x.level}:serviceId:${x.serviceId}`} />
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     });
//   };
//   /* Done */
//   const redirectToMemberships = () => {
//     history.push("/vendor/subscription-upgrade");
//   };

//   const removeExtraEntries = () => {
//     setShowLoader(true);
//     const zipCodeArray = selectedZipCode.filter((item) => {
//       return item.rootZipCode !== newZipCodes;
//     });
//     const pollygonArray = selectedPolygons.filter((item) => {
//       return item.rootZipCode !== newZipCodes;
//     });
//     const tempZipCodeRemaining =
//       zipCodesRemaining + (selectedZipCode.length - zipCodeArray.length);

//     const radiusDecreased = true;
//     setShowLoader(false);
//     console.log("===> From Remove Funtion <===");
//     addNewEntries(
//       zipCodeArray,
//       pollygonArray,
//       radiusDecreased,
//       tempZipCodeRemaining
//     );
//   };

//   const addNewEntries = async (
//     zipCodeArray,
//     pollygonArray,
//     radiusDecreased,
//     tempZipCodeRemaining
//   ) => {
//     setShowLoader(true);
//     let url = `${baseApiPath}zip-code/geojson?zip_code=${newZipCodes}&radius=${currentRadius}`;
//     await axios
//       .get(url)
//       .then((response) => {
//         console.log("Response of Zip+Radius: ", response.data);
//         if (
//           membershipInfo.membership_name !== "Business" &&
//           response.data.features.length + zipCodeArray.length >
//             membershipInfo.zip_code_remaining
//         ) {
//           handleShowMapError(
//             `You have reached your ${membershipInfo.max_zip_code_allowed} zip code limit, to increase coverage you must upgrade`
//           );
//         } else {
//           const polygons = [];
//           const zipCodes = [];
//           response.data.features.forEach((feature) => {
//             let index = zipCodeArray.findIndex(
//               (zipCode) => feature.properties.zipCode === zipCode.zipCode
//             );

//             console.log("index from zipCodeArray: ", index);
//             if (index === -1) {
//               polygons.push({
//                 type: "FeatureCollection",
//                 features: [feature],
//                 radius: currentRadius,
//                 rootZipCode: newZipCodes,
//               });
//               zipCodes.push({
//                 metroArea: selectedMetroArea,
//                 zipCode: feature.properties.zipCode,
//                 radius: currentRadius,
//                 rootZipCode: newZipCodes,
//               });
//               console.log("Before setting: ");
//               console.log("zipCodes to be added: ", zipCodes);
//               setSelectedPolygons([...pollygonArray, ...polygons]);
//               setSelectedZipCode([...zipCodeArray, ...zipCodes]);
//               if (!!radiusDecreased) {
//                 setZipCodesRemaining(tempZipCodeRemaining - zipCodes.length);
//               } else {
//                 setZipCodesRemaining(zipCodesRemaining - zipCodes.length);
//               }
//             }
//           });
//           console.log("New Added are: ", zipCodes.length);
//         }

//         setOldRadius(currentRadius);
//         setOldZipCode(newZipCodes);

//         const points = turf.points(
//           response.data.features
//             .filter((item) => {
//               if (Object.keys(item.properties).length > 0) {
//                 return item.geometry.geometries[0].coordinates;
//               }
//             })
//             .map((item) => {
//               return item.geometry.geometries[0].coordinates;
//             })
//         );
//         const center = turf.center(points); // returns absolute center point of all feature/featuresCollections
//         setCenter(center.geometry.coordinates);

//         stopLoader();
//       })
//       .catch((err) => {
//         console.log("Zip + Radius err: ", err);
//       });
//     setShowLoader(false);
//   };

//   const checkRadiusFromSelectedZipCodes = () => {
//     for (let x = 0; x < selectedZipCode.length; x++) {
//       if (selectedZipCode[x].radius < currentRadius) {
//         return true;
//       }
//     }
//     return false;
//   };

//   const checkZipCodeFromSelectedZipCodes = () => {
//     for (let x = 0; x < selectedZipCode.length; x++) {
//       if (selectedZipCode[x].rootZipCode === newZipCodes) {
//         return true;
//       }
//     }
//     return false;
//   };

//   const handleZipAndRadiusSelect = async () => {
//     if (currentRadius < oldRadius && newZipCodes === oldZipCode) {
//       removeExtraEntries();
//     } else if (
//       checkRadiusFromSelectedZipCodes() &&
//       checkZipCodeFromSelectedZipCodes()
//     ) {
//       removeExtraEntries();
//     } else {
//       addNewEntries(selectedZipCode, selectedPolygons);
//     }
//   };

//   useEffect(() => {
//     if (
//       !!newZipCodes &&
//       newZipCodes !== undefined &&
//       newZipCodes.toString().length === 5 /* newZipCodes > 9999 */
//     ) {
//       handleZipAndRadiusSelect();
//     }
//   }, [currentRadius, newZipCodes]);

//   let features = [];
//   for (let i = 0; i < selectedPolygons.length; i++) {
//     features.push(...selectedPolygons[i].features);
//   }
//   const polygonsData = {
//     type: "FeatureCollection",
//     features: [...features],
//   };

//   const showStateUpdates = () => {
//     console.log("selected Polygons => ", selectedPolygons);
//     console.log("selected zipCodes => ", selectedZipCode);
//     console.log("polygonsData => ", polygonsData);
//     console.log(
//       "membershipInfo => ",
//       membershipInfo.membership_name === "Business"
//     );
//   };
//   useEffect(() => {
//     window.dispatchEvent(new Event("resize"));
//   });

//   return (
//     <>
//       <React.Fragment>
//         {showDeleteConfirmation && (
//           <div className="modal is-active custom-modal error-message">
//             <div className="modal-background" />
//             <div className="modal-content">
//               <div className="error-message-modal">
//                 <button onClick={clearConfirmation} className="modal-close" />
//                 <p>Are you sure to delete this coverage?</p>
//                 <button /* onClick={deleteCoverage} */ className="btn-fill">
//                   Delete
//                 </button>
//                 <button onClick={clearConfirmation} className="btn-empty">
//                   Cancel
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}
//         {showError && (
//           <div className="modal is-active custom-modal error-message">
//             <div className="modal-background" />
//             <div className="modal-content">
//               <div className="error-message-modal">
//                 <button /* onClick={clearError} */ className="modal-close" />
//                 <p>{errorMessage}</p>
//                 {errorMessage.includes("upgrade") ? (
//                   <a onClick={redirectToMemberships} className="btn-fill">
//                     Upgrade
//                   </a>
//                 ) : (
//                   <a /* onClick={clearError} */ className="btn-fill">OK</a>
//                 )}
//               </div>
//             </div>
//           </div>
//         )}
//         {showSuccess && (
//           <div className="modal is-active custom-modal success-message">
//             <div className="modal-background" />
//             <div className="modal-content">
//               <div className="success-message-modal">
//                 <button onClick={clearMessage} className="modal-close" />
//                 <p>{message}</p>
//                 <a onClick={clearMessage} className="btn-fill">
//                   OK
//                 </a>
//               </div>
//             </div>
//           </div>
//         )}

//         <div className={`modal mainn-map ${showMap ? "is-active" : ""}`}>
//           <div className="modal-background" />
//           <div className="modal-card">
//             <button
//               onClick={closePopup}
//               className="delete"
//               aria-label="close"
//             />
//             <section className={"modal-card-body"}>
//               {showMapError && (
//                 <div className="modal is-active custom-modal error-message">
//                   <div className="modal-background" />
//                   <div className="modal-content">
//                     <div className="error-message-modal">
//                       <button onClick={clearMapError} className="modal-close" />
//                       <p>{errorMapMessage}</p>
//                       {errorMapMessage.includes("upgrade") ? (
//                         <a className="btn-fill" onClick={redirectToMemberships}>
//                           Upgrade
//                         </a>
//                       ) : (
//                         <a onClick={clearMapError} className="btn-fill">
//                           OK
//                         </a>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               )}
//               <h1>
//                 Select area of coverage for this service{" "}
//                 <span className="zip-code-counter">
//                   {membershipInfo.membership_name === "Business"
//                     ? "unlimited"
//                     : Number(membershipInfo.max_zip_code_allowed) -
//                       Number(zipCodesRemaining) /
//                         membershipInfo.max_zip_code_allowed}
//                 </span>
//               </h1>
//               <Grid container>
//                 <Grid item xs={12} sm={12} md={6} lg={6}>
//                   <div>
//                     <Typography>Enter ZipCode:</Typography>
//                     <TextField
//                       type="number"
//                       variant="outlined"
//                       value={newZipCodes}
//                       name="zipCodeSelected"
//                       id="zipCodeSelected"
//                       onChange={(e) => {
//                         if (e.target.value < 100000) {
//                           setNewZipCodes(e.target.value);
//                         }
//                       }}
//                       theme={(theme) => ({
//                         ...theme,
//                         borderRadius: 0,
//                         spacing: {
//                           baseUnit: 4.84,
//                           controlHeight: 46,
//                           menuGutter: 9.68,
//                         },
//                       })}
//                     />
//                   </div>
//                   <CustomPrettoSlider
//                     radius={currentRadius}
//                     setRadius={setCurrentRadius}
//                   />
//                   {/* <Button onClick={showStateUpdates}>ShowChanges</Button> */}
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={6} lg={6}>
//                   <div className="columns">
//                     {membershipInfo.membership_name !== "Free" && (
//                       <div className="column has-text-left is-6">
//                         <p className="has-text-left has-text-weight-bold">
//                           Select a county
//                         </p>
//                         <Select
//                           value={countySelected}
//                           isMulti={true}
//                           isClearable={true}
//                           onChange={handleSelectChange}
//                           name="countySelected"
//                           id="countySelected"
//                           options={counties}
//                           theme={(theme) => ({
//                             ...theme,
//                             borderRadius: 0,
//                             spacing: {
//                               baseUnit: 4.84,
//                               controlHeight: 46,
//                               menuGutter: 9.68,
//                             },
//                           })}
//                         />
//                         <label className="has-text-weight-bold">
//                           Clearing the selection will clear all of the selected
//                           zip codes from map
//                         </label>
//                         <br />
//                         <br />
//                       </div>
//                     )}
//                     {/*<div className="column has-text-left is-6">
//                   <p className="has-text-left has-text-weight-bold">
//                     Pre-selected service coverage maps
//                   </p>
//                   <Select
//                     value={alreadyAddedCoverageSelected}
//                     isClearable={true}
//                     onChange={handleAddedCoverageSelected}
//                     name="alreadyAddedCoverageSelected"
//                     id="alreadyAddedCoverageSelected"
//                     options={servicesPromoted
//                       .filter((x) => x.single_map === true)
//                       .map((x, index) => ({
//                         label: `Map-${index + 1}`,
//                         value: x.id,
//                       }))}
//                     placeholder={
//                       servicesPromoted.filter((x) => x.single_map === true)
//                         .length === 0
//                         ? "No coverages saved yet."
//                         : "Select..."
//                     }
//                     isDisabled={
//                       servicesPromoted.filter((x) => x.single_map === true)
//                         .length === 0
//                     }
//                     theme={(theme) => ({
//                       ...theme,
//                       borderRadius: 0,
//                       spacing: {
//                         baseUnit: 4.84,
//                         controlHeight: 46,
//                         menuGutter: 9.68,
//                       },
//                     })}
//                   />
//                   <br />
//                 </div>
//               */}
//                   </div>
//                 </Grid>
//               </Grid>

//               <div className="mappp">
//                 {zipCodes !== null && (
//                   <Map
//                     // eslint-disable-next-line react/style-prop-object
//                     style={style}
//                     containerStyle={mapStyle}
//                     center={center}
//                     onClick={handleClick}
//                     movingMethod="flyTo"
//                     width={"auto"}
//                     onStyleLoad={(map) => map.resize()}
//                   >
//                     <GeoJSONLayer
//                       // key={geoJson.features[0].properties.zipCode}
//                       data={polygonsData}
//                       fillPaint={{
//                         "fill-color": "rgba(200, 100, 240, 0.4)",
//                         "fill-outline-color": "rgba(0, 0, 0, 1)",
//                       }}
//                     />
//                     <GeoJSONLayer
//                       data={zipCodes}
//                       fillPaint={{
//                         "fill-color": "rgba(200, 100, 240, 0.0)",
//                         "fill-outline-color": "rgba(0, 0, 0, 1)",
//                       }}
//                       symbolLayout={{
//                         "text-field": "{zipCode}",
//                         "text-font": [
//                           "Open Sans Semibold",
//                           "Arial Unicode MS Bold",
//                         ],
//                         "text-offset": [0, 0.6],
//                         "text-anchor": "top",
//                         "symbol-placement": "point",
//                         "text-size": 10,
//                       }}
//                     />
//                     <ZoomControl />
//                   </Map>
//                 )}
//                 <p>
//                   Selected zip codes will be available at a single click when
//                   selecting coverage for other services
//                 </p>
//                 <div className="map-buton">
//                   <button
//                     onClick={
//                       Object.keys(selectedActiveService).length !== 0 &&
//                       selectedZipCode.length !== 0
//                         ? assignCoverage
//                         : null
//                     }
//                     className="btn-fill"
//                   >
//                     Assign & Exit
//                   </button>
//                 </div>
//               </div>
//             </section>
//           </div>
//         </div>

//         <Loader text={loadingMessage} open={showLoader} />

//         <div className="services_main_body">
//           <div className="container">
//             <ServiceSteps moveToStep={moveToStep} service={"coverage"} />

//             <div className="s_m_b_head  clearfix">
//               <span className="s_m_1">Step 3 of 4:</span>{" "}
//               <span className="s_m_2">Service coverage,</span>{" "}
//               <span className="s_m_3">
//                 zip codes will make search result more relevant, also means
//                 highly qualified customers for you
//               </span>
//             </div>
//             <div className="clearfix" />
//             <div className="clearfix new_sep_dashboard_body">
//               {Object.keys(selectedActiveService).length !== 0 &&
//                 selectedActiveService.zip_code !== null &&
//                 selectedActiveService.zip_code.length > 0 && (
//                   <p className="zip_codes_selected">
//                     {membershipInfo.membership_name === "Business" ? (
//                       <span className="s_m_3">
//                         Unlimited Coverage Available for Business Members
//                         <a onClick={handleShowMap}>(View Map)</a>
//                       </span>
//                     ) : (
//                       <span className="s_m_3">
//                         {Number(membershipInfo.max_zip_code_allowed) -
//                           Number(zipCodesRemaining)}{" "}
//                         out of {membershipInfo.max_zip_code_allowed} Zip codes
//                         selected
//                         <a onClick={handleShowMap}>(View Map)</a>
//                       </span>
//                     )}
//                   </p>
//                 )}
//               <div className="new-profile-panel-header select_assign_heading">
//                 <div className="new_p_tbl_1">
//                   <p className="services-selected-title-center">
//                     Select the service to assign coverage
//                   </p>
//                 </div>
//               </div>

//               <div className="segment_steps_body clearfix">
//                 {renderPromotedServices(servicesPromoted)}
//               </div>
//             </div>
//           </div>
//         </div>
//       </React.Fragment>
//     </>
//   );
// };

// export default withRouter(CoverageManagement);
import React from 'react'

function CoverageManagemenet() {
  return (
    <div>CoverageManagemenet</div>
  )
}

export default CoverageManagemenet