import React from 'react'

function MembeshipItems({ handleShowModalEntrance }) {
    return (
        <div className='container row membership-panel'>
            <div className='membership-item col-12 col-md-5'>
                <div className='membership-item__header'>
                    <h2 className='membership-item__title'>$0</h2>
                </div>
                <div className='membership-item__content'>
                    <div className='membership-item__content__item'><h4>Free</h4></div>
                    <ul className='framework-ul'>
                        <li style={{fontSize:'14px', fontFamily: 'Inter, serif'}}>Create, download and save up to one project</li>
                        <li style={{fontSize:'14px', fontFamily: 'Inter, serif'}}>Pre-development and pre-construction Frameworks</li>
                        <li style={{fontSize:'14px', fontFamily: 'Inter, serif'}}>Work Breakdown Structure</li>
                    </ul>
                    
                </div>
                <div className='membership-item__footer'>
                    <button className='btn btn-primary' onClick={() => handleShowModalEntrance(false)}>JOIN NOW</button>
                </div>
            </div>
            <div className='membership-item col-12 col-md-5 mt-3 mt-md-0'>
                <div className='membership-item__header'>
                    <h2 className='membership-item__title'>$24.95</h2>
                    <small className='membership-item__title'>PER MONTH</small>
                </div>
                <div className='membership-item__content'>
                    <div className='membership-item__content__item'><h4>Basic</h4></div>
                    <ul className='framework-ul'>
                        <li style={{fontSize:'14px', fontFamily: 'Inter, serif'}}>Create, download and save up to three project</li>
                        <li style={{fontSize:'14px', fontFamily: 'Inter, serif'}}>Pre-development and pre-construction Frameworks</li>
                        <li style={{fontSize:'14px', fontFamily: 'Inter, serif'}}>Work Breakdown Structure</li>
                        <li style={{fontSize:'14px', fontFamily: 'Inter, serif'}}>Interactive table of content</li>
                    </ul>
                </div>
                <div className='membership-item__footer'>
                    <button className='btn btn-primary' onClick={() => handleShowModalEntrance(true)}>JOIN NOW</button>
                </div>
            </div>
        </div>
    )
}

export default MembeshipItems