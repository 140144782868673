import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../style.css'

// Función para filtrar los nodos jerárquicos
const filterCheckedItems = (items) => {
  return items.reduce((filtered, item) => {
    // Filtrar los hijos recursivamente
    const filteredChildren = filterCheckedItems(item.children || []);
    
    // Incluir este nodo si está checked o si alguno de sus hijos está checked
    if (item.checked || filteredChildren.length > 0) {
      filtered.push({
        ...item,
        checked: true,
        children: filteredChildren,
      });
    }

    return filtered;
  }, []);
};

const CollapsibleListView = ({ items, level = 0, prefix = '', padding, checkedItem, show = 'block', format }) => {

  const filteredItems = filterCheckedItems(items);

  return (

    <ul className="container-framework-list" style={{ paddingLeft: padding + "px", width: 'max-content', display: (show) }}>
      {filteredItems.map((item, index) => {
        const currentNumber = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        return <CollapsibleItem key={item.id} item={item} level={level} currentNumber={currentNumber} checkedItem={checkedItem} format={format} />;
      })}
    </ul>

  );
};

const CollapsibleItem = ({ item, level, currentNumber, checkedItem, format }) => {
  const [isOpen, setIsOpen] = useState(item.checked?true:false); // true if all open

  const toggle = () => setIsOpen(!isOpen);

  const handleCheckItem = (e, item) => {
    e.stopPropagation();

    if (e.target.checked) {
      setIsOpen(true);
    }
    checkedItem(item);
  }


  return (
    <>
     
        <li style={{ marginLeft: `${0}px` }}>
          <Button variant="link" onClick={toggle} style={{ textDecoration: 'none' }}>
            {item.children.length > 0 &&
              < small className='icon-open ' style={{ color: '#8d8d8d', fontFamily: "Inter, serif" }}>
                {isOpen > 0 ?
                  <i class="bi bi-caret-down-fill"></i> :
                  <i class="bi bi-caret-right-fill"></i>
                }
                &nbsp;
              </small>
            }
            <span style={{ color: '#3f3f3f', fontFamily: "Inter, serif", fontSize: "14px" }}>{format == 'wbs' ? currentNumber : item.id}</span> <span style={{ color: 'gray', fontFamily: "Inter, serif", fontSize: "14px" }}>{item.name}</span>
          </Button>
          {
            item.children.length > 0 && (
              <CollapsibleListView items={item.children} level={level + 1} prefix={currentNumber} padding={20} checkedItem={checkedItem} show={isOpen ? 'block' : 'none'} format={format} />
            )
          }
        </li >
      
    </>

  );
};

export default CollapsibleListView;

