import React, { useState, useEffect } from 'react'
import './treeTrade.css'
function TreeSupplementary({ data, handleSupplementary, pathArray, saveGroup }) {

    const [dataSource, setData] = useState(data);
    const [pathList, setPathList] = useState(pathArray);


    useEffect(() => {
        setData(data)
    }, [data])

    useEffect(() => {
        saveGroup(null, pathList);
        //console.log("lista ",pathList);        
    }, [pathList])

    const compareArrays = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((valor, indice) => valor === arr2[indice]);
    };

    const existArray = (newArray) => {
        return pathList.some(arr => compareArrays(arr, newArray));
    };

    const deleteArray = (nuevoArray) => {
        setPathList(pathList.filter(arr => !compareArrays(arr, nuevoArray)));
    };

    const handleCheckboxChange = (id, path, item) => {

        setData(prevData => {

            const updatedData = toggleChecked(prevData, id, path);
            return [...updatedData]; // Devolvemos un nuevo array
        });

        let positionListChecked = path.split("-").map(Number);
        if (existArray(positionListChecked)) {
            deleteArray(positionListChecked);
        } else {
            setPathList([...pathList, positionListChecked]);
        }

        //saveGroup(dataSource, null);
    };

    const toggleChecked = (services, id, path) => {


        return services.map(service => {
            if (service.id === id && service.path === path && service.children && service.children.length > 0) {
                service.checked = !service.checked;
            }

            if (service.id === id && service.path === path && service.children && service.children.length == 0) {
                service.checked = !service.checked;
            } else {
                service.children = toggleChecked(service.children, id, path);
            }

            return service;
        });
    };





    // const handleCheckboxChange = (item, e) => {
    //     // Llamar a la función de cambio de estado proporcionada
    //     //console.log(id);        
    //     // onCheckedChange(id, e.target.checked);        
    //     handleSupplementary(item.path,e.target.checked )
    // };



    const handleCheckboxChangeVisible = (id, e) => {
        // Llamar a la función de cambio de estado proporcionada
        //console.log(id);        
        // onVisibleChange(id, e.target.checked);
    };


    const renderItems = (data) => {
        return (
            <ul>
                {data.map((item, index) => (

                    <li key={item.id}>
                        {/* Renderiza el nombre del item */}

                        {item.children && item.children.length > 0 ?
                            <>
                                <input className='control-input' type="checkbox" checked={item.checked} id={item.id} onChange={(e) => handleCheckboxChange(item.id, item.path, item)} />
                                {item.visible ?
                                    <input className='control-node' type="checkbox" id={'ch-' + item.id} checked={item.visible} /> :
                                    <input className='control-node' type="checkbox" id={'ch-' + item.id} />
 
                                }
                                <label htmlFor={'ch-' + item.id} className={"tree_label l" + item.id}>&nbsp;&nbsp;{item.name}&nbsp;</label>
                            </>
                            :
                            <>
                                <input className='control-input' type="checkbox" checked={item.checked} id={item.id} onChange={(e) => handleCheckboxChange(item.id, item.path, item)} />
                                <span className={"tree_label_child"}>&nbsp;&nbsp;{item.name}</span>
                            </>
                        }

                        {/* Si el item tiene children y no está vacío, renderiza una nueva lista */}
                        {item.children && item.children.length > 0 && renderItems(item.children)}

                    </li>
                ))}
            </ul>
        );

        //return renderItems(data);
    }


    return (
        <div className="cont-suplementary" style={{ width: "max-content", marginBottom: '20px' }}>
            <ul className="tree">
                <li>
                    <input className='control-node' type="checkbox" checked={true} id={dataSource[0].path} />
                    &nbsp;&nbsp;&nbsp;
                    {/* <input className='control-input' type="checkbox" id={dataSource[0].path + '-' + 0} /> */}
                    <label className="tree_label" htmlFor={dataSource[0].path}>{dataSource[0].name}</label>
                    {dataSource[0].children && dataSource[0].children.length > 0 && renderItems(dataSource[0].children)}
                </li>
            </ul>
        </div>
    )
}

export default TreeSupplementary