import React, { useState, useRef, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import SecureLS from "secure-ls";
import axios from "axios";
import { BASE_URL, CLIENT_PROPAGILE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header";
import Footer from "../Footer";
import Loader from "../Loader/loader";
import NewHeader from "../Header/NewHeader";
import { Box } from "@mui/material";


function SponsorSignup() {

    const initialFormData = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        captcha: '',
    }
    const [userData, setUserData] = useState(initialFormData);
    const [showError, setShowError] = useState(false);
    const [showLoader, setShowLoader] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isFormValid, setIsFormValid] = useState("");
    const [successAccess, setSuccessAccess] = useState(false);
    const recaptchaRef = useRef(null);




    const startLoader = () => {
        setShowLoader(true);
    };

    const stopLoader = () => {
        setShowLoader(false);
    };

    const clearError = () => {
        setShowError(false);
        setErrorMessage("");
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^\d{5}$/;
        //return passwordRegex.test(password);
        return password.length > 6 ? true : false;
    };

    const validateForm = () => {
        const { password, email, captcha, firstName, lastName } = userData;
        if (!validateEmail(email)) {
            setShowError(true);
            setErrorMessage("Please enter a valid email address.");
            return false;
        }

        if (!validatePassword(password)) {
            setShowError(true);
            setErrorMessage("Password length must be atleast 7 characters.");
            return false;
        }

        if (captcha == '') {
            setShowError(true);
            setErrorMessage("Please confirm you are not a robot.");
            return false;
        }

        if (firstName == '' || lastName == '') {
            setShowError(true);
            setErrorMessage("All fields required.");
            return false;
        }
        setShowError(false);
        setErrorMessage("");
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            startLoader();
            let data = Object.assign({}, userData, { user_type: 40, first_name: userData.firstName, last_name: userData.lastName });

            axios.post(BASE_URL + '/entrance/signup-workflow', data).then(res => {
                stopLoader();
                setSuccessAccess(true);
            }).catch(err => {
                setShowError(true);
                setErrorMessage("Failed to create account. Try again");
            })

        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((userData) => ({
            ...userData,
            [name]: value
        }));

    };

    const onReCAPTCHAChange = (token) => {

        setUserData(userData => ({
            ...userData,
            captcha: token
        }));
    };

    return (
        <div>
            <Loader text="Loading..." open={showLoader} />


            <Box style={{ marginBottom: "10px" }}>
                <NewHeader />
            </Box>

            <section className="form-wrapper" style={{ minHeight: "auto" }}>
                <div className={"form-inner"}>
                    <div className={"form-main"}>
                        {successAccess ?
                            <div>
                                <div className="header-entrance">
                                    <h2>Success!</h2>
                                    <div>
                                        <img src="../images/email.png" width={300}></img>
                                    </div>
                                    <p style={{ fontSize: "25px", fontFamily: "Monserrat" }}>
                                        We sent you an email to confirm your account.
                                    </p>
                                </div>
                                <button type="button" className="btn btn-primary btn-submit-feedback mb-3 mt-3" onClick={() => { window.location.href = CLIENT_PROPAGILE_URL + '/vendor/lead/login'; }}>Sign In</button>
                            </div>
                            :
                            <>
                                <div className="header-entrance" style={{ margin: "20px 0px 20px 0px" }}>
                                    <h2>{'Sign Up'}</h2>
                                    <hr></hr>
                                    {
                                        <span>You have an account? <b onClick={() => true}><Link to="/vendor/lead/login" style={{ color: '#fcc75c', fontWeight: 600, cursor: 'pointer' }}>Sign in here</Link></b></span>
                                    }
                                </div>
                                <div className={"form-fields"}>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group position-relative input-with-icon">
                                            {/* <label>Username or Email</label> */}
                                            <i className="bi bi-person-lines-fill icon"></i>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={userData.firstName}
                                                name="firstName"
                                                required
                                                placeholder="Firstname"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group position-relative input-with-icon">
                                            {/* <label>Username or Email</label> */}
                                            <i className="bi bi-person-lines-fill icon"></i>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={userData.lastName}
                                                name="lastName"
                                                required
                                                placeholder="Lastname"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group position-relative input-with-icon">
                                            {/* <label>Username or Email</label> */}
                                            <i className="bi bi-envelope-fill icon"></i>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={userData.email}
                                                name="email"
                                                required
                                                placeholder="Email"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group position-relative input-with-icon">
                                            {/* <label>Password</label> */}
                                            <i className="bi bi-key-fill icon"></i>
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={userData.password}
                                                name="password"
                                                placeholder="Password"
                                                required
                                                onChange={handleChange}
                                            />

                                        </div>

                                        <div className="text-center">
                                            {showError && (
                                                <span className="input-warning" style={{ color: 'red' }}>{errorMessage}</span>
                                            )}

                                        </div>

                                        <div className="captcha">
                                            <ReCAPTCHA
                                                sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                                                onChange={onReCAPTCHAChange}
                                                ref={recaptchaRef}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary btn-submit-feedback mb-3 mt-3">
                                            SUBMIT
                                        </button>



                                    </form>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>
            <Box style={{ margin: "auto" }}>
                <Footer />
            </Box>
        </div>
    )
}

export default SponsorSignup