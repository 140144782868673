import React from "react";

const PromotedServicesPanel = ({ promotedService }) => {
  return (
    <div className="form-group">
      <div className="new-profile-panel-header">
        <div className="new_p_tbl_1">
          <p className="services-selected-title-center">
            Services promotion panel
          </p>
        </div>
      </div>
      <div className="segment_steps_body clearfix">
        {promotedService !== undefined &&
          promotedService.map((x) => (
            <div
              key={`active-services-selected-${x.serviceId}-${x.level}`}
              className="popup-item-boxes"
            >
              <div className="tbl">
                <div className="tbl-cell md_ch_2">
                  <div className="tbl">
                    <div className="tbl-cell">{x.name}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="text-center">
        <p>service being promoted in your profile</p>
      </div>
    </div>
  );
};

export default PromotedServicesPanel;
