import React from 'react'
const DATA_TRADES = require('../../../Constants/dataSource.json');
const projects = [
    {  zip_code: "10001", gross_area:"500", work_type: "New building", main_building_use: "Office" },
    {  zip_code: "10002", gross_area:"700", work_type: "Building remodelation", main_building_use: "Retail" },
    {  zip_code: "10003", gross_area:"900", work_type: "Building enlargement", main_building_use: "Schools" }
]
function WorkflowDashboard() {

    return (
        <div className='services_main_body'>
            <div className='container'>
                <div className="title-module" style={{ marginBottom: "15px" }}>
                    {`Workflow`}
                </div>
                <div className='d-flex flex-row justify-content-between title-panel'>
                    <h3 className='my-3'></h3>
                    <div className=''>
                        {/* <button className='btn btn-danger my-3' onClick={deleteTeams}>Remove Team</button> */}
                        <span onClick={() => { }}><i className="bi bi-plus-circle" > New workflow</i></span>
                        {/* <span onClick={() => { downloadReport("ABC") }}><i className="bi bi-plus-circle" >Download Project</i></span>
                        <span onClick={() => { requestReport("5d4316a3-9d26-4476-ab94-2cc72e99d853") }}><i className="bi bi-plus-circle" >Request Report</i></span> */}
                        {/* <button className='btn btn-primary my-3' onClick={() => { setCurrentStep(1); setShowPopup(true); }}>New Team</button> */}
                    </div>
                </div>
                <div className={`row-card-list`}>
                    {projects.length > 0 &&
                        <>
                            {projects.map((item, index) => {
                                return (
                                    <div className={`card-license-info team-card `} onClick={() => true}>

                                        <div className="team-item">
                                            <span>Zip code</span>
                                            <span>{item.zip_code}</span>
                                        </div>
                                        <div className="team-item ">
                                            <span>Gross area</span>
                                            <span>{item.gross_area}</span>
                                        </div>
                                        <div className="team-item ">
                                            <span>Work type</span>
                                            <span>{item.work_type}</span>
                                        </div>
                                        <div className="team-item ">
                                            <span>Main building use</span>
                                            <span>{item.main_building_use}</span>
                                        </div>
                                        <div className="team-item ">
                                            <span>Description</span>
                                            <span className='description'>Description</span>
                                        </div>

                                    </div>
                                )
                            })}
                        </>
                    }
                </div>


                WorkflowDashboard

            </div>
        </div>
    )
}

export default WorkflowDashboard