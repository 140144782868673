import React from 'react';

const InputText = ({ label, name, value, onChange, placeholder, type="text" }) => {
  return (
    <div className="mb-3">
      {label && <label htmlFor={name} className="form-label">{label}</label>}
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="form-control rounded-0"
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputText;
