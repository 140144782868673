import React, { useState } from 'react';
import FormField from '../Molecules/FormField';
import ButtonGroup from '../Molecules/ButtonGroup';

const Form = ({ mode = 'create', initialData = {}, onSubmit, onCancel }) => {
    const [formData, setFormData] = useState({
        id: initialData.id || '',
        name: initialData.name || '',
        zip_code: initialData.zip_code || '',
        gross_area: initialData.gross_area || '',
        work_type: initialData.work_type || '',
        main_building_use: initialData.main_building_use || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);       
    };

    const handleCancel = () => {
        setFormData({id:'', name: '', zip_code: '', gross_area: '', work_type: '', main_building_use: '' });
        onCancel();
    };

    return (
        <form onSubmit={handleSubmit} className='px-4'>
            <FormField
                type="text"
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter Project Name"
            />
            <FormField
                type="text"
                label="ZipCode"
                name="zip_code"
                value={formData.zip_code}
                onChange={handleChange}
                placeholder="Enter ZipCode"
            />
            <FormField
                type="text"
                label="Gross Area"
                name="gross_area"
                value={formData.gross_area}
                onChange={handleChange}
                placeholder="Enter Gross Area"
            />
            <FormField
                type="select"
                label="Work Type"
                name="work_type"
                value={formData.work_type}
                onChange={handleChange}
                customValue='Select work type'
                options={[
                    { label: 'New Construction', value: 'New Construction' },
                    { label: 'Remodeling', value: 'Remodeling' },
                    { label: 'Renovation/Repair', value: 'Renovation/Repair' },
                    { label: 'Enlargement', value: 'Enlargement' },
                ]}
            />
            <FormField
                type="select"
                label="Main Building Use"
                name="main_building_use"
                value={formData.main_building_use}
                onChange={handleChange}
                customValue='Add infrastructure'
                options={[
                    { label: 'Single / Two family home', value: 'Single / Two family home' },
                    { label: 'Multifamily property (3+ units)', value: 'Multifamily property (3+ units)' },
                    { label: 'Commercial: (Offices, Flex space, Medical offices)', value: 'Commercial: (Offices, Flex space, Medical offices)' },
                    { label: 'Retail', value: 'Retail' },
                    { label: 'Hospitality: (Hotel, Restaurants, Event venues)', value: 'Hospitality: (Hotel, Restaurants, Event venues)' },
                    { label: 'Warehouses, logistics, and storage', value: 'Warehouses, logistics, and storage' },
                    { label: 'Data Centers', value: 'Data Centers' },
                    { label: 'Manufacturing', value: 'Manufacturing' },
                    { label: 'Schools', value: 'Schools' },
                    { label: 'Universities', value: 'Universities' },
                    { label: 'Health care facilities', value: 'Health care facilities' },
                    { label: 'Infrastructure', value: 'Infrastructure' },
                ]}
            />
            <ButtonGroup onCancel={handleCancel} />
        </form>
    );
};

export default Form;
