import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import './index.css';
import axios from "axios";
import { BASE_URL, CLIENT_PROPAGILE_URL } from "../../../Constants/serverConfig";
import VendorLeadLogin from "../../Login/vendorLeadLogin";

function ModalEntrance({ projectInformation, isUserLoggedIn, isSpLoggedIn, handleServiceProviderLogin, message, showStripeWall }) {

    const initialFormData = {
        password: '',
        email: '',
        captcha: '',
    }
    const initialProjectData = {
        pathList: [],
        uuid: '',
        zip_code: '',
        work_type: '',
        main_building_use: '',
        gross_area: '',
        uuid: ''
    }

    const recaptchaRef = useRef(null);
    const [userData, setUserData] = useState(initialFormData);
    const [projectData, setProjectData] = useState(initialProjectData);
    const [isFormValid, setIsFormValid] = useState(false);
    const [show, setShow] = useState(false);
    const [toLogin, setToLogin] = useState(false);
    const [successAccess, setSuccessAccess] = useState(false);



    useEffect(() => {
        setProjectData(projectInformation);
    }, []);

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [userData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((userData) => ({
            ...userData,
            [name]: value
        }));

    };

    const handleSubmit = () => {

        let data = Object.assign({}, userData, projectData);
        if (toLogin) {

        } else {
            axios.post(BASE_URL + '/entrance/signup-workflow', data).then(res => {
                if (showStripeWall) {
                    window.location.href = 'https://buy.stripe.com/test_7sIdTocj02dU0qkeUZ?prefilled_email=' + userData.email
                } else {
                    setSuccessAccess(true)
                }
            }).catch(err => {
                setSuccessAccess(false)
            })
        }



    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^\d{5}$/;
        //return passwordRegex.test(password);
        return password.length > 6 ? true : false;
    };

    const validateForm = () => {
        const { password, email, captcha } = userData;
        return validateEmail(email) && validatePassword(password) && captcha !== '';
    };

    const handleClose = () => {
        setShow(false);

    };

    const onReCAPTCHAChange = (token) => {

        setUserData(userData => ({
            ...userData,
            captcha: token
        }));
    };

    return (
        <div className="container-entrance">

            {successAccess ?
                <div>
                    <div className="header-entrance">
                        <h2>Success!</h2>
                        <div>
                            <img src="../images/email.png" width={300}></img>
                        </div>
                        <p style={{ fontSize: "25px", fontFamily: "Monserrat" }}>
                            We sent you an email to confirm your account.
                        </p>
                    </div>
                    <button type="button" className="btn btn-primary btn-submit-feedback mb-3 mt-3" onClick={() => { window.location.href = CLIENT_PROPAGILE_URL + '/vendor/lead/login'; }}>Login</button>
                </div>
                :
                <>
                    <div className={"header-entrance " + (message !== "" && "with-message")}>
                        <h2>{toLogin ? 'Sign in' : 'Sign up'}</h2>
                        <hr></hr>

                        {message !== "" &&
                            <div className="alert-entrance">
                                <span >
                                    {message}
                                </span>
                            </div>
                        }
                        <br></br>
                        {
                            toLogin ?
                                <span>Don't have an account? <b style={{ color: '#fcc75c', fontWeight: 600, cursor: 'pointer' }} onClick={() => setToLogin(false)}>Sign up here</b></span> :
                                <span>Have an account? <b style={{ color: '#fcc75c', fontWeight: 600, cursor: 'pointer' }} onClick={() => setToLogin(true)}>Sign in here</b></span>
                        }
                    </div>
                    {toLogin ?
                        <VendorLeadLogin
                            isUserLoggedIn={isUserLoggedIn}
                            isSpLoggedIn={isSpLoggedIn}
                            handleServiceProviderLogin={handleServiceProviderLogin}
                            projectData={projectData}
                            fromApp={false}>
                        </VendorLeadLogin> :
                        <div className="input-container">
                            <div className="form-group position-relative input-with-icon">
                                <i className="bi bi-envelope-fill icon"></i>
                                <input className="form-control" type="text" placeholder="Email" name="email" value={userData.email} onChange={handleChange} />

                            </div>
                            <div className="form-group position-relative input-with-icon">
                                <i className="bi bi-key-fill icon"></i>
                                <input className="form-control" type="password" placeholder="Password" name="password" value={userData.password} onChange={handleChange} />
                            </div>

                            {!validateEmail(userData.email) && userData.email !== '' && (
                                <span className="input-warning" style={{ color: 'red' }}>Please enter a valid email address.</span>
                            )}
                            {!validatePassword(userData.password) && userData.password !== '' && (
                                <span className="input-warning" style={{ color: 'red' }}>Password length must be atleast 7 characters.</span>
                            )}
                            <ReCAPTCHA
                                sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                                onChange={onReCAPTCHAChange}
                                ref={recaptchaRef}
                            />
                            {
                                toLogin ?
                                    <button type="button" className="btn btn-primary btn-submit-feedback mb-3 mt-3" disabled={!isFormValid} onClick={handleSubmit}>SUBMIT</button> :
                                    <button type="button" className="btn btn-primary btn-submit-feedback mb-3 mt-3" disabled={!isFormValid} onClick={handleSubmit}>SUBMIT</button>

                            }
                            {
                                toLogin ?
                                    <></> :
                                    <p className="term-policy m-0">
                                        By continuing, you confirm that you’ve read and accepted our <a href={CLIENT_PROPAGILE_URL + "/terms"} target="_blank">Conditions of use</a> and < a href={CLIENT_PROPAGILE_URL + "/privacy-policy"} target="_blank">privacy policy</a>
                                    </p>
                            }
                        </div>
                    }
                </>}
        </div>
    )
}

export default ModalEntrance