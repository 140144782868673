import React, { useState, useEffect } from 'react';
import ProjectList from '../Organisms/ProjectList';
import ProjectPhase from '../Organisms/ProjectPhase';
import useProjects from '../../hooks/useProjects';
import { Login } from '@mui/icons-material';

const ProjectsLayout = ({ selectedPage, selectedPhase, setIsSelectedProject, setProjectName, token, serviceProvider, freeProject = [], handleShowSubscriptionsWall = null }) => {

  const [project, setProject] = useState();
  const { projects, loading, error, addProject, removeProject, editProject } = useProjects(token);

  const renderPageContent = (selectedPhase) => {
    switch (selectedPhase) {
      case 'Feasibility Phase':
        return <ProjectPhase
          selectedPhase={selectedPhase}
          project={project}
          token={token}
          serviceProvider={serviceProvider}
          subscription={serviceProvider?.subscription}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}

        />;
      case 'Planning Phase':
        return <ProjectPhase
          selectedPhase={selectedPhase}
          project={project}
          token={token}
          serviceProvider={serviceProvider}
          subscription={serviceProvider?.subscription}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        />;
      case 'Construction Phase':
        return <ProjectPhase
          selectedPhase={selectedPhase}
          project={project}
          token={token}
          serviceProvider={serviceProvider}
          subscription={serviceProvider?.subscription}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        />;
      case 'Handover / Closeout Phase':
        return <ProjectPhase
          selectedPhase={selectedPhase}
          project={project}
          token={token}
          serviceProvider={serviceProvider}
          subscription={serviceProvider?.subscription}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        />;
      case 'Work Breakdown Structure':
        return <ProjectPhase
          selectedPhase={selectedPhase}
          project={project}
          token={token}
          serviceProvider={serviceProvider}
          subscription={serviceProvider?.subscription}
          editProject={editProject}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        />;
      case 'Account':
        return <ProjectPhase selectedPhase={selectedPhase} project={project} token={token} serviceProvider={serviceProvider} />;
      default:
        return (
          <ProjectList
            projects={projects.length ? projects : freeProject == 0 ? [] : [freeProject]}
            loading={loading}
            addProject={addProject}
            removeProject={removeProject}
            editProject={editProject}
            setIsSelectedProject={setIsSelectedProject}
            setProjectName={setProjectName}
            selectProject={setProject}
            subscription={serviceProvider?.subscription}
            userEmail={serviceProvider?.email_address}
          />
        );
    }
  };



  return (
    <div>
      <div className="mt-2">
        {selectedPage === 'Projects' && (
          renderPageContent(selectedPhase)
        )}
      </div>
    </div>
  );
};

export default ProjectsLayout;
