// Packages
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import _ from "lodash";

// Components
import HeaderWithoutOptions from "../Header/headerWithoutOptions";
import Footer from "../Footer";
// import Footer from "../Footer/footerWithoutOptions";
import Loader from "../Loader/loader";
import Message from "../Message/Message";
import Invite from "./Invite";
import CreateProject from "../CreateProject/CreateProject";
import Manage from "./Manage";

// Constants
import { BASE_URL } from "../../Constants/serverConfig";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";
import Header from "../Header/header";

const Index = ({ isUserLoggedIn, isSpLoggedIn, handleLogout }) => {
  const token = useSelector((state) => state.token.token);
  const serviceProvider = useSelector(
    (state) => state.serviceProvider.serviceProvider
  );

  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [projects, setProjects] = useState([]);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [showInvitedUsers, setShowInvitedUsers] = useState(false);
  const populateInvitedUsers = (projectId, isShowLoader = true) => {
    isShowLoader === true && setShowLoader(true);

    axios
      .get(`${BASE_URL}/project/${projectId}/invitation/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setShowLoader(false);
        setInvitedUsers(response.data);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error(error, "Error");
      });
  };

  const populateProjectList = () => {
    setShowLoader(true);
    axios
      .get(`${BASE_URL}/project`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setShowLoader(false);
        setProjects(_.orderBy([...response.data], "id"));
      })
      .catch((error) => {
        setShowLoader(false);
        setProjects([]);
        console.error(error, "Error");
      });
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    populateProjectList();
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const clearError = () => {
    setShowError(false);
    setMessage("");
  };

  return (
    <div>
      <Loader text="Loading..." open={showLoader} />
      <Message
        clearMessage={clearError}
        showMessage={showError}
        message={message}
      />
      {/* {!!isSpLoggedIn || !!isUserLoggedIn ? (
        <HeaderWithoutOptions
          isDark={true}
          isUserLoggedIn={isUserLoggedIn}
          isSpLoggedIn={isSpLoggedIn}
          handleLogout={handleLogout}
          serviceProvider={serviceProvider}
        />
      ) : (
        <NewHeader />
      )}
 */}
      {!!isSpLoggedIn || !!isUserLoggedIn ? (
        <Header
          isDark={true}
          isUserLoggedIn={isUserLoggedIn}
          isSpLoggedIn={isSpLoggedIn}
          handleLogout={handleLogout}
          serviceProvider={serviceProvider}
          childRef={(ref) => (this.headerChildRef = ref)}
        />
      ) : (
        <NewHeader />
      )}
      <Invite
        showInvitedUsers={showInvitedUsers}
        setShowInvitedUsers={setShowInvitedUsers}
        showInvite={showInvite}
        setShowInvite={setShowInvite}
        setShowCreateProject={setShowCreateProject}
        projectId={projectId}
        invitedUsers={invitedUsers}
        setInvitedUsers={setInvitedUsers}
        populateInvitedUsers={populateInvitedUsers}
      />

      <Manage
        showInvitedUsers={showInvitedUsers}
        setShowInvitedUsers={setShowInvitedUsers}
        showInvite={showInvite}
        setShowInvite={setShowInvite}
        invitedUsers={invitedUsers}
        projectId={projectId}
      />

      <CreateProject
        showCreateProject={showCreateProject}
        setShowLoader={setShowLoader}
        token={token}
        setShowCreateProject={setShowCreateProject}
        populateProjectList={populateProjectList}
      />

      <div style={{ height: "68vh", overflow: "scroll" }}>
        <section className="modal-card-body home-modal">
          <div className="endos_panel2 clearfix">
            <div className="endorsment-form">
              <section className="modal-card-body" style={{ paddingTop: "0" }}>
                <div className="endos_panel3 clearfix">
                  <h1></h1>
                  <div className="columns">
                    <div className="column"></div>
                    <div className="column is-2">
                      <div className="form-group">
                        <button
                          style={{
                            paddingLeft: "2px",
                            paddingRight: "2px",
                          }}
                          className="add-btn"
                          onClick={() => {
                            setShowCreateProject(true);
                          }}
                        >
                          CREATE PROJECT
                        </button>
                      </div>
                    </div>
                  </div>

                  <table style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>Sr. no</th>
                        <th>Project</th>
                        <th style={{ width: "10rem" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((member, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{member.project_name}</td>
                            <td>
                              <div
                                className="tooltip"
                                style={{ borderBottom: "none" }}
                                onClick={() => {
                                  setInvitedUsers([]);
                                  populateInvitedUsers(member.id);
                                  setProjectId(member.id);
                                  setShowInvitedUsers(true);
                                }}
                              >
                                <i
                                  style={{ cursor: "pointer" }}
                                  className="fas fa-calendar-alt"
                                />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    top: "-20px",
                                  }}
                                  className="tooltiptext"
                                >
                                  Manage
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
    </div>
  );
};

export default Index;
