import React, { useState, useEffect } from 'react';
import ProjectCard from '../Atoms/ProjectCard';
import Modal from './Modal';
import Form from './Form';
import SubscriptionInformation from '../Molecules/SubscriptionInformation';
const ProjectList = ({ projects, loading, setIsSelectedProject, setProjectName, selectProject, addProject, removeProject, editProject, subscription = {plan:'no-plan'}, userEmail }) => {

      

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formMode, setFormMode] = useState('create');
    const [project, setProject] = useState(false);
    const [canCreateProject, setCanCreateProject] = useState(false);

    useEffect(() => {

        if ((subscription.plan === 'Basic' && projects.length < 3) || (subscription.plan === 'Free' && projects.length < 1)) {
            setCanCreateProject(true)
        }
        return () => {
            setCanCreateProject(false)
        }
    }, [projects])


    const handleOpenModal = (mode, project = null) => {
        setFormMode(mode);
        setProject(project)
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setProject("");
        setIsModalOpen(false);
    };

    const handleClickProject = (item) => {
        
        selectProject(item);
        setIsSelectedProject(item.id);
        setProjectName(item.name);
        setProject({
            id: item.id,
            name: item.name,
            zip_code: item.zip_code,
            gross_area: item.gross_area,
            work_type: item.work_type,
            main_building_use: item.main_building_use,
        })
    }

    const handleSave = (data) => {
        setProject(data);
        addProject(data);
        setIsModalOpen(false);
    };

    const handleUpdate = (data) => {
        let bodyData = {
            project_data: data
        }
        setProject(data);
        editProject(data.id, bodyData);
        setIsModalOpen(false);
    };

    const handleRemoveProject = (data) => {
        removeProject(data.id);
    }
    const handleEditProject = (data) => {
        handleOpenModal('edit', data);
    }

    

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <span></span>
                {canCreateProject && <i class="bi bi-plus-circle-fill" style={{ fontStyle: 'normal', cursor: 'pointer', color: 'rgb(255 255 255)', backgroundColor: '#1a67a3', padding: '5px 20px', borderRadius: '5px' }} onClick={() => { handleOpenModal('create', project) }}> New Project</i>}
            </div>
            
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title="New Project"
                onSave={handleSave}
                onCancel={handleCloseModal}
            >
                <Form
                    mode={formMode}
                    initialData={project}
                    onSubmit={formMode === 'create' ? handleSave : handleUpdate}
                    onCancel={handleCloseModal}
                />
            </Modal>
            {loading ? (
                <p>Loading projects...</p>
            ) : (
                <div className="row mt-3" >                   
                    {projects.length > 0 ? (
                        projects.map((project, index) => (
                            <div key={index} className="col-12 col-md-4" >
                                <ProjectCard
                                    {...project}
                                    selectProject={() => { handleClickProject(project) }}
                                    removeProject={() => { handleRemoveProject(project) }}
                                    editProject={() => { handleEditProject(project) }}
                                    subscription={subscription}
                                />
                            </div>
                        ))
                    ) : (
                        <p>Projects not available.</p>
                    )}
                    <div className='d-block d-md-none'>
                        {subscription.plan === 'Free' &&
                            <SubscriptionInformation userEmail={userEmail} />
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProjectList