import React from "react";
import { Dialog, Typography, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

const Loader = props => {
  const { text, classes, ...other } = props;
  return (
    <Dialog {...other}>
      <div className="div-loader">        
        <div class="new-loader"></div>        
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(Loader);
