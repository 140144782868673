import React, { useState, useEffect } from "react";
import Styles from "./VendorDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// import { BASE_URL } from "../../../Constants/serverConfig";
// import { useToasts } from "react-toast-notifications";
import { BASE_URL } from "../../../Constants/serverConfig";
import styles from "./CompanyInfo.module.css";

const ShowProfile = (props) => {
  const {
    selectedProfile,
    setSelectedProfile,
    vendorUpdated,
    token,
    // serviceProvider,
  } = props;
  const [updatedSelectedProfile, setUpdatedSelectedProfile] = useState({
    ...selectedProfile,
  });
  // const { addToast } = useToasts();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [stateUserInformationPanel, setStateUserInformationPanel] = useState(false);
  const [stateLocationInformationPanel, setStateLocationInformationPanel] = useState(false);

  useEffect(() => {
    const name = updatedSelectedProfile.first_name.split(" ");
    setFirstName(updatedSelectedProfile.first_name);
    setLastName(updatedSelectedProfile.last_name);
    setEmail(updatedSelectedProfile.email_address);
    setStreetAddress(updatedSelectedProfile.street_address);
    setCity(updatedSelectedProfile.city);
    setState(updatedSelectedProfile.state);
    setZipCode(updatedSelectedProfile.zip_code);
  }, [updatedSelectedProfile]);

  const handleFileUpload = (event) => {
    if (token !== null) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      // axios
      //   .patch(`${BASE_URL}/account/profile-image`, formData, {
      //     headers: {
      //       authorization: `Bearer ${token}`,
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((response) => {
      //     console.log("picture url = ", [...response.data.profile_image]);
      //     console.log("check = ", {
      //       ...selectedProfile,
      //       user_id: {
      //         ...selectedProfile.user_id,
      //         profile_image: [...response.data.profile_image],
      //       },
      //     });
      //     setUpdatedSelectedProfile({
      //       ...selectedProfile,
      //       user_id: {
      //         ...selectedProfile.user_id,
      //         profile_image: response.data.profile_image,
      //       },
      //     });
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }
  };

  const updateUserInfo = (e, accountId) => {
    // setDisableUpdate(true);
    e.preventDefault();
    console.log("accountId === ", accountId);
    if (email !== updatedSelectedProfile.email_address) {
      // addToast(
      //   `An email will be sent to ${email} so you can log in to Propagile`
      // );
    }
    const DATA = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zipCode,
    };
    console.log("update = ", updatedSelectedProfile);
    console.log("DATA = ", DATA);
    axios
      .patch(
        `${BASE_URL}/license-management/account`,
        DATA,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setStateLocationInformationPanel(false);
          setStateUserInformationPanel(false);
        }
      })
      .catch((err) => {
        console.error(
          "Something went wrong while de-activating a licence: ",
          err
        );
        // addToast("Could not update vendor details.", {
        //   appearance: "error",
        // });
      });
  };

  const handleChange = (event) => {
    if (event.target.name === "zipCode") {
      let zipCodeRegex = /^[0-9]{0,5}$/;
      if (zipCodeRegex.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  /*   const onChangeInfo = (event) => {
    const { name, value } = event.target;
    let tempVendor = {
      ...updatedSelectedProfile,
      user_id: { ...updatedSelectedProfile.user_id },
    };
    tempVendor.user_id[name] = value;
    setUpdatedSelectedProfile({
      ...tempVendor,
      user_id: { ...tempVendor.user_id },
    });
  }; */

  return (
    <div>
      <div className={styles.heading} style={{ marginBottom: "5px" }}>
        {/* {`Licences added`} */}
        <i className="bi bi-arrow-left" onClick={()=>setSelectedProfile(null)}>&ensp;Back to licenses list</i>
        <i className="bi bi-trash-fill">&ensp;Delete Account</i>
      </div>
      <div className={styles.profileInformationPanel} style={{ marginTop: "20px" }}>
        <div data="profile" className={styles.itemsProfileInformationPanel + " " + (stateUserInformationPanel ? styles.openEdit : styles.closeEdit)}>
          <div className={styles.informationTitle}>
            <span>User Information</span>
            <i onClick={() => { setStateUserInformationPanel(true); setStateLocationInformationPanel(false) }}>Edit</i>
          </div>
          {
            !stateUserInformationPanel ?
              <>
                <div className={styles.fields}>
                  <span>Name</span>
                  <span>{firstName + " " + lastName}</span>
                </div>
                <div className={styles.fields}>
                  <span>Email</span>
                  <span>{email}</span>
                </div>
                <div className={styles.fields}>
                  <span>Membership</span>
                  <span>{"Standard"}</span>
                </div>
              </>
              :
              <div>

                <form onSubmit={(e) => updateUserInfo(e, selectedProfile.id)}>
                  <div className="form-group mt-4">
                    <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputName" placeholder="" name="firstName" onChange={(e) => setFirstName(e.target.value)} autoFocus value={firstName} />
                    <label className={styles.labelInput} htmlFor="inputName">Name</label>
                  </div>
                  <div className="form-group mt-4">
                    <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputLastName" placeholder="" name="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                    <label className={styles.labelInput} htmlFor="inputName">Last Name</label>
                  </div>

                  {/* <div className={styles.informationTitle}>
                        <span>Email Address</span>
                      </div> */}
                  <div className="form-group">
                    <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputEmail" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                    <label className={styles.labelInput} htmlFor="inputEmail">Email</label>
                  </div>
                  {/* <p className={styles.informationParaph}>We'll send you an email to confirm this change</p> */}
                  <hr></hr>
                  <div className={styles.divActionsButtons}>
                    <button type="submit" className="btn btn-primary btn-submit">Update</button>
                    <button type="button" className="btn btn-primary btn-cancel" onClick={() => { setStateUserInformationPanel(false) }}>Cancel</button>
                  </div>
                </form>


              </div>
          }
        </div>

        <div data="location" className={styles.itemsProfileInformationPanel + " " + (stateLocationInformationPanel ? styles.openEdit : styles.closeEdit)}>
          <div className={styles.informationTitle}>
            <span>Location Information</span>
            <i onClick={() => { setStateLocationInformationPanel(true); setStateUserInformationPanel(false) }}>Edit</i>
          </div>
          {
            !stateLocationInformationPanel ?
              <>
                <div className={styles.fields}>
                  <span>Address</span>
                  <span>{streetAddress}</span>
                </div>
                <div className={styles.fields}>
                  <span>State</span>
                  <span>{state}</span>
                </div>
                <div className={styles.fields}>
                  <span>City</span>
                  <span>{city}</span>
                </div>
                <div className={styles.fields}>
                  <span>Zip Code</span>
                  <span>{zipCode}</span>
                </div>
              </>
              :
              <div >

                <form onSubmit={(e) => updateUserInfo(e, selectedProfile.id)}>
                  <div className="form-group mt-4">
                    <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputAddress" placeholder="" autoFocus name="address" onChange={(e) => setStreetAddress(e.target.value)} value={streetAddress} />
                    <label className={styles.labelInput} htmlFor="inputAddress">Address</label>
                  </div>
                  <div className="form-group mt-4">
                    <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputState" placeholder="" name="state" onChange={(e) => setState(e.target.value)} value={state} />
                    <label className={styles.labelInput} htmlFor="inputState">State</label>
                  </div>
                  <div className="form-group mt-4">
                    <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputCity" placeholder="" name="city" onChange={(e) => setCity(e.target.value)} value={city} />
                    <label className={styles.labelInput} htmlFor="inputCity">City</label>
                  </div>
                  <div className="form-group mt-4">
                    <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputZip" placeholder="" name="zipCode" onChange={(e) => setZipCode(e.target.value)} value={zipCode} />
                    <label className={styles.labelInput} htmlFor="inputZip">Zip Code</label>
                  </div>
                  {/* <div className="form-group">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputAddress" placeholder="Address" value={this.state.address} />
                      </div> */}
                  <hr></hr>
                  <div className={styles.divActionsButtons}>
                    <button type="submit" className="btn btn-primary btn-submit">Update</button>
                    <button type="button" className="btn btn-primary btn-cancel" onClick={() => setStateLocationInformationPanel(false)}>Cancel</button>
                  </div>
                </form>


              </div>
          }
        </div>

      </div>
    </div>

    //<div className="modal is-active custom-modal success-message">
    //<div className="modal-background" />
    //   <div
    //     className="modal-content"
    //     style={{ margin: 0, maxWidth: "none", width: "80%" }}
    //   >
    //     <div
    //       className="success-message-modal"
    //       style={{
    //         height: "auto",
    //         borderRadius: "10px",
    //         maxWidth: "none",
    //         backgroundColor: "white",
    //         border: "none",
    //         padding: "1% 10%",
    //       }}
    //     >
    //       <div style={{ paddingRight: "0.75em" }}>
    //         <FontAwesomeIcon
    //           icon={faXmark}
    //           style={{
    //             height: "25px",
    //             width: "25px",
    //             marginLeft: "100%",
    //             paddingLeft: "9%",
    //             paddingTop: "0",
    //             cursor: "pointer",
    //           }}
    //           onClick={() => setSelectedProfile(null)}
    //         />
    //       </div>
    //       <div className={Styles.vendorProfile}>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             width:"100%"
    //           }}
    //         >
    //           <div className={Styles.vendorProfileLabels}>
    //             Vendor First Name
    //           </div>
    //           <input
    //             className={Styles.vendorInputField}
    //             type="text"
    //             name="first_name"
    //             value={firstName}
    //             onChange={(e) => setFirstName(e.target.value)}
    //             // value={updatedSelectedProfile.user_id.first_name}
    //             // onChange={(e) => onChangeInfo(e)}
    //           />
    //           <div className={Styles.vendorProfileLabels}>Vendor Last Name</div>
    //           <input
    //             className={Styles.vendorInputField}
    //             type="text"
    //             name="last_name"
    //             value={lastName}
    //             onChange={(e) => setLastName(e.target.value)}
    //             // value={updatedSelectedProfile.user_id.last_name}
    //             // onChange={(e) => onChangeInfo(e)}
    //           />
    //           <div className={Styles.vendorProfileLabels}>Vendor Email</div>
    //           <input
    //             className={Styles.vendorInputField}
    //             type="text"
    //             name="email_address"
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //             // value={updatedSelectedProfile.user_id.email_address}
    //             // onChange={(e) => onChangeInfo(e)}
    //             /* disabled={
    //               serviceProvider.id === selectedProfile.owner_id ? false : true
    //             } */
    //           />
    //         </div>
    //         {/* <img
    //           className={Styles.vendorProfilePicture}
    //           alt={"Picture not found"}
    //           src={
    //             !!selectedProfile &&
    //             !!selectedProfile.user_id &&
    //             !!selectedProfile.user_id.profile_image.length
    //               ? selectedProfile.user_id.profile_image[0]
    //               : null
    //           }
    //         /> */}
    //         <div className="pro-pic hidden-767">
    //           <div className="dashboard-pic is-280x280">
    //             <figure
    //               style={{
    //                 backgroundImage: `url(${
    //                   !!selectedProfile &&
    //                   !!selectedProfile.user_id &&
    //                   !!selectedProfile.user_id.profile_image.length
    //                     ? selectedProfile.user_id.profile_image[0]
    //                     : ""
    //                 })`,
    //               }}
    //             ></figure>
    //             <div className="image">
    //               <div className="pic-btn">
    //                 <input
    //                   accept="image/*"
    //                   style={{ display: "none" }}
    //                   id="profile-picture-desktop"
    //                   name="profile-picture"
    //                   type="file"
    //                   onChange={handleFileUpload}
    //                 />
    //                 <label htmlFor="profile-picture-desktop">
    //                   <i className="fa fa-upload" aria-hidden="true"></i>
    //                 </label>
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //         {/* --- end-uploading- picture */}
    //       </div>

    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "column",
    //           width:"100%"
    //         }}
    //       >
    //         <div className={Styles.vendorProfileLabels}>Vendor Address</div>
    //         <input
    //           className={Styles.vendorInputField}             
    //           type="text"
    //           name="streetAddress"
    //           placeholder="Street Address"
    //           onChange={(e) => setStreetAddress(e.target.value)}
    //           // value={selectedProfile.user_id.last_name}
    //           // readOnly
    //         />
    //       </div>
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "column",
    //           width: "100%",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         <div style={{ display: "flex", flexDirection: "column" }}>
    //           <div className={Styles.vendorProfileLabels}>City</div>
    //           <input
    //             className={Styles.vendorInputField}
    //             type="text"
    //             name="city"
    //             placeholder="City"
    //             onChange={(e) => setCity(e.target.value)}
    //             // value={selectedProfile.user_id.last_name}
    //             // readOnly
    //           />
    //         </div>

    //         <div style={{ display: "flex", flexDirection: "column" }}>
    //           <div className={Styles.vendorProfileLabels}>State</div>
    //           <input
    //             className={Styles.vendorInputField}
    //             type="text"
    //             name="state"
    //             placeholder={"State"}
    //             onChange={(e) => setState(e.target.value)}
    //             // value={selectedProfile.user_id.last_name}
    //             // readOnly
    //           />
    //         </div>

    //         <div style={{ display: "flex", flexDirection: "column" }}>
    //           <div className={Styles.vendorProfileLabels}>Zip Code</div>
    //           <input
    //             className={Styles.vendorInputField}
    //             type="text"
    //             name="zipCode"
    //             placeholder="ZipCode"
    //             onChange={(e) => setZipCode(e.target.value)}
    //             // value={selectedProfile.user_id.last_name}
    //             // readOnly
    //           />
    //         </div>
    //       </div>
    //       <button
    //         style={{
    //           alignItems: "start",
    //           marginBottom: "1em",
    //           cursor: "pointer",
    //           opacity: !!disableUpdate ? "0.5" : "1",
    //         }}
    //         onClick={() =>
    //           !!disableUpdate ? {} : updateUserInfo(selectedProfile.id)
    //         }
    //         className="btn-fill"
    //       >
    //         Update
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ShowProfile;
