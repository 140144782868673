import React, { useState, useEffect } from 'react'
import './index.css'
import TreeCheck from './TreeCheck';
import axios from 'axios';
import { BASE_URL } from '../../Constants/serverConfig';
import MembeshipItems from './MembeshipItems';


function CheckList({ uuid, handleCheckedData, checkedIds, dataCheck, isChildren, showModalEntrance, handleShowModalEntrance, handleshowStripeWall }) {


    const [showIntro, setShowIntro] = useState(false);
    const [showCheckListTree, setShowCheckListTree] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] = useState(dataCheck[0]);
    const [checkedData, setCheckedData] = useState(checkedIds);


    useEffect(() => {
        restoreCheckedState([data], checkedIds)
    }, [checkedIds])

    useEffect(() => {
        setShowPopup(false);
    }, [showModalEntrance])

    useEffect(() => {

        //  console.log(checkedData);
        //  console.log(data);
        handleCheckedData(checkedData);
    }, [checkedData])

    useEffect(() => {
        downloadTemplate();
    }, [data])

    const handleManageModalEntrance = (goStripe = false) => {
        handleShowModalEntrance(true);
        if (goStripe) {
            handleshowStripeWall(true);
        }else{
            handleshowStripeWall(false);
        }

    }

    const handleCheckedChange = (id, checked) => {

        const updateCheckedStatus = (node) => {
            if (node.id === id) {
                return { ...node, checked };
            }
            if (node.children) {
                return {
                    ...node,
                    children: node.children.map(updateCheckedStatus)
                };
            }
            return node;
        };

        setData(updateCheckedStatus(data));
    };

    const handleVisibleChange = (id, visible) => {

        const updateCheckedStatus = (node) => {

            if (node.id === id) {
                return { ...node, visible };
            }
            if (node.children) {
                return {
                    ...node,
                    children: node.children.map(updateCheckedStatus)
                };
            }
            return node;
        };

        setData(updateCheckedStatus(data));
    };

    const getCheckedItems = (data) => {
        const result = [];

        function traverse(node) {
            if (node.checked) {
                result.push(node.id);
            }

            if (node.children && node.children.length > 0) {
                node.children.forEach(traverse);
            }
        }

        data.forEach(traverse);
        return result;
    }

    const restoreCheckedState = (data, checkedItems) => {

        const checkedSet = new Set(checkedItems); // Usamos un Set para búsquedas rápidas

        function traverse(node) {
            node.checked = checkedSet.has(node.id);

            if (node.children && node.children.length > 0) {
                node.children.forEach(traverse);
            }
        }

        data.forEach(traverse);
        data.forEach(node => markParents(node));
        return data;
    }

    const markParents = (node) => {
        let hasCheckedChild = false; // Variable que indica si el nodo tiene hijos marcados

        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                // Recursivamente marcar a los padres si los hijos están chequeados
                if (markParents(child)) {
                    hasCheckedChild = true;
                }
            });
        }


        // Si el nodo está marcado o tiene algún hijo marcado
        if (node.checked || hasCheckedChild) {
            node.visible = true;
            return true;  // Retorna true si este nodo o alguno de sus hijos está marcado
        }

        return false;  // Retorna false si ni el nodo ni sus hijos están marcados
    }

    const downloadTemplate = () => {
        let checkedList = getCheckedItems([data]);
        setCheckedData(checkedList);
    }

    const submitDownloadCheckList = () => {

        axios.post(`${BASE_URL}/project/generate-checklist-file`,
            {
                checked_data: JSON.stringify(checkedData),
                uuid: uuid

            })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    axios.get(BASE_URL + '/public/project/download-report/' + uuid, {
                        responseType: 'blob'// Configurar responseType como blob para manejar archivos binario
                    }).then(response => {

                        const url = window.URL.createObjectURL(new Blob([response.data]));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', uuid + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    }).catch(err => {
                        console.log(err);
                    });
                }

            })
            .catch((error) => {

            });
    }

    const restoreTemplate = () => {
        let checkedList = restoreCheckedState([data], checkedData);

        setCheckedData(checkedList);
    }

    return (
        <div className='container check-container'>

            {showPopup &&
                <div className={`modal small-popup is-active `}>
                    <div className="modal-background" />
                    <div className={"modal-card "}>
                        <header className="modal-card-head"><span onClick={() => setShowPopup(false)} style={{ cursor: 'pointer' }}>X</span></header>
                        <section className={"modal-card-body membership-modal"}>
                            <MembeshipItems handleShowModalEntrance={handleManageModalEntrance}/>
                        </section>
                    </div>
                </div>
            }
            {showIntro &&
                <div className='check-right'>

                    <h5>PropAgile Construction Frameworks</h5>
                    <h2>Owner’s Management Framework</h2>
                    <span>A Checklist for Guiding Projects Every Step of the Way</span>
                    <br>
                    </br>
                    <p>This tool breaks down all phases of construction into steps from start to finish.Select to customize all steps that apply to your project, download as a check list to manage in any preferred management tool, and keep track of the process and progress at each step of the way.</p>
                    <div className='check-list-info'>
                        <ul>
                            <li><b>Pre-Development Phase:</b> Initiation, Planning, and Conceptualization</li>
                            <li><b>Pre-Construction Phase:</b> Design and Engineering, Procurements, Construction Set up</li>
                            <li><b>Construction Phase:</b> Construction Work, Monitoring and Control, Final punchlist, Handover.</li>
                            <li><b>Post-Construction Phase:</b> Closeout Preparation, Documentation, Facilities Management Setup.</li>
                        </ul>
                    </div>
                    <p>Use this checklist to generate agendas and track meeting minutes in Procore, or to create email subjects and add corresponding stakeholders, to channelize and manage communications for each topic, ensuring everyone is informed.</p>
                    <span>Select what applies from the lists, download, and manage</span>
                    <br></br>
                    <button className='btn-start-workflow-div'>Create Your Project’s Management Framework </button>

                </div>
            }
            {showCheckListTree &&
                <div className='check-right'>

                    <h5>PropAgile Construction Frameworks</h5>
                    <h2>Owner’s Management Framework</h2>
                    <span>Select all that apply and export excel to your favorite management tool</span>
                    <br></br>
                    <div className='check-tree-container bg-light mb-2'>
                        <TreeCheck data={data} onCheckedChange={handleCheckedChange} onVisibleChange={handleVisibleChange}></TreeCheck>
                    </div>
                    <span className='text-center'>Manage and update the list as required in our cloud <br></br> Basic plan is only $24.95/Mo.</span>
                    <div className={'actions-button ' + (isChildren ? 'children' : '')}>
                        <button className="download-check-button" onClick={() => submitDownloadCheckList()}>Download Checklist</button>
                        <button className='btn-start-workflow-div' onClick={() => setShowPopup(true)}>&nbsp;&nbsp;Join Basic&nbsp;&nbsp;</button>
                        <span className="" style={{ width: '200px' }}></span>
                        <span className="d-none" onClick={() => restoreTemplate()}>Restore Checklist</span>
                    </div>
                </div>
            }


        </div >
    )
}

export default CheckList