/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header";
import Footer from "../Footer";
import Loader from "../Loader/loader";
import NewHeader from "../Header/NewHeader";
import ReCAPTCHA from "react-google-recaptcha";
import NewFooter from "../Footer/NewFooter";

class ResetPasswordRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showResetPassword: false,
      forgotEmailOrUsername: "",
      success: false,
      showLoader: false,
      captcha: "",
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onChange = (value) => {
    this.setState({
      captcha: value,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  clearSuccess = () => {
    this.setState({
      success: false,
    });
    this.props.history.push("/");
  };

  resetPassword = (event) => {
    event.preventDefault();
    const { forgotEmailOrUsername } = this.state;
    this.setState({
      showLoader: true,
    });
    if (forgotEmailOrUsername.trim() === "") {
      this.showError("Please enter email or username to reset password.");
      return false;
    } else {
      axios
        .post(`${BASE_URL}/entrance/password-recovery`, {
          recovery_input: forgotEmailOrUsername.trim(),
        })
        .then((response) => {
          this.setState({
            showLoader: false,
          });
          if (response.status === 200) {
            this.setState({
              success: true,
              forgotEmailOrUsername: "",
            });
            setTimeout(this.clearSuccess, 5000);
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
          this.showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
        });
    }
  };

  render() {
    const {
      showError,
      errorMessage,
      forgotEmailOrUsername,
      success,
      showLoader,
    } = this.state;
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {success && (
          <div className="modal is-active custom-modal success-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="success-message-modal">
                <button
                  onClick={this.clearSuccess}
                  className="modal-close"
                ></button>
                <p>
                  We have sent an email to reset password. Please following
                  instructions in the email.
                </p>
                <a onClick={this.clearSuccess} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <section className="form-wrapper">
          <div className="form-inner">
            <div className="form-main forget-password">
              <div className="form-title">
                <div className="header-entrance">
                  <h2>{'Forgot your password?'}</h2>
                  <hr></hr>

                  <p className="">
                    Enter your email address and you will receive an
                    email to reset your password.
                  </p>
                </div>
              </div>

              <div className="form-fields mt-3">
                <form onSubmit={this.resetPassword}>
                  <div className="form-group position-relative input-with-icon">
                    <i className="bi bi-envelope-fill icon"></i>
                    <input
                      type="text"
                      value={forgotEmailOrUsername}
                      name="forgotEmailOrUsername"
                      placeholder="Email"
                      required
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>


                  <div className="captcha">
                    <ReCAPTCHA
                      sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                      onChange={this.onChange}
                      ref={(e) => (this.captcha = e)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary btn-submit-feedback mb-3 mt-3">
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ResetPasswordRequest);
