

export const resolveTree = (path, data) => {
  let array = restoreCheckedState(path, data);
  return buildHierarchy(array);
}

const restoreCheckedState = (paths, workflow) => {

  let arrayData = [];
  for (const [index, path] of paths.entries()) {

    let auxDataTrade = { ...workflow[0].children };
    //  console.log(path);
    //let path = [0,1];
    for (let i = 0; i < path.length; i++) {
      const pos = path[i];


      if ((i + 1) != path.length) {
        //  auxDataTrade[pos].visible = true;
      }
      if (!auxDataTrade[pos]) {
        auxDataTrade[pos] = { children: [] };
      }
      if (i === path.length - 1) {
        auxDataTrade[pos].checked = true;
        arrayData.push({
          id: auxDataTrade[pos].id,
          name: auxDataTrade[pos].label,
          path: auxDataTrade[pos].path,
          parent_path: auxDataTrade[pos].parent_path,
          children: []
        });
      } else {
        auxDataTrade = auxDataTrade[pos].children;
      }
    }
  }
  return arrayData;
}

// function buildHierarchy(data) {
//     const tree = [];      // Array para almacenar el árbol final
//     const nodes = {};     // Objeto para mapear cada nodo por su `path`

//     // Crear un objeto de referencia para cada nodo con una lista vacía de `children`
//     data.forEach(item => {
//       nodes[item.path] = { ...item, children: [] };
//     });

//     // Construir la jerarquía
//     data.forEach(item => {
//       if (!item.parent_path || !nodes[item.parent_path]) {
//         // Si no tiene `parent_path` o el `parent_path` no existe en `nodes`, es raíz
//         tree.push(nodes[item.path]);
//       } else {
//         // Si tiene `parent_path` y el nodo padre existe, agregarlo como hijo del padre
//         nodes[item.parent_path].children.push(nodes[item.path]);
//       }
//     });

//     return tree;
//   }

function buildHierarchy(data) {
  const tree = [];
  const nodes = {};

  // Crear un objeto de referencia para cada nodo
  data.forEach(item => {
    nodes[item.path] = { ...item, children: [] };
  });

  // Construir la jerarquía
  data.forEach(item => {
    let parentPath = item.parent_path;

    // Si no tiene `parent_path` o si el `parent_path` no existe en `nodes`
    while (parentPath && !nodes[parentPath]) {
      // Remover el último segmento del `parent_path` para buscar el ancestro
      parentPath = parentPath.split("-").slice(0, -1).join("-");
    }

    if (!parentPath) {
      // Si no hay un `parent_path` válido, este es un nodo raíz
      tree.push(nodes[item.path]);
    } else {
      // Si hay un `parent_path` válido, añadir el nodo como hijo del ancestro encontrado
      nodes[parentPath].children.push(nodes[item.path]);
    }
  });

  return tree;
}


export const checkFrameworkItems = (id, data) => {
  const updateTree = (nodes) =>
    nodes.map((node) => {
      // Si encontramos el nodo, actualizamos su estado
      if (node.id === id) {
        return { ...node, checked: true };
      }

      // Si tiene hijos, aplicamos la actualización de forma recursiva
      if (node.children && node.children.length > 0) {
        return { ...node, children: updateTree(node.children) };
      }

      return node; // Retornamos el nodo sin cambios si no coincide
    });

  // Actualizamos el estado con el árbol modificado    
  return updateTree(data);
}

export const checkFrameworkItemsMultiple = (arrayIds, data) => {
  const ids = arrayIds.map((obj) => obj.id);
  const updateTree = (nodes) =>
    nodes.map((node) => {
      const updatedNode = { ...node };
      if (ids.includes(updatedNode.id)) {
        updatedNode.checked = true;
      }
      if (updatedNode.children && updatedNode.children.length > 0) {
        updatedNode.children = updateTree(updatedNode.children);
      }
      return updatedNode;
    });

  return updateTree(data);
};

export const checkFrameworkItemsMultipleParents = (idArray, tree) => {
  
  const updateTree = (nodes, selectedIds) =>
    nodes.map((node) => {
      const updatedNode = { ...node };

      // Si el ID del nodo está en la lista de seleccionados, cambia su estado de 'checked'
      if (selectedIds.some(({ id }) => id === node.id)) {
        updatedNode.checked = true;
      }

      // Si tiene hijos, llama recursivamente para actualizar el árbol
      if (Array.isArray(node.children) && node.children.length > 0) {
        updatedNode.children = updateTree(updatedNode.children, selectedIds);

        // Actualizar el estado del nodo basado en los hijos
        updatedNode.checked =
          updatedNode.checked || updatedNode.children.some((child) => child.checked);
      }

      return updatedNode;
    });

  // Función para actualizar los padres recursivamente
  const updateParents = (nodes, targetId) => {
    nodes.forEach((node) => {
      if (
        Array.isArray(node.children) &&
        node.children.some((child) => child.id === targetId)
      ) {
        node.checked = true; // Marcar al padre como checked

        // Si el nodo tiene un parent_id, actualizar recursivamente
        if (node.parent_id) {
          updateParents(tree, node.id);
        }
      }

      // Buscar recursivamente en los hijos
      if (Array.isArray(node.children) && node.children.length > 0) {
        updateParents(node.children, targetId);
      }
    });
  };

  // Actualizar el estado del árbol
  const newTree = updateTree(tree, idArray);

  // Actualizar los padres de los nodos seleccionados
  idArray.forEach(({ id }) => {
    const targetNode = findNodeById(newTree, id);
    if (targetNode && targetNode.parent_id) {
      updateParents(newTree, id);
    }
  });

  return updateTree(newTree);

};

// Función auxiliar para encontrar un nodo por su ID
const findNodeById = (nodes, id) => {
  for (let node of nodes) {
    if (node.id === id) return node;
    if (Array.isArray(node.children) && node.children.length > 0) {
      const found = findNodeById(node.children, id);
      if (found) return found;
    }
  }
  return null;
};

const auxCheckedParents = (arrayIds, data) =>{

}


