import React, { useState } from 'react';
import Logo from '../Atoms/Logo';
import NavMenu from '../Molecules/NavMenu';
import NavIcons from '../Molecules/NavIcons';
import DropdownMenu from '../Atoms/DropDownMenu';
import NavBar from '../Molecules/NavBar';

const Header = ({ logoSrc, logoAlt, menuItems, selectedPage, rightIcons, logout }) => {

  const [showMenu, setShowMenu] = useState(false);
  const [showMenuNotification, setShowMenuNotification] = useState(false);
  const [showMenuAll, setShowMenuAll] = useState(false);

  const toggleMenu = () => {
    handleMouseLeave();
    setShowMenu((prev) => !prev);
  }
  const toggleMenuAll = () => {
    handleMouseLeave();
    setShowMenuAll((prev) => !prev);
  }
  const toggleMenuNotification = () => {
    handleMouseLeave();
    setShowMenuNotification((prev) => !prev);
  }

  const handleMouseLeave = () => {
    setShowMenu(false);
    setShowMenuAll(false);
    setShowMenuNotification(false);
  };


  const menuItemsDropDown = [
    { label: 'Logout', onClick: () => handleLogout() },
  ];

  const menuItemsDropDownNotification = [
    { label: "You don't have notifications", onClick: () => handleLogout() }
  ];

  const handleLogout = () => {
    logout();
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light px-4" style={{ height: '56px', backgroundColor: '#1a67a3' }}>
      <div className="container-fluid d-flex align-items-center">
        {/* Logo a la izquierda */}
        <a className="navbar-brand" href="/">
         
            <Logo src={logoSrc} alt={logoAlt} />
            
          
        </a>

        {/* Menú en el centro */}
        <div className="d-none d-lg-flex flex-grow-1 justify-content-center">
          <NavMenu items={menuItems} selectedPage={selectedPage} />
        </div>
        <div className='d-block d-md-none'>
          <h4
            style={{
              color: 'white',
              borderBottom: '2px solid #ffc107'
            }}
          >{selectedPage}</h4>
        </div>

        {/* Íconos a la derecha */}
        <div className='d-flex flex-row align-items-center' style={{ position: 'relative' }} onMouseLeave={() => { handleMouseLeave() }}>
          <div >
            <NavIcons icon={'bi bi-bell-fill'} onClick={toggleMenuNotification} />
            <DropdownMenu show={showMenuNotification} items={menuItemsDropDownNotification} />
          </div>
          <div className='d-none d-md-block '>
            <NavIcons icon={'bi bi-person-circle'} onClick={toggleMenu} />
            <DropdownMenu show={showMenu} items={menuItemsDropDown} />
          </div>

          <div >
            <i className="d-block d-md-none bi bi-list" style={{ fontSize: '25px', color: 'white', border: '0px', padding: "0px 5px" }} onClick={toggleMenuAll}></i>
            <DropdownMenu show={showMenuAll} items={[...menuItems, menuItemsDropDown[0]]} />
          </div>
        </div>
      </div>

    </nav>
  );
}
export default Header;
