import React from 'react';
import NavIcons from '../Atoms/NavIcon';

const NavIcon = ({ icons }) => (
  <div className="d-flex align-items-center">
    {icons.map((icon, index) => (
      <NavIcon key={index} icon={icon.src} alt={icon.alt} onClick={icon.onClick} />
    ))}
  </div>
);

export default NavIcons;
