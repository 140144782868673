import { combineReducers } from "redux";
import user from "./user.jsx";
import token from "./token.jsx";
import serviceProvider from "./serviceProvider.jsx";
import Vendors from "./Vendors.jsx";
import TreeCheked from "./TreeChecked.jsx"

export default combineReducers({
  user,
  serviceProvider,
  token,
  Vendors,
  TreeCheked
});
