import React from 'react'

const SubscriptionInformation = ({userEmail}) => {
    return (
        <div className='text-center' style={{ marginTop: '8vh', padding: '15px', backgroundColor: '#e7e7e7', borderRadius: '5px' }}>
            <p style={{ fontSize: '15px', fontWeight: '700', fontFamily:'Inter, serif'}}>
                Unlock  Cloud Editing <br></br>& Beta Tools
            </p>
            <p style={{ fontSize: '15px', fontWeight: '500', fontFamily:'Inter, serif' }}>
                Edit your framework's table of content in the cloud, and access exclusive beta features.
            </p>
            <p style={{ fontSize: '15px', fontWeight: '500' }}>
                Basic plan is only $24.95/Mo
            </p>
            <button
                className='w-100'
                style={{ backgroundColor: '#fcc75c', padding: '5px', borderRadius: '5px', border: '0px', color: "#d2691e", fontWeight: '800', fontSize: '18px' }}
                onClick={() => { window.open('https://buy.stripe.com/test_7sIdTocj02dU0qkeUZ?prefilled_email=' + userEmail, '_blank') }}
            >Join Basic</button>
        </div>
    )
}

export default SubscriptionInformation