import React from 'react';

const Breadcrumbs = ({ paths, onPathChange }) => {

  const handleClick = (index) => {
    const newPaths = paths.slice(0, index + 1); // Mantiene solo los elementos hasta el clic
    if (onPathChange) {
      onPathChange(newPaths);
    }
  };

  return (
    <nav aria-label="breadcrumb" className='breadcrumb-container' style={{ 
      backgroundColor: '#f8f9fa', 
      padding: '0px',  
      overflowX:'auto', 
      marginBottom:'20px'      
       }}>
      <ol className="breadcrumb m-0" style={{ backgroundColor: 'rgb(249, 249, 249)', flexWrap:'nowrap' }}>
        {paths.map((path, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${(index === paths.length - 1) || (index == 2 && paths.length == 4) ? 'active' : ''}`}
            aria-current={index === paths.length - 1 ? 'page' : undefined}
          >
            {index === paths.length - 1 ?
              path :
              (index == 2 && paths.length == 4) ? path :
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick(index);
                  }}
                >{path}</a>}
          </li>
        ))}
      </ol>
    </nav>
  )
};

export default Breadcrumbs;
