import React from 'react';
import InputText from '../Atoms/InputText';
import InputSelect from '../Atoms/InputSelect';

const FormField = ({ type, label, name, value, onChange, customValue, options, placeholder }) => {
  return (
    <div className="mb-3">
      {type === 'text' && (
        <InputText label={label} name={name} value={value} onChange={onChange} placeholder={placeholder} />
      )}
      {type === 'select' && (
        <InputSelect label={label} name={name} value={value} onChange={onChange} options={options} customValue={customValue} />
      )}
    </div>
  );
};

export default FormField;
