import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_URL, CLIENT_TEAM_URL } from "../../Constants/serverConfig";
import { Modal } from "react-bootstrap";
import axios from "axios";
import './index.css'

function Feedback({showModal, setShowModal,showModalFeedback, setShowModalFeedback}) {

   
    const initialFormData = {
        name: '',
        email: '',
        zipcode: '',
        comment: '',
        feedbackType: '',
        captcha: ''
    }
    const [show, setShow] = useState(false);
    const recaptchaRef = useRef(null);
    const [dataFeedback, setdataFeedback] = useState(initialFormData);
    const [isFormValid, setIsFormValid] = useState(false);
    useEffect(() => {
        setShow(showModalFeedback);
    }, [showModalFeedback])

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [dataFeedback]);

    useEffect(() => {
        console.log('show modal', showModal);
        if(showModal){
            setShow(true)
        }else{
            setShow(false)
            setShowModal(false)
        }
    }, [showModal]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setdataFeedback((dataFeedback) => ({
            ...dataFeedback,
            [name]: value
        }));

    };

    const handleSubmit = () => {

        axios.post(BASE_URL + '/feedback', dataFeedback).then(res => {
            console.log(res);
            setdataFeedback(initialFormData);
            handleClose();
        }).catch(err => {
            console.log(err);
        })

    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateZipcode = (zipcode) => {
        const zipcodeRegex = /^\d{5}$/;
        return zipcodeRegex.test(zipcode);
    };

    const validateForm = () => {
        const { name, email, zipcode, feedbackType, comment, captcha } = dataFeedback;
        return name !== '' && validateEmail(email) && validateZipcode(zipcode) && feedbackType !== '' && comment !== '' && captcha !== '';
    };

    const handleClose = () => {
        setShow(false);
        setShowModal(false);
        setShowModalFeedback && setShowModalFeedback(false);
    };

    const onReCAPTCHAChange = (token) => {

        setdataFeedback(dataFeedback => ({
            ...dataFeedback,
            captcha: token
        }));
    };
    
    return (
        <Modal show={show} onHide={handleClose} className="modal-feedback" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton className="feedback-header">
            </Modal.Header>
            <Modal.Body >
                <div className="container-feedback px-2">
                    <div className="feedback-tittle text-center">
                        <h3>Share your thoughts with us</h3>
                        <span>Please provide feedback by filling the form below</span>
                    </div>
                    <div className="checkbox-feedback">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="feedbackType" id="feedback" value="feedback" checked={dataFeedback.feedbackType === 'feedback'} onChange={handleChange} />
                            <label className="form-check-label feedback-label" for="feedback">
                                Feedback
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="feedbackType" id="issue" value="issue" checked={dataFeedback.feedbackType === 'issue'} onChange={handleChange} />
                            <label className="form-check-label feedback-label" for="issue">
                                Technical Issue
                            </label>
                        </div>
                    </div>

                    <div className="input-container">
                        <div className="form-group position-relative input-with-icon">
                            <i className="bi bi-person-fill icon"></i>
                            <input className="form-control" type="text" placeholder="Name" name="name" value={dataFeedback.name} onChange={handleChange} />
                        </div>
                        <div className="form-group position-relative input-with-icon">
                            <i className="bi bi-envelope-fill icon"></i>
                            <input className="form-control" type="text" placeholder="Email" name="email" value={dataFeedback.email} onChange={handleChange} />

                        </div>
                        <div className="form-group position-relative input-with-icon">
                            <i className="bi bi-geo-alt-fill icon"></i>
                            <input className="form-control" type="text" placeholder="Zip Code" name="zipcode" value={dataFeedback.zipcode} onChange={handleChange} />

                        </div>
                        <div className="form-group position-relative input-with-icon">
                            <i className="bi bi-chat-left-text-fill icon"></i>
                            <textarea className="form-control area-comment" id="exampleFormControlTextarea1" placeholder="Comment" rows="3" name="comment" value={dataFeedback.comment} onChange={handleChange}></textarea>
                        </div>
                        {!validateEmail(dataFeedback.email) && dataFeedback.email !== '' && (
                            <span style={{ color: 'red' }}>Please enter a valid email address.</span>
                        )}
                        {!validateZipcode(dataFeedback.zipcode) && dataFeedback.zipcode !== '' && (
                            <span style={{ color: 'red' }}>Please enter a valid zip code.</span>
                        )}
                        <ReCAPTCHA
                            sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                            onChange={onReCAPTCHAChange}
                            ref={recaptchaRef}
                        />
                        <button type="button" className="btn btn-primary btn-submit-feedback mb-3 mt-3" disabled={!isFormValid} onClick={handleSubmit}>SUBMIT</button>
                    </div>
                    <div></div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Feedback