import { useState } from 'react';
import axios from 'axios';
import Loader from '../../Loader/loader';
import styles from "./CompanyInfo.module.css";
import { BASE_URL } from '../../../Constants/serverConfig';

function Subscription({ token, serviceProvider }) {
    const [showLoader, setShowLoader] = useState(false);

    const cancelSubscription = () => {
        setShowLoader(true);

        axios.post(`${BASE_URL}/payment/subscription/cancel`, {},
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            }
        ).then(response => {
            console.log(response.data);
            setShowLoader(false);
        }).catch(err => {
            console.log(err);
        })

    }

    return (
        <div>
            <Loader text="Loading..." open={showLoader} />
            <div className={styles.profileInformationPanel}>
                <div className={'w-100'} style={{}}>
                    <div className={styles.informationTitle}>
                        <span></span>
                    </div>
                    <div className={styles.informationPrice}>
                        <h2>{serviceProvider.subscription.plan}</h2>
                        <p>{serviceProvider.subscription.price}<small>/Month</small></p>
                    </div>
                    <div className={styles.fields}>
                        <span>Status</span>
                        <span className={(serviceProvider.subscription.status == 'active' ? styles.active : styles.inactive)}>{serviceProvider.subscription.status}</span>
                    </div>
                    <div className={styles.fields}>
                        <span>Next payment</span>
                        <span>{serviceProvider.subscription.cancel_at ? "N/A" : serviceProvider.subscription.end_date}</span>
                    </div>
                    {
                        serviceProvider.subscription.end_date &&
                        <div className={styles.fields}>
                            <span>Cancel date</span>
                            <span>{serviceProvider.subscription.cancel_at}</span>
                        </div>
                    }
                    {!serviceProvider.subscription.cancel_at &&

                        <div className={styles.fields + " " + styles.unsubscribe}>
                            <span onClick={cancelSubscription}>Unsubscribe</span>
                            <span></span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Subscription