import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, withRouter, Switch, Route } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
///import { compose } from "recompose";
import SecureLS from "secure-ls";
import PrivateRouter from "../Router/privateRouter.jsx";
import { saveUser, removeUser } from "../Actions/user";
import { saveToken, removeToken } from "../Actions/token";
import {
  saveServiceProvider,
  removeServiceProvider,
} from "../Actions/serviceProvider";

//Route Imports
import LeadLanding from "./Home/leadLanding.jsx";
import Home from "./Home/home.jsx";
import HomeTest from "./Home/HomeTest.jsx";
import BusinessDashboard from "./Dashboard/businessDashboard.jsx";
import Card from "./BusinessCards/card.jsx";
import VendorLogin from "./Login/vendorLogin.jsx";
import VendorLeadLogin from "./Login/vendorLeadLogin.jsx";
import VendorSignup from "./SignUp/vendorSignUp.jsx";
import CustomerSignup from "./SignUp/customerSignup.jsx";
import Request from "./Customers/request.jsx";
import Customers from "./Customers/newIndex.jsx";
import Campaign from "./ServiceProviders/campaign.jsx";
import Review from "./Review/review.jsx";
import Endorsement from "./Endorsement/endorsement.jsx";
import IndividualProfile from "./ServiceProviders/individualProfile.jsx";
import CompanyProfile from "./ServiceProviders/companyProfile.jsx";
import Thanks from "./Thanks/thanks.jsx";
import CustomerThanks from "./Thanks/customerThanks.jsx";
import {
  USERLOGIN,
  USER,
  SERVICEPROVIDERLOGIN,
  SERVICEPROVIDER,
  EXPIRY,
  TOKEN,
} from "../Constants/localStorageKeys.jsx";
import Subscriptions from "./Subscriptions/subscriptions.jsx";
import SubscriptionUpgrade from "./Subscriptions/subscriptionUpgrade.jsx";
import ResetPasswordRequest from "./ResetPassword/resetPasswordRequest.jsx";
import ResetPassword from "./ResetPassword/resetPassword.jsx";
import ContactUs from "./ContactUs/contactUs.jsx";
import Terms from "./Terms/terms.jsx";
import PrivacyPolicy from "./PrivacyPolicy/privacyPolicy.jsx";
import News from "./News/news.jsx";
import Blog from "./Blog/blog.jsx";
import About from "./About/about.jsx";
import Careers from "./Careers/careers.jsx";
//import LandingPage from "./Home/landingPage.jsx";
import CardDummy from "./BusinessCards/card-dummy.jsx";
import DummyDashboard from "./Dashboard/dummyDashboard.jsx";
import TeamDashboard from "./TeamDashboard/index";
import VendorInvite from "./VendorInvite/index";
import VendorInviteSignUp from "./SignUp/vendorInviteSignUp.jsx";
import Register from "./Register/Register.jsx";
import DummyLandingPage from "./Home/DummyLandingPage.jsx";
import InvitationByForemen from "./InvitationByForemen/index.jsx";
import InvitationByFriends from "./InvitationByFriends/index.jsx";
import './style.css'
import WorkFlow from "./WorkFlow/Index.jsx";
import SponsorSignup from "./SignUp/sponsorSignup.jsx";
import InteractivePanel from "./InteractivePanel/index.jsx";
import NewSignup from "./SignUp/newSignup.jsx";
import MainPage from "./Pages/MainPage.jsx";
import LandingPage from "./Pages/LandingPage.jsx";
const theme = createTheme({
  overrides: {
    MuiCardContent: {
      root: {
        paddingBottom: "4px !important",
        padding: 8,
      },
    },
  },
  palette: {
    primary: {
      main: "#b5b5b5",
    },
    secondary: {
      light: "#53A318",
      main: "#53A318",
      contrastText: "#ffcc00",
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSpLoggedIn: false,
      isUserLoggedIn: false,
      isAuth: false,
      uuid: ""      
    };
    this.handleUserLogin = this.handleUserLogin.bind(this);
    this.handleServiceProviderLogin = this.handleServiceProviderLogin.bind(
      this
    );
    this.handleLogout = this.handleLogout.bind(this);
    this.inactivityTime();



    window.onbeforeunload = () => {
      let ls = new SecureLS();
      var expiry = new Date();
      let token = ls.get(TOKEN);
      let sp = ls.get(SERVICEPROVIDER);
      let splogin = ls.get(SERVICEPROVIDERLOGIN);
      let user = ls.get(USER);
      let userlogin = ls.get(USERLOGIN);
      expiry.setDate(expiry.getDate() + 3); // three days 
      ls.set(EXPIRY, expiry / 1000);
      ls.set(TOKEN, token);
      ls.set(SERVICEPROVIDER, sp);
      ls.set(SERVICEPROVIDERLOGIN, splogin);
      ls.set(USER, user);
      ls.set(USERLOGIN, userlogin);
    };
  }

  inactivityTime = () => {
    var time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    const inactiveLogout = () => {
      this.logout();
    };

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(inactiveLogout, 20 * 60 * 1000);
    }
  };

  componentWillMount() {

    this.setState({
      uuid: window.self.crypto.randomUUID(),
    })

    const localStore = new SecureLS();
    const expiryDate = localStore.get(EXPIRY);
    if (expiryDate < Date.now() / 1000) {
      let ls = new SecureLS();
      ls.remove(TOKEN);
      ls.remove(USER);
      ls.remove(USERLOGIN);
      ls.remove(SERVICEPROVIDER);
      ls.remove(SERVICEPROVIDERLOGIN);
      ls.remove(EXPIRY);
      this.props.removeUser(null);
      this.props.removeServiceProvider(null);
      this.props.removeToken(null);
    } else {
      const token = localStore.get(TOKEN);
      if (token) {
        const isUserLoggedIn = localStore.get(USERLOGIN);
        if (isUserLoggedIn) {
          this.setState({
            isUserLoggedIn: true,
            isSpLoggedIn: false,
          });
          const user = localStore.get(USER);
          this.props.saveUser(user);
          this.props.saveToken(token);
          this.props.removeServiceProvider();
        }
        const isSpLoggedIn = localStore.get(SERVICEPROVIDERLOGIN);
        if (isSpLoggedIn) {
          this.setState({
            isSpLoggedIn: true,
            isUserLoggedIn: false,
          });
          const serviceProvider = localStore.get(SERVICEPROVIDER);
          this.props.saveServiceProvider(serviceProvider);
          this.props.saveToken(token);
          this.props.removeUser();
        }
      }
    }
  }

  handleUserLogin = (data) => {
    let ls = new SecureLS();
    ls.set(TOKEN, data.access_token);
    ls.set(USER, data.user);
    ls.set(USERLOGIN, true);
    this.props.saveUser(data.user);
    this.props.saveToken(data.access_token);
    this.props.removeServiceProvider();
    this.setState({
      isUserLoggedIn: true,
      isSpLoggedIn: false,
    });
  };

  handleServiceProviderLogin = (data) => {
    //console.log(data)

    if (data.user.memberOf === "PROPAGILE") {
      let ls = new SecureLS();
      var expiry = new Date();
      expiry.setMinutes(expiry.getMinutes() + 20);
      ls.set(TOKEN, data.access_token);
      ls.set(SERVICEPROVIDER, data.user);
      ls.set(SERVICEPROVIDERLOGIN, true);
      ls.set(EXPIRY, expiry / 1000);
      this.props.saveServiceProvider(data.user);
      this.props.saveToken(data.access_token);
      this.props.removeUser();
      this.setState({
        isSpLoggedIn: true,
        isUserLoggedIn: false,
      });
    }

    if (data.user.memberOf === "CONNECTIONS") {


      if (this.props.signIn(
        {
          token: data.access_token,
          expiresIn: 345600,
          tokenType: "Bearer",
          authState: data.user,    // Only if you are using refreshToken feature
        }
      )) {
        // Redirect or do-something
        window.location.replace("http://localhost:3000/dashboard");
      } else {
        //Throw error
        console.log("error")
      }


    }

  };

  logout = () => {
    this.handleLogout(true);
  };

  logoutWithoutRefresh = () => {
    this.handleLogout(false);
  };

  handleLogout = (shouldRefresh) => {
    let ls = new SecureLS();
    ls.remove(TOKEN);
    ls.remove(USER);
    ls.remove(USERLOGIN);
    ls.remove(SERVICEPROVIDER);
    ls.remove(SERVICEPROVIDERLOGIN);
    ls.remove(EXPIRY);
    localStorage.removeItem("tab");
    this.props.removeUser(null);
    this.props.removeServiceProvider(null);
    this.props.removeToken(null);
    this.setState({
      isUserLoggedIn: false,
      isSpLoggedIn: false,
    });
    if (shouldRefresh) {
      //this.props.history.push("/");
      // window.location.reload();
    }
  };

  

  render() {
    const { isUserLoggedIn, isSpLoggedIn, uuid, steps, run } = this.state;



    return (
     
     
        

        <ThemeProvider theme={theme}>
          <div className="App">
            {/* <button onClick={this.startTour}>Iniciar Tour</button> */}


            {/* <BrowserRouter basename="/propagile-test"> */}
            <BrowserRouter basename="/">
              <Switch>
                <Route
                  exact={true}
                  path="/"
                  render={(routeProps) => (
                    // <DummyLandingPage
                    //   isUserLoggedIn={isUserLoggedIn}
                    //   isSpLoggedIn={isSpLoggedIn}
                    //   handleLogout={this.logout}
                    // />
                    // <HomeTest
                    //   isUserLoggedIn={isUserLoggedIn}
                    //   isSpLoggedIn={isSpLoggedIn}
                    //   handleLogout={this.logout}
                    //   {...routeProps}
                    // />
                    <LandingPage isSpLoggedIn={isSpLoggedIn} />
                  )}
                />
                <Route
                  exact={true}
                  path="/landing/by-invitation-only/foremen"
                  render={() => (
                    <InvitationByForemen
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/landing/by-invitation-only/friends"
                  render={() => (
                    <InvitationByFriends
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/lead-landing"
                  render={() => (
                    <LeadLanding
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/landing/specialists-list-1/new-york-metropolitan-area/by-invitation-only"
                  render={() => (
                    <LandingPage
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/home"
                  render={(routeProps) => (
                    <Home
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                      {...routeProps}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/workflow"
                  render={() => (
                    <WorkFlow
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                      uuid={uuid} />
                  )}
                />
                <Route
                  exact={true}
                  path="/home-test"
                  render={(routeProps) => (
                    <HomeTest
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                      {...routeProps}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/contact-us"
                  render={() => <ContactUs />}
                />
                <Route
                  exact={true}
                  path="/vendor/lead/login"
                  render={() => (
                    <VendorLeadLogin
                      fromApp={true}
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/vendor/login"
                  render={() => (
                    <VendorLogin
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/vendor/sign-up"
                  render={() => (
                    <NewSignup
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                    />
                    // <VendorSignup
                    //   isUserLoggedIn={isUserLoggedIn}
                    //   isSpLoggedIn={isSpLoggedIn}
                    //   handleServiceProviderLogin={this.handleServiceProviderLogin}
                    // />
                  )}
                />
                <Route
                  exact={true}
                  path="/sponsor/sign-up"
                  render={() => (
                    <SponsorSignup />
                  )}
                />
                <Route
                  exact={true}
                  path="/vendor/sign-up-invite"
                  render={() => (
                    <VendorInviteSignUp
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/customer/sign-up"
                  render={() => (
                    <CustomerSignup
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleUserLogin={this.handleUserLogin}
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/pro1/:user"
                  render={() => (
                    <Card
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/profile/edit"
                  render={() => (
                    <DummyDashboard
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/profile/view"
                  render={() => (
                    <CardDummy
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/customer/request"
                  render={() => (
                    <Customers
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                    />
                    // <Request
                    //   isUserLoggedIn={isUserLoggedIn}
                    //   isSpLoggedIn={isSpLoggedIn}
                    // />
                  )}
                />
                <Route
                  exact={true}
                  path="/customers"
                  render={() => (
                    <Customers />
                  )}
                />
                <Route
                  exact={true}
                  path="/thanks"
                  render={() => (
                    <Thanks
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logoutWithoutRefresh}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/customer/thanks"
                  render={() => <CustomerThanks />}
                />
                <Route
                  exact={true}
                  path="/vendor/reset-password-request"
                  render={() => <ResetPasswordRequest />}
                />
                <Route
                  exact={true}
                  path="/reset-password"
                  render={() => <ResetPassword />}
                />
                <Route
                  exact={true}
                  path="/vendor/lead"
                  render={() => (
                    <Campaign
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                      isSpLoggedIn={isSpLoggedIn}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/vendor/subscriptions"
                  render={() => <Subscriptions isSpLoggedIn={isSpLoggedIn} />}
                />
                <Route
                  exact={true}
                  path="/vendor/review"
                  render={() => (
                    <Review
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                      isSpLoggedIn={isSpLoggedIn}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/vendor/endorsement"
                  render={() => (
                    <Endorsement
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                      isSpLoggedIn={isSpLoggedIn}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/terms"
                  render={() => (
                    <Terms
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/privacy-policy"
                  render={() => (
                    <PrivacyPolicy
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/news"
                  render={() => (
                    <News
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/about"
                  render={() => (
                    <About
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/careers"
                  render={() => (
                    <Careers
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/blog"
                  render={() => (
                    <Blog
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/project-manager/invite"
                  render={() => (
                    <Register
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/Construction-Project-Management/login"
                  render={() => (
                    <VendorLeadLogin
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleServiceProviderLogin={this.handleServiceProviderLogin}
                      isProjectManager={true}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/interactive"
                  render={() => (
                    <InteractivePanel />
                  )}
                />
                <PrivateRouter
                  isLoggedIn={isSpLoggedIn}
                  exact={true}
                  redirectionPath="/vendor/lead/login"
                  path="/construction-project-management/team"
                  component={() => (
                    <TeamDashboard
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <PrivateRouter
                  isLoggedIn={isSpLoggedIn}
                  exact={true}
                  redirectionPath="/vendor/lead/login"
                  path="/construction-project-management/project-manager"
                  component={() => (
                    <VendorInvite
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <PrivateRouter
                  isLoggedIn={isSpLoggedIn}
                  exact={true}
                  redirectionPath="/vendor/lead/login"
                  path="/vendor/dashboard"
                  component={() => (
                    // <BusinessDashboard
                    //   isUserLoggedIn={isUserLoggedIn}
                    //   isSpLoggedIn={isSpLoggedIn}
                    //   handleLogout={this.logout}
                    // />
                    <MainPage
                      logout={this.logout}
                    />
                  )}
                />
                <PrivateRouter
                  isLoggedIn={isSpLoggedIn}
                  exact={true}
                  redirectionPath="/vendor/lead/login"
                  path="/vendor/subscription-upgrade"
                  component={() => (
                    <SubscriptionUpgrade
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <PrivateRouter
                  isLoggedIn={isSpLoggedIn}
                  exact={true}
                  redirectionPath="/vendor/lead/login"
                  path="/vendor/individual-profile"
                  component={() => (
                    <IndividualProfile
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
                <PrivateRouter
                  isLoggedIn={isSpLoggedIn}
                  exact={true}
                  redirectionPath="/vendor/lead/login"
                  path="/vendor/company-profile"
                  component={() => (
                    <CompanyProfile
                      isUserLoggedIn={isUserLoggedIn}
                      isSpLoggedIn={isSpLoggedIn}
                      handleLogout={this.logout}
                    />
                  )}
                />
              </Switch>
            </BrowserRouter>
          </div>
        </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveServiceProvider,
      removeServiceProvider,
      saveUser,
      removeUser,
      saveToken,
      removeToken,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
