
import { TOGGLE_CHECK } from '../Actions/TreeChecked';

const initialState = {
    checkedIds: [],
};

const TreeChecked = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_CHECK: {
            const id = action.payload;
            const isChecked = state.checkedIds.includes(id);
            return {
                ...state,
                checkedIds: isChecked
                    ? state.checkedIds.filter(checkedId => checkedId !== id)
                    : [...state.checkedIds, id],
            };
        }
        default:
            return state;
    }
};

export default TreeChecked;
