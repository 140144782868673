import React, { useEffect, useState, useRef, useReducer } from 'react'
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import { useHistory } from "react-router-dom";
import { Box } from '@mui/material'
import { Carousel } from 'react-bootstrap';
import Footer from '../Footer'
import NewHeader from '../Header/NewHeader';
import TreeTrades from '../Dashboard/components/TreeTrades';
import TreeSupplementary from './TreeSupplementary';
import { BASE_URL } from "../../Constants/serverConfig.jsx";
import './index.css'
import axios from 'axios';
import ModalEntrance from './ModalEntrance';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CheckList from './CheckList';
import Loader from '../Loader/loader';
import { isMobile } from 'react-device-detect';
import PageLayoutFree from '../Templates/PageLayoutFree';
const DATA_TRADES = require('../../Constants/dataSourceTotal.json');
const DATA_SUPPLEMENTARY = require('../../Constants/supplementaryDefaultSA00.json');
const DATA_CHECK = require('../../Constants/checkList.json');


const TOUR_STEPS = [
    {
        target: ".tour-search",
        content: (<><span>Clic on a project to activate the sidebar menu options.</span></>),
        disableBeacon: true,
        disableOverlayClose: true,       
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        styles: {
            tooltip : {padding:2, fontSize:14},
            tooltipContainer : {textAlign: 'center'},
        }
    }

];

const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "START":
            return { ...state, run: true };
        case "RESET":
            return { ...state, stepIndex: 0 };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, ...action.payload };
        case "RESTART":
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date()
            };
        default:
            return state;
    }
};

function WorkFlow(props) {

    let initialProjectData = {
        name: props.data?.name ?? "Project Example",
        zip_code: props.data?.zip_code ?? "10001",
        work_type: props.data?.work_type ?? "New Construction",
        main_building_use: props.data?.main_building_use ?? "Retail",
        gross_area: props.data?.gross_area ?? "4000",
        id: props.data?.id ?? -1,
        uuid: props.data?.uuid ?? "uuid-propagile-2025",
        project_item_selection: "[]"
    }
    const [showPopup, setShowPopup] = useState(false);
    const [messageEntrance, setMessageEntrance] = useState("");
    const [index, setIndex] = useState(0);

    const [showWorkFlowForm, setShowWorkFlowForm] = useState(false);
    const [showWorkFlowButtons, setShowWorkFlowButtons] = useState(true);
    const [currentPhase, setCurrentPhase] = useState(0);

    const [showCheckList, setShowCheckList] = useState(false);
    const [showFrameworkPanel, setShowFrameworkPanel] = useState(false);
    const [showIntroPanel, setShowIntroPanel] = useState(true);
    const [currentPanel, setCurrentPanel] = useState(0);
    const [checkedData, setCheckedData] = useState([]);

    const [showProjectDetails, setShowProjectDetails] = useState(true);
    const [showProjectDetailsItems, setShowProjectDetailsItems] = useState(false);
    const [showProjectWorkflow, setShowProjectWorkflow] = useState(false);
    const [showProjectWorkflowNotification, setShowProjectWorkflowNotification] = useState(false);

    const [showSupplementaryModal, setShowSupplementaryModal] = useState(false);
    const [showSupplementaryPopup, setShowSupplementaryPopup] = useState(false);

    const [showItemDetails, setShowItemDetails] = useState(false);
    const [showModalFeedback, setShowModalFeedback] = useState(false);
    const [itemDetails, setItemDetails] = useState();
    const [activeAlert, setActiveAlert] = useState(false);

    const [pathList, setPathList] = useState(props.pathFramework ?? []);
    const [supplementaryList, setSupplementaryList] = useState(props.pathSupplementary ?? []);
    const [pathSupplementaryList, setPathSupplementaryList] = useState([]);
    const [projectId, setProjectId] = useState(props.data?.id || 0);
    const [lastPath, setLastPath] = useState([]);
    const [lastItemName, setLastItemName] = useState("");

    const [showModalEntrance, setShowModalEntrance] = useState(false);
    const [showStripeWall, setShowStripeWall] = useState(false);


    const [projectInformation, setProjectInformation] = useState(initialProjectData)

    const [schematicDesignSelected, setSchematicDesignSelected] = useState([]);

    const [workflow, setWorkflow] = useState([]);
    const [checkData, setCheckData] = useState([]);
    const [supplementary, setSupplementary] = useState([]);

    const [editMode, setEditMode] = useState(false);

    const [learnMore, setLearnMore] = useState(false);

    const [tooltipDescriptionShow, setTooltipDescriptionShow] = useState(false);
    const target = useRef(null);

    const [showLoader, setShowLoader] = useState(false);

    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

    const targetRef = useRef(null);
    let history = useHistory();



    useEffect(() => {

        if (!props.isChildren) {
            history.push(`/workflow?s_id=${props.uuid}&check=true`)
            setProjectInformation(prevState => ({
                ...prevState,
                uuid: props.uuid
            }));
        }

        if (props.showFrameworkPanel) {
            setShowFrameworkPanel(true)
            setShowIntroPanel(false)
            setCurrentPanel(1);
        }
        setShowPopup(true);

        const data = JSON.parse(JSON.stringify(DATA_TRADES));
        const check = JSON.parse(JSON.stringify(DATA_CHECK));


        setWorkflow(data);
        setCheckData(check);

        if (showProjectWorkflow) {
            setShowProjectDetailsItems(false);
        }
        startWorkFlowHandled();

        let hiddenTour = localStorage.getItem('hiddenTour');        
        if(hiddenTour !== "1") dispatch({ type: "START" });

    }, [])

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const check = queryParameters.get("check");

        if (check) {
            setShowCheckList(true);
        }

        sessionStorage.setItem('workflow_project', JSON.stringify(initialProjectData));

    }, [])

    useEffect(() => {

        let data = JSON.parse(JSON.stringify(DATA_SUPPLEMENTARY));
        setSupplementary(data);

    }, [showSupplementaryModal])

    useEffect(() => {
        if (supplementary.length) {
            setSupplementary(checkedItemSupplementaryTree(pathSupplementaryList));
        }
    }, [supplementary])

    useEffect(() => {
    }, [pathSupplementaryList])


    useEffect(() => {
        let data = require('../../Constants/dataSourceTotal.json');

        if (editMode) {
            setCurrentPhase(3);
            setWorkflow(checkedItemTree(pathList));
            setShowWorkFlowButtons(false);
            //setShowProjectDetails(false);
            setShowWorkFlowForm(true)
        }


    }, [editMode]);

    useEffect(() => {

        if (lastPath.length) {
            let path = JSON.stringify(lastPath[0].split("-").map(Number));
            const foundSupplementaryPath = supplementaryList.find(item => item.parent_path === path);

            if (foundSupplementaryPath) {
                setPathSupplementaryList(JSON.parse(foundSupplementaryPath.supplementary_path));
            }

        }

    }, [lastPath])

    const setTourViewed = () => {
        localStorage.setItem("hiddenTour", "1");
    };

    const handleSupplementaryModal = () => {

        setShowSupplementaryModal(true);
        setShowSupplementaryPopup(true);
    }

    const scrollNotificationProject = () => {

        console.log(isMobile);

        if (isMobile) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const handleLastPath = (path, item) => {
        setLastPath([path]);
        setLastItemName(item.name);
    }

    const getCheckedItems = (data) => {
        setCheckedData(data)
    }

    const editWorkflow = () => {
        setWorkflow(checkedItemTree(pathList));
    }



    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const handleFormInput = (evt) => {

        const { name, value } = evt.target;

        setProjectInformation(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const startWorkFlowHandled = () => {
        // setShowWorkFlowForm(true);

        //  setCurrentPhase(1);
        validateForm('PRE-CONSTRUCTION');


    }

    const validateForm = (type = '') => {

        if (projectInformation.zip_code === "" || projectInformation.gross_area === "" || projectInformation.work_type === "" || projectInformation.main_building_use === "") {
            setActiveAlert(true);
        } else {

            if (type == "PRE-CONSTRUCTION") {
                //setCurrentPhase(3);
                // setShowIntroPanel(false);
                // setShowFrameworkPanel(true);
                // setCurrentPanel(2);
                saveWorkFlowStatus();
                setShowPopup(false);
                updateCurrentPanel(2)
            }
            else if (type == "PRE-DEVELOPMENT") {
                // setShowIntroPanel(false);
                // setShowPopup(false);
                // setShowFrameworkPanel(true);
                // setCurrentPanel(1);
                setShowPopup(false);
                updateCurrentPanel(1)
            }
        }
        setSchematicDesignSelected([1, 2, 3]);



    }

    const updateCurrentPanel = (id = 0) => {
        setCurrentPanel(id);
        if (id == 1) {
            setShowProjectDetails(false);
            setShowProjectDetailsItems(false);
            setShowIntroPanel(false);
            setShowProjectWorkflow(false)
            setShowFrameworkPanel(true);

        }
        if (id == 2) {
            setShowProjectDetails(true);
            setShowProjectDetailsItems(showProjectWorkflow ? false : true); //cambiar
            setShowIntroPanel(true);
            setShowFrameworkPanel(false);
        }
    }

    const showProjectDescriptionModal = () => {
        setCurrentPhase(1);
        setShowWorkFlowButtons(false);
        setShowProjectDetails(true);
        setShowPopup(true);
        setShowWorkFlowForm(true);
        setLearnMore(false);
    }

    const saveWorkFlowStatus = () => {

        let body = {
            zip_code: projectInformation.zip_code,
            work_type: projectInformation.work_type,
            main_building_use: projectInformation.main_building_use,
            session_id: props.uuid,
            schematic_design_selected: JSON.stringify(schematicDesignSelected)
        }
        return true;
        axios
            .post(`${BASE_URL}/searcher/workflow-project`, body, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(response => {
                console.log(response);
            }).catch(err => {
                console.log(err);
            })
    }



    const ItemInformation = () => {
        return (
            <div>
                {itemDetails}
            </div>
        )
    }

    const updateItemsDetails = (data) => {
        setItemDetails(data);
    }

    const checkedItemTree = (paths) => {

        for (const [index, path] of paths.entries()) {

            let auxDataTrade = { ...workflow[0].children };
            //  console.log(path);
            //let path = [0,1];
            for (let i = 0; i < path.length; i++) {
                const pos = path[i];
                if ((i + 1) != path.length) {
                    auxDataTrade[pos].visible = true;
                }
                if (!auxDataTrade[pos]) {
                    auxDataTrade[pos] = { children: [] };
                }
                if (i === path.length - 1) {
                    auxDataTrade[pos].checked = true;
                } else {
                    auxDataTrade = auxDataTrade[pos].children;
                }
            }
        }


        return workflow;
    }

    const checkedItemSupplementaryTree = (paths) => {

        for (const [index, path] of paths.entries()) {

            let auxDataTrade = { ...supplementary[0].children };
            //  console.log(path);
            //let path = [0,1];
            for (let i = 0; i < path.length; i++) {
                const pos = path[i];
                if ((i + 1) != path.length) {
                    auxDataTrade[pos].visible = true;
                }
                if (!auxDataTrade[pos]) {
                    auxDataTrade[pos] = { children: [] };
                }
                if (i === path.length - 1) {
                    auxDataTrade[pos].checked = true;
                } else {
                    auxDataTrade = auxDataTrade[pos].children;
                }
            }
        }


        return supplementary;
    }

    const saveGroup = (data, pathArray) => {

        if (pathArray) {
            setPathList(pathArray);
            setProjectInformation(prevState => ({
                ...prevState,
                project_item_selection: pathArray
            }));
        }

    }

    const saveSupplementaryGroup = (data, pathArray) => {
        if (pathArray) {
            setPathSupplementaryList(prevState => [...prevState, ...pathArray]);
        }
    }

    const submitDownloadCheckList = () => {
        setShowLoader(true);
        axios.post(`${BASE_URL}/project/generate-checklist-file`,
            {
                checked_data: JSON.stringify(checkedData),
                uuid: props.uuid

            })
            .then((response) => {

                if (response.status === 200) {
                    axios.get(BASE_URL + '/public/project/download-report/' + props.uuid, {
                        responseType: 'blob'// Configurar responseType como blob para manejar archivos binario
                    }).then(response => {

                        const url = window.URL.createObjectURL(new Blob([response.data]));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', props.uuid + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        setShowLoader(false);
                    }).catch(err => {
                        console.log(err);
                    });
                }

            })
            .catch((error) => {

            });
    }

    const submitDownloadFramework = () => {
        setShowLoader(true)
        axios.post(`${BASE_URL}/project/report`,
            {
                framework_data: JSON.stringify(pathList),
                uuid: props.uuid

            })
            .then((response) => {

                if (response.status === 200) {
                    axios.get(BASE_URL + '/public/project/download-report/' + props.uuid, {
                        responseType: 'blob'// Configurar responseType como blob para manejar archivos binario
                    }).then(response => {

                        const url = window.URL.createObjectURL(new Blob([response.data]));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', props.uuid + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        setShowLoader(false);
                    }).catch(err => {
                        console.log(err);
                    });
                }

            })
            .catch((error) => {

            });

    }

    const updateAllFramework = () => {
        if (props.isChildren) {
            props.handleUpdate(projectInformation.id, projectInformation, pathList, pathSupplementaryList, lastPath);
        } else {
            setShowProjectDetails(true)
            setShowProjectDetailsItems(true)
            setShowProjectWorkflow(false)
        }
    }

    const handleSupplementary = (item, checked) => {

        let path = item.split("-").map(Number);
        setSupplementary(checkedItemSupplementaryTree([path]));
    }


    const handleCheckedChange = (path, checked) => {

        const updateCheckedStatus = (node) => {
            console.log(node.path === path);

            if (node.path === path) {
                return { ...node, checked };
            }
            if (node.children) {
                return {
                    ...node,
                    children: node.children.map(updateCheckedStatus)
                };
            }
            return node;
        };

        setSupplementary(updateCheckedStatus(supplementary));
    };

    const handleProjectPhaseInput = (e) => {
        updateCurrentPanel(e.target.value)
    }

    const callback = data => {
        const { action, index, type, status } = data;

        if (
            // If close button clicked, then close the tour
            action === ACTIONS.CLOSE ||
            // If skipped or end tour, then close the tour
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            setTourViewed();
            dispatch({ type: "STOP" });
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            // Check whether next or back button click and update the step.
            dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
            });
        }
    };

    const startTour = () => {
        // Start the tour manually
        dispatch({ type: "RESTART" });
    };

    const  stopTour = () => {
        // Start the tour manually
        dispatch({ type: "STOP" });
    };


    return (

        <div className={'workflow-container ' + (props.isChildren ? 'children' : '')}>
            <Loader text="Loading..." open={showLoader} />
            <JoyRide
                {...tourState}
                callback={callback}
                showSkipButton={true}
                styles={{
                    tooltipContainer: {
                        textAlign: "left"
                    },
                    buttonBack: {
                        marginRight: 10
                    }
                }}
                locale={{
                    last: "End tour"
                }}
            />
            <div className={`modal small-popup ` + (showSupplementaryPopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className={"modal-card"}>
                    <section className={"modal-card-body"}>
                        {supplementary.length &&
                            <TreeSupplementary
                                data={[supplementary[0]]}
                                handleSupplementary={handleSupplementary}
                                pathArray={pathSupplementaryList}
                                saveGroup={saveSupplementaryGroup}
                            ></TreeSupplementary>
                        }

                    </section>
                    <div className='cont-supplementary-button bg-light text-center'>
                        <button className="btn btn-primary" onClick={() => setShowSupplementaryPopup(false)}>Save</button>
                        <button className="btn btn-danger" onClick={() => setShowSupplementaryPopup(false)}>Close</button>
                    </div>

                </div>
            </div>

            {(currentPhase > 0) &&
                <div className={`modal small-popup ` + (showPopup ? 'is-active' : '')}>
                    <div className="modal-background" />
                    <div className={"modal-card " + (showWorkFlowForm ? " workflow-information-expanded " : "") + (showItemDetails ? 'read-only' : '')}>
                        <section className={"modal-card-body home-modal " + ((currentPhase == 2 || currentPhase == 3) ? "cont-tree" : "")}>
                            {learnMore &&
                                <>
                                    <div className="workflow-description">


                                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={50000}>
                                            <Carousel.Item className='carousel-workflow' style={{ height: "200px" }}>

                                                <Carousel.Caption>
                                                    <h3>Propagile Workflow</h3>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium aut autem a asperiores recusandae hic pariatur illo nulla consectetur, odit ipsa nesciunt atque voluptate aspernatur. Praesentium nisi a esse cumque.</p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                            <Carousel.Item className='carousel-workflow' style={{ height: "200px" }}>


                                                <Carousel.Caption>
                                                    <h3>Construction Phases</h3>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi accusantium voluptatum reprehenderit placeat ab incidunt, animi ullam voluptate recusandae in quo, sunt unde nisi eveniet quia. Itaque accusamus numquam sunt!</p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                            <Carousel.Item className='carousel-workflow' style={{ height: "200px" }}>


                                                <Carousel.Caption>
                                                    <h3>Third slide label</h3>
                                                    <p>
                                                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                                    </p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                    <div className="workflow-image">
                                        <img src="../images/underConstruction.png" alt="propagile workflows" width={550} />
                                    </div>


                                </>
                            }

                            {/* <section className="modal-card-body home-modal "> */}
                            <form className={'phase-1 ' + (currentPhase == 1 ? 'active' : currentPhase == 5 ? 'inactive' : [2, 3].includes(currentPhase) ? 'inactive-left' : '')}>
                                <div className='title-modal-step'>
                                    <h3>Project Description</h3>

                                    <OverlayTrigger
                                        key={1}
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip id={`tooltip-1`}>
                                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et excepturi quo voluptatum delectus earum eveniet placeat voluptatibus qui a cumque nemo.</p>
                                            </Tooltip>
                                        }
                                    >
                                        <span ><i class="bi bi-exclamation-circle"></i></span>
                                    </OverlayTrigger>
                                </div>
                                {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et excepturi quo voluptatum delectus earum eveniet placeat voluptatibus qui a cumque nemo.</p> */}
                                <div className="form-group">
                                    <label htmlFor="inputName">Name</label>
                                    <input type="text" className={"form-control " + (activeAlert && projectInformation.name == "" ? 'error-input' : '')} id="inputName" aria-describedby="inputname" placeholder="Enter Project Name" name="name" value={projectInformation.name} onChange={handleFormInput} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputZip">ZipCode</label>
                                    <input type="text" className={"form-control " + (activeAlert && projectInformation.zip_code == "" ? 'error-input' : '')} id="inputZip" aria-describedby="inputZip" placeholder="Enter Zip Code" name="zip_code" value={projectInformation.zip_code} onChange={handleFormInput} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputGross">Gross Area</label>
                                    <input type="text" className={"form-control " + (activeAlert && projectInformation.gross_area == "" ? 'error-input' : '')} id="inputGross" aria-describedby="inputGross" placeholder="Enter Gross Area" name="gross_area" value={projectInformation.gross_area} onChange={handleFormInput} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputWork">Work Type</label>
                                    <select className={"form-control " + (activeAlert && projectInformation.work_type == "" ? 'error-input' : '')} id="inputWork" name="work_type" onChange={handleFormInput}>
                                        <option selected={true}>{(projectInformation.main_building_use == "" ? "Select work type" : projectInformation.work_type)}</option>
                                        {/* <option selected={true}>Select work type</option> */}
                                        <option>New Construction</option>
                                        <option>Remodeling</option>
                                        <option>Renovation/Repair</option>
                                        <option>Enlargement</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputZip">Main Building Use</label>
                                    <select className={"form-control " + (activeAlert && projectInformation.main_building_use == "" ? 'error-input' : '')} id="inputZip" name="main_building_use" onChange={handleFormInput}>
                                        <option selected={true}>{(projectInformation.main_building_use == "" ? "Add infrastructure" : projectInformation.main_building_use)}</option>
                                        {/* <option selected={true}>Select main building use</option> */}
                                        <option>Single / Two family home</option>
                                        <option>Multifamily property (3+ units)</option>
                                        <option>Commercial: (Offices, Flex space, Medical offices).</option>
                                        <option>Retail</option>
                                        <option>Hospitality: (Hotel, Restaurants, Event venues)</option>
                                        <option>Warehouses, logistics, and storage</option>
                                        <option>Data Centers</option>
                                        <option>Manufacturing</option>
                                        <option>Schools </option>
                                        <option>Universities</option>
                                        <option>Health care facilities</option>
                                        <option>Infrastructure</option>
                                    </select>
                                </div>
                            </form>
                            {/* </section> */}



                            {/*  TEMPORALLY UNABLED */}
                            {/* <form className={'phase-2 ' + (currentPhase == 2 ? 'active' : currentPhase == 5 ? 'inactive' : [3].includes(currentPhase) ? 'inactive-left' : '')}>
                                <h3>Concept Phase</h3>
                                {currentPhase == 2 && <TreeTrades data={[workflow[0]]} saveGroup={saveGroup} type={'CONCEPT'} showDescription={() => setShowItemDetails(true)} itemDetails={updateItemsDetails} paidMembership={props.isChildren ? true : false}></TreeTrades>}
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et excepturi quo voluptatum delectus earum eveniet placeat voluptatibus qui a cumque nemo, itaque ullam architecto ab eius soluta praesentium nam minima!</p>

                            </form>

                            <form className={'phase-3 ' + (currentPhase == 3 ? 'active' : (currentPhase == 5) ? 'inactive' : '')}>
                                <h3>Click to Customize Your Project’s <br></br>Construction Framework</h3>
                                <p>Start by selecting building components, proceed to types, then assign materials; select to add to your framework, To define how work should be packaged, and assign parameters to any selection.</p>
                                <p className='phase-3-span'>(<span style={{ color: '#1a67a3', cursor: 'pointer', fontWeight: '600' }}>Based on Uniformat / Masterformat connected</span>)</p>
                                <TreeTrades data={[workflow[0]]} saveGroup={saveGroup} pathArray={pathList} type={'SCHEMATIC'} showDescription={() => setShowItemDetails(true)} itemDetails={updateItemsDetails} paidMembership={props.isChildren ? true : false}></TreeTrades>
                            </form> */}

                            {(currentPhase == 2 && showItemDetails) &&
                                < div className={'item-information ' + (showItemDetails ? 'active' : '')}>
                                    <div className='' style={{ display: (showItemDetails ? 'block' : 'none'), padding: '5px 15px' }}>
                                        <div className='title-panel-phase'>
                                            <h4 className={"title-phase" + (itemDetails.name.length > 15 ? '-14' : '')}>{itemDetails.name}</h4>
                                            <span className={"edit-phase-details"} onClick={() => { setShowItemDetails(false) }}><i className="bi bi-x-lg"></i></span>
                                        </div>
                                        <p style={{ marginTop: '5px' }}>
                                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quo consequatur incidunt, quisquam quos magnam laborum sed quod natus.
                                        </p>
                                    </div>

                                </div>
                            }
                        </section>
                        {/* </section> */}

                        {
                            (currentPhase == 1 && showWorkFlowButtons) &&
                            <div className='project-descripton-buttons'>
                                <button className='btn-start-workflow' onClick={() => validateForm("PRE-DEVELOPMENT")}>Start with Pre-Development</button>
                                <button className='btn-start-workflow' onClick={() => validateForm("PRE-CONSTRUCTION")}>Start with Pre-Construction</button>
                            </div>
                        }

                        {
                            (currentPhase == 2 && showWorkFlowButtons) &&
                            <>
                                <button className='btn-start-workflow' onClick={() => setCurrentPhase(3)}>Next (Schematic Design Phase)</button>
                            </>
                        }
                        {
                            (currentPhase == 3 && showWorkFlowButtons) &&
                            <>
                                <button className='btn-start-workflow finish' onClick={() => { saveWorkFlowStatus(); setShowProjectDetails(true); setCurrentPhase(0); setShowWorkFlowForm(false); }}>Next Step</button>
                            </>
                        }
                        {
                            (!showWorkFlowButtons) &&
                            <>
                                <button className='btn-start-workflow' onClick={() => { updateAllFramework(); setEditMode(false); setShowProjectDetails(true); setCurrentPhase(0); setShowWorkFlowForm(false) }}>{([5, 2].includes(currentPhase) ? 'Close' : 'Update')}</button>
                            </>
                        }
                    </div>
                </div >
            }
            {
                showModalEntrance &&
                <div className={'modal small-popup ' + (showModalEntrance && 'is-active')}>
                    <div className="modal-background" />

                    <div className={"modal-card modal-entrance"}>
                        <section className={"modal-card-body " + (showModalEntrance ? 'entrance' : '')}>
                            <div className='div-close-button'>
                                <button onClick={() => setShowModalEntrance(false)}>X</button>
                            </div>
                            <ModalEntrance
                                isUserLoggedIn={props.isUserLoggedIn}
                                isSpLoggedIn={props.isSpLoggedIn}
                                handleServiceProviderLogin={props.handleServiceProviderLogin}
                                projectInformation={projectInformation}
                                message={messageEntrance}
                                showStripeWall={showStripeWall}
                            >
                            </ModalEntrance>
                        </section>
                    </div>
                </div>


            }
            {!props.isChildren &&
                <NewHeader />
            }
            <div className={'workflow-body ' + (props.isChildren ? 'children' : '')}>
                <>
                    {showIntroPanel &&
                        <>
                            {(!showProjectDetails) &&
                                <div className='text-center'>
                                    <div className="container workflow-description-div">


                                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={50000}>
                                            <Carousel.Item className='carousel-workflow' >
                                                <div className="workflow-image">
                                                    <img src="../images/firstCaption.png" alt="propagile workflows" width={550} />
                                                </div>
                                                <Carousel.Caption>
                                                    <h3>PropAgile Construction Frameworks</h3>
                                                    <p>
                                                        This tool simplifies the selection of building components, methods, and materials, organizing them into a customized framework that serves as a detailed checklist
                                                        to manage each step of the construction process. It outlines what needs to be done, how it should be done, and groups work to clarify the scope for contracts and hiring needs.
                                                    </p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                    {
                                        (!learnMore && showWorkFlowButtons) &&
                                        <>
                                            <button className='btn-start-workflow-div' onClick={startWorkFlowHandled}>Start Here</button>
                                        </>
                                    }

                                </div>
                            }
                            {showProjectDetails &&

                                <div className='workflow-details'>
                                    <PageLayoutFree
                                        project={projectInformation}
                                        isUserLoggedIn={props.isUserLoggedIn}
                                        isSpLoggedIn={props.isSpLoggedIn}
                                        handleServiceProviderLogin={props.handleServiceProviderLogin}
                                        uuid={props.uuid}
                                        stopTour={stopTour}
                                    />
                                </div>


                            }
                        </>
                    }

                </>
            </div>
            {!props.isChildren &&
                <Box className="workflow-footer" >
                    <Footer showModalFeedback={showModalFeedback} setShowModalFeedback={setShowModalFeedback} />
                </Box>
            }
        </div >
    )
}

export default WorkFlow