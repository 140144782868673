/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { compose } from "recompose";
import { compose } from "redux";
import querystring from "querystring";
import axios from "axios";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import Loader from "../Loader/loader.jsx";
import Select from "react-select";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

class Endorsement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      vendorName: "",
      profileName: "",
      token: "",
      firstName: "",
      lastName: "",
      trade: [],
      email: "",
      comment: "",
      selectedServices: [],
      showError: false,
      errorMessage: "",
      showLoader: false,
      tradeOptions: [],
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  componentDidMount() {
    let query = querystring.parse(this.props.location.search);
    this.setState({
      vendorName: query["?vendor_name"],
      token: query["token"],
      profileName: query["profile_name"],
    });
    axios
      .get(`${BASE_URL}/endorsement/service?token=${query["token"]}`)
      .then((response) => {
        if (response.data.filter((x) => x.is_endorsed === false).length === 0) {
          this.showError(
            "You have already endorsed all services. Cannot endorse any further"
          );
        }
        this.setState({
          services: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(
        "https://api.propagile.com/api/v1/items-wbs-branching/masterformat?name="
      )
      .then((res) => {
        console.log("res: ", res);
        this.setState({
          tradeOptions: res.data.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        });
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }

  handleChange = (event) => {
    if (event.target.name === "comment") {
      if (event.target.value.length <= 1500) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      } else {
        this.setState({
          [event.target.name]: event.target.value.substr(0, 1500),
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleCheckboxClick = (name) => {
    const selection = this.state[name];
    console.log(selection);
    let parent_id = name.split("-")[0];
    let child_id = name.split("-")[1];
    const { selectedServices } = this.state;
    if (selection === true) {
      this.setState({
        [name]: false,
        selectedServices: [
          ...selectedServices.filter((item) => item.parent_id !== parent_id),
        ],
      });
    } else {
      this.setState({
        [name]: true,
        selectedServices: [...selectedServices, { parent_id, child_id }],
      });
    }
  };

  validateInformation = () => {
    this.clearError();
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    // let companyNameValidation = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/;
    const { firstName, lastName, trade, email, comment } = this.state;
    if (firstName === "") {
      this.showError("Please enter name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (trade === "") {
      this.showError("Please enter your trade");
      return false;
    }
    /* if (!companyNameValidation.test(trade.trim())) {
      this.showError("Please enter a valid trade name");
      return false;
    } */
    if (email === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    if (comment.trim() === "") {
      this.showError("Please enter your comment");
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validateInformation()) {
      return;
    }
    const {
      token,
      firstName,
      lastName,
      trade,
      email,
      comment,
      selectedServices,
      profileName,
    } = this.state;
    if (selectedServices.length === 0) {
      this.showError("Please choose a service for endorsement");
      return;
    }

    const body = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      trade: trade.map((item) => ({
        id: item.value,
        name: item.label,
      })),
      email_address: email.trim(),
      endorsement_note: comment.trim(),
      selected_service: JSON.stringify(selectedServices),
      request_token: token,
    };
    console.log("sending DATA ==> ", body);
    this.startLoader();
    axios
      .post(`${BASE_URL}/endorsement`, querystring.stringify(body), {
        "Content-Type": "application/x-www-form-urlencoded",
      })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          this.props.history.push(`/pro1/${profileName}`);
        }
      })
      .catch((error) => {
        this.stopLoader();
        console.error(error.response);
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
      });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };
  render() {
    const {
      services,
      vendorName,
      firstName,
      lastName,
      trade,
      email,
      comment,
      showError,
      errorMessage,
      showLoader,
      tradeOptions,
    } = this.state;
    const {
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
      isUserLoggedIn,
    } = this.props;

    return (
      <div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <section className="endos-ment-form">
          <Loader text="Loading..." open={showLoader} />
          {showError && (
            <div className="modal is-active custom-modal error-message">
              <div className="modal-background" />
              <div className="modal-content">
                <div className="error-message-modal">
                  <button onClick={this.clearError} className="modal-close" />
                  <p>{errorMessage}</p>
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="form-inner signup-form">
            <div className="form-main">
              <div className="form-title">
                <h3>Endorsement for {vendorName}</h3>
                <p>
                  I endorse {vendorName} for the following services: check on
                  all that apply
                </p>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <div className="promo_checkboxes_new">
                    {services.map((service, index) => (
                      <div
                        key={index}
                        onClick={
                          service.is_endorsed
                            ? () => {
                                this.showError(
                                  "This service is already endorsed"
                                );
                              }
                            : () =>
                                this.handleCheckboxClick(
                                  `${service.parent_id}-${service.child_id}`
                                )
                        }
                        className={
                          service.is_endorsed
                            ? "checkkk clearfix is-disabled"
                            : "checkkk clearfix"
                        }
                      >
                        <div
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                          className="checkbox"
                        >
                          <label
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                            className={
                              this.state[
                                `${service.parent_id}-${service.child_id}`
                              ] === true
                                ? "is-active-checkbox"
                                : "checkbox"
                            }
                          >
                            <input
                              onChange={() => {}}
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                              checked={
                                this.state[
                                  `${service.parent_id}-${service.child_id}`
                                ] === true
                              }
                              type="checkbox"
                            />
                            <span className="checkmark" />
                            {`${service.parent_name} / ${service.child_name}`}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    required
                    value={firstName}
                    name="firstName"
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    required
                    value={lastName}
                    name="lastName"
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Trade</label>
                  <Select
                    value={trade}
                    onChange={(e) => this.setState({ trade: e })}
                    options={tradeOptions}
                    isMulti
                    isOptionDisabled={() => trade.length >= 3}
                  />
                  {/* <input
                    type="text"
                    required
                    value={trade}
                    name="trade"
                    onChange={this.handleChange}
                    className="form-control"
                  /> */}
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    required
                    value={email}
                    name="email"
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Comment</label>
                  <textarea
                    rows="6"
                    value={comment}
                    name="comment"
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <label className="has-text-weight-normal is-size-7 has-text-danger has-text-right">
                    {1500 - comment.length} of 1500 characters
                  </label>
                </div>
                <div className="signup-btns">
                  <button className="signup-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(Endorsement);
