import React from 'react';

const InputSelect = ({ label, name, options, value, customValue, onChange }) => {
  return (
    <div className="mb-3">
      {label && <label htmlFor={name} className="form-label">{label}</label>}
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="form-control rounded-0"
      >
        <option value="">{customValue}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InputSelect;
