import React from 'react';

const Button = ({ label, onClick, type = 'button', variant = 'primary' }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`btn btn-${variant} me-2`}
      style={{width:'30%', backgroundColor:(variant == 'primary'?'#1a67a3':'#dc3545')}}
      
    >
      {label}
    </button>
  );
};

export default Button;
