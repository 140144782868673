import React, { useEffect, useState } from 'react'
import axios from "axios";
import { BASE_URL } from '../../../Constants/serverConfig';
import TreeTrades from './TreeTrades';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    LinkedinShareButton
} from "react-share";
import './team.css'
import NewTeam from './TeamsForm/NewTeam';
import NewMember from './TeamsForm/NewMember';

const DATA_TRADES = require('../../../Constants/dataSource.json');
function Teams({ token, showError, showSuccess, clearMessage, serviceProvider, saveServiceProvider }) {

    const [showPopup, setShowPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentTradeIndex, setCurrentTradeIndex] = useState(50);
    const [currentTradeValue, setCurrentTradeValue] = useState(50);
    const [teamsList, setTeamsList] = useState([]);
    const [teamsListToDelete, setTeamsListToDelete] = useState([]);
    const [tradePathSelected, setTradePathSelected] = useState();
    const [tradePathSelectedID, setTradePathSelectedID] = useState();
    const [viewMode, setViewMode] = useState(false);
    const [sharedMode, setSharedMode] = useState(false);
    const [addUserMode, setAddUserMode] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [teamType, setTeamType] = useState();

    const [newMemberName, setNewMemberName] = useState();
    const [newMemberEmail, setNewMemberEmail] = useState();
    const [currentFocus, setCurrentFocus] = useState(1);


    const [currentTeamID, setCurrentTeamID] = useState();

    useEffect(() => {

        getAllTeams();


    }, []);

    useEffect(() => {


        if (teamsList.length) {
            setCurrentStep(3);
            setShowPopup(false);
        } else {
            setCurrentStep(0);
            setShowPopup(true);
        }
    }, [teamsList]);


    const getAllTeams = () => {
        axios
            .get(`${BASE_URL}/teams`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                // console.log(response.data.data.length);

                // response.data.data =  response.data.data.length % 2 === 0 ? response.data.data.concat([
                //     {
                //         "id": 0,
                //         "created_at": "5/4/2024",
                //         "name": "dummy",
                //         "type": 1,
                //         "main_trade": "Plumbing ",
                //         "trade": "[]",
                //         "user_id": 0,
                //         "user_uuid": "dummy"
                //     }
                // ]) : response.data.data;

                setTeamsList(response.data.data)
            })
            .catch((error) => {

            });
    }

    const closePopup = () => {
        setShowPopup(false);
        setViewMode(false);
        setSharedMode(false);
        setAddUserMode(false);
        setCurrentStep(-1);
    }

    const closeViewPopup = () => {
        setShowPopup(false);
        setViewMode(false);
        setSharedMode(false);
        setAddUserMode(false);
        setTradePathSelected([]);
        setTradePathSelectedID(0);
        setCurrentStep(-1);
    };

    const handleShowPopup = () => {
        setCurrentStep(1);
        setShowPopup(true);
    };

    const handleTeamName = (teamName) => {
        setTeamName(teamName);
    };

    const handleNewMemberName = (memberName) => {
        setNewMemberName(memberName);
        setCurrentFocus(2);
    };

    const handleNewMemberEmail = (memberEmail) => {
        setNewMemberEmail(memberEmail);
        setCurrentFocus(1);
    };

    const onChange = (evt) => {
        setCurrentTradeIndex(evt.target.options[evt.target.selectedIndex].id)
        setCurrentTradeValue(evt.target.value)
    }

    const handleNextStep = () => {
        setCurrentStep(2);
    }

    const handleBackStep = () => {
        setCurrentStep(1);
    }

    const updateGroupSelection = (data) => {

        setTradePathSelected(data);
    }

    const saveNewTeam = () => {

        axios.post(`${BASE_URL}/teams`,
            {
                name: teamName,
                team_type: teamType,
                trade_name: DATA_TRADES[currentTradeIndex].name,
                trade_masterformat_code: DATA_TRADES[currentTradeIndex].masterformat_code,
                trade: JSON.stringify(tradePathSelected)
            }, {
            headers: {
                authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                getAllTeams();
                closeViewPopup();
            })
            .catch((error) => {

            });

    }

    const updateTeam = () => {

        axios.patch(`${BASE_URL}/teams/` + tradePathSelectedID,
            {
                name: teamName,
                trade: JSON.stringify(tradePathSelected)
            }, {
            headers: {
                authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                console.log(response);
                getAllTeams();
                closeViewPopup();
            })
            .catch((error) => {

            });

    }

    const addListToDelete = (evt, teamID) => {


        if (evt.target.checked) {
            setTeamsListToDelete([...teamsListToDelete, teamID.id]);
        } else {
            const updateList = teamsListToDelete.filter(item => item !== teamID.id);
            setTeamsListToDelete(updateList);
        }


    }

    const deleteTeams = (teamID) => {
        axios.delete(`${BASE_URL}/teams`,
            {
                headers: {
                    authorization: `Bearer ${token}`
                },
                data: {

                    id: [teamID]

                }
            })
            .then((response) => {
                setShowDeletePopup(false);
                getAllTeams();

            })
            .catch((error) => {

            });

    }

    const addNewUser = (teamID) => {
        axios.post(`${BASE_URL}/searcher/team/request`,
            {

                guest_first_name: newMemberName,
                guest_last_name: "",
                guest_email_address: newMemberEmail,
                team_id: teamID

            }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        )
            .then((response) => {
                getAllTeams();

            })
            .catch((error) => {

            });

    }

    const showTradePath = (data, name, id) => {


        setTradePathSelected(data);
        setTradePathSelectedID(id);

        setSharedMode(false);
        setAddUserMode(false);

        setTeamName(name);
        setViewMode(true);
        setShowPopup(true);

    }

    const showSharedPanel = () => {

        setViewMode(false)
        setAddUserMode(false);

        setSharedMode(true);
        setShowPopup(true);
    }

    const showAddPanel = (teamID) => {

        setViewMode(false)
        setSharedMode(false);
        setCurrentTeamID(teamID);
        setAddUserMode(true);
        setShowPopup(true);
    }

    const StepOne = () => {
        return (
            <div className="step-one form-group">
                <h5 className='mt-3 mb-2'>Team Name</h5>
                <p style={{ textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                {/* <input key={'teamNameInput'} className="form-control"  value={teamName} onChange={handleTeamName}></input> */}
                <NewTeam value={teamName} onChange={handleTeamName}></NewTeam>
                <h5 className='mt-3 mb-2'>Construction Trade</h5>
                <p style={{ textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                </p>
                <select className="form-control" onChange={onChange} value={currentTradeValue}>
                    {/* Mapea el array de opciones y crea un elemento option por cada elemento */}
                    <option id={50} value={50} disabled selected>Choose an option</option>
                    {DATA_TRADES.map((option, index) => (
                        <option key={index} id={index} value={option.path}>
                            {option.name}
                        </option>
                    ))}
                </select>
                <h5 className='mt-3 mb-2'>Team Type</h5>
                <p style={{ textAlign: 'justify' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div className='d-flex flex-row'>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="teamType" id="teamType1" checked={teamType === 1} onChange={() => setTeamType(1)} />
                        <label className="form-check-label" htmlFor="teamType1">
                            Laborer
                        </label>
                    </div>
                    <div className="form-check mx-2">
                        <input className="form-check-input" type="radio" name="teamType" id="teamType2" checked={teamType === 2} onChange={() => setTeamType(2)} />
                        <label className="form-check-label" htmlFor="teamType2">
                            Foreman
                        </label>
                    </div>
                </div>

            </div>
        )
    }

    const StepZero = () => {
        return (
            <div>
                <p style={{ textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <div className="map-buton ">
                    <button
                        type="button"
                        onClick={handleShowPopup}
                        className="btn-fill"
                    >
                        Create My Team
                    </button>
                </div>
            </div>
        )
    }
    const StepAddUser = () => {
        return (
            // <div className='px-2 mt-4 mt-md-2 ' >
            //     <h5 className='mt-4'>Add members</h5>
            //     <span className='d-none d-md-block' style={{ fontSize: "20px" }}>You can invite multiple members to this team</span>
            //     <p className='d-block d-md-none'>You can invite multiple members to this team</p>
            //     <form className="row g-3 mt-3 mb-3">
            //         <div className="col-12 col-md-12">
            //             <label htmlFor="inputName" className="visually-hidden" >Name</label>
            //             <input key="newMemberName" type="text" className="form-control" id="inputName" placeholder="Name" value={newMemberName} onChange={(e)=>setNewMemberName(e.target.value)} />
            //         </div>

            //         <div className="col-12 col-md-12">
            //             <label htmlFor="inputEmail" className="visually-hidden">Email</label>
            //             <input key="newMemberEmail" type="email" className="form-control" id="inputEmail" placeholder="email" value={newMemberEmail} onChange={(e)=>setNewMemberEmail(e.target.value)}/>
            //         </div>
            //     </form>

            // </div>
            <NewMember
                handleNewMemberName={handleNewMemberName}
                handleNewMemberEmail={handleNewMemberEmail}
                valueName={newMemberName}
                valueEmail={newMemberEmail}
                currentFocus={currentFocus}
            />
        )
    }

    return (
        <React.Fragment>
            <div className={`modal delete-popup ` + (showDeletePopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className="modal-card ">
                    <section className="modal-card-body home-modal delete-modal-body">
                        <h2>Delete Team?</h2>
                        <p>The team and all its members will be removed</p>
                        <div className='div-buttons-modal'>
                            <button onClick={() => deleteTeams(currentTeamID)}>Confirm</button>
                            <button onClick={() => setShowDeletePopup(false)}>Cancel</button>
                        </div>
                    </section>
                </div>
            </div>

            <div className={`modal small-popup ` + (showPopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className="modal-card ">
                    <section className="modal-card-body home-modal">
                        <div className="endos_panel2 clearfix">

                            {currentStep == 0 &&
                                <StepZero></StepZero>
                            }
                            {currentStep == 1 &&
                                <StepOne></StepOne>
                            }
                            {currentStep == 2 &&
                                <TreeTrades data={[DATA_TRADES[currentTradeIndex]]} saveGroup={updateGroupSelection}></TreeTrades>
                            }
                            {viewMode &&
                                <TreeTrades data={typeof tradePathSelected === 'object' ? tradePathSelected : JSON.parse(tradePathSelected)} saveGroup={updateGroupSelection}></TreeTrades>
                            }
                            {sharedMode &&
                                <div className='shared-buttons-group'>
                                    <div className='shared-tittle'>
                                        <h5>PropAgile</h5>
                                        <i className="bi bi-link">&nbsp;Copy Link</i>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt maximus lobortis. Aenean vel luctus diam, eget suscipit metus. Cras non suscipit nisi, fermentum imperdiet lacus.
                                    </p>
                                    <div className='shared-body'>
                                        <WhatsappShareButton
                                            url={window.location.href}
                                            title="Click to see my digital profile"

                                        >
                                            <i className="fab fa-whatsapp" />
                                        </WhatsappShareButton>
                                        <TelegramShareButton
                                            url={window.location.href}
                                            title="Click to see my digital profile"

                                        >
                                            <i className="fab fa-telegram" />
                                        </TelegramShareButton>

                                        <LinkedinShareButton
                                            url={window.location.href}
                                            title={"Click to see my digital profile"}

                                        >
                                            <i className="fab fa-linkedin" />
                                        </LinkedinShareButton>
                                    </div>
                                    <div className='shared-body'>
                                        <FacebookShareButton
                                            url={window.location.href}
                                            quote={"Click to see my digital profile"}

                                        >
                                            <i className="fab fa-facebook-f" />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={window.location.href}
                                            title={"Click to see my digital profile"}

                                        >
                                            <i className="bi bi-twitter-x" />
                                        </TwitterShareButton>
                                        <EmailShareButton
                                            url={window.location.href}
                                            subject={"Click to see my digital profile"}

                                        >
                                            <i className="fa fa-envelope" />
                                        </EmailShareButton>
                                    </div>
                                </div>
                            }
                            {addUserMode &&
                                <StepAddUser></StepAddUser>
                            }

                            <br />

                            <div className="map-buton ">
                                {currentStep == 1 &&
                                    <>

                                        <button
                                            type="button"
                                            onClick={handleNextStep}
                                            className="btn-fill"
                                            disabled={currentTradeIndex == 50 ? true : false}
                                        >
                                            Next
                                        </button>
                                        <button
                                            type="button"
                                            onClick={closePopup}
                                            className="btn-fill exit-trade-modal"

                                        >
                                            Exit
                                        </button>
                                    </>
                                }
                                {currentStep == 2 &&
                                    <><button
                                        type="button"
                                        onClick={handleBackStep}
                                        className="btn-fill"
                                    >
                                        Back
                                    </button>
                                        <button
                                            type="button"
                                            onClick={saveNewTeam}
                                            className="btn-fill"
                                        >
                                            Save
                                        </button>
                                    </>
                                }
                                {viewMode &&
                                    <>

                                        <button
                                            type="button"
                                            onClick={updateTeam}
                                            className="btn-fill"
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="button"
                                            onClick={closeViewPopup}
                                            className="btn-fill"
                                        >
                                            Exit
                                        </button>
                                    </>
                                }
                                {sharedMode &&
                                    <><button
                                        type="button"
                                        onClick={closeViewPopup}
                                        className="btn-fill exit-trade-modal"
                                    >
                                        Exit
                                    </button>

                                    </>
                                }
                                {addUserMode &&
                                    <>
                                        <button
                                            type="button"
                                            onClick={() => addNewUser(currentTeamID)}
                                            className="btn-fill"
                                        >
                                            Send
                                        </button>
                                        <button
                                            type="button"
                                            onClick={closeViewPopup}
                                            className="btn-fill exit-trade-modal"
                                        >
                                            Exit
                                        </button>


                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className='services_main_body'>
                <div className="container">
                    <div className="title-module" style={{ marginBottom: "15px" }}>
                        {`Teams`}
                    </div>
                    <div className='d-flex flex-row justify-content-between title-panel'>
                        <h3 className='my-3'></h3>
                        <div className=''>
                            {/* <button className='btn btn-danger my-3' onClick={deleteTeams}>Remove Team</button> */}
                            <span onClick={() => { setCurrentStep(1); setShowPopup(true); }}><i className="bi bi-plus-circle" > New Team</i></span>
                            {/* <button className='btn btn-primary my-3' onClick={() => { setCurrentStep(1); setShowPopup(true); }}>New Team</button> */}
                        </div>

                    </div>

                    {teamsList.length == 0 &&
                        <StepZero></StepZero>
                    }




                    <div className={`row-card-list ${teamsList.length > 3 ? "" : "no-scroll"}`}>

                        {teamsList.length > 0 &&
                            <>
                                {teamsList.map((item, index) => {
                                    return (
                                        <div className={`card-license-info team-card `} onClick={() => true}>
                                            {/* <th scope="row">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" onClick={(e) => { addListToDelete(e, item) }} id="defaultCheck1" />
                                                </div>
                                            </th> */}
                                            <div className="team-item">
                                                <span>Created</span>
                                                <span>{item.created_at}</span>
                                            </div>
                                            <div className="team-item">
                                                <span>Name</span>
                                                <span>{item.name}</span>
                                            </div>
                                            <div className="team-item">
                                                <span>Type</span>
                                                <span>{item.type == 1 ? "Laborer" : "Foreman"}</span>
                                            </div>
                                            <div className="team-item">
                                                <span>Trade</span>
                                                <span>{item.main_trade}</span>
                                            </div>

                                            <div className="team-item">

                                                {/* <i className="fa fa-trash" style={{marginRight:"10px", cursor:"pointer"}} onClick={() => { deleteTeams(item) }}></i>
                                                <i className="fas fa-eye" ></i> */}
                                                <button style={{ backgroundColor: "unset", border: "0px" }} onClick={() => showAddPanel(item.id)}>
                                                    {/* <i className="bi bi-person-plus-fill" aria-hidden="true"></i> */}
                                                    <img src="../images/addUser40.png" width="30" />
                                                </button>
                                                {/* <button style={{ backgroundColor: "unset", border: "0px" }} onClick={() => showSharedPanel()}><i className="fa fa-share" aria-hidden="true"></i></button> */}
                                                <button style={{ backgroundColor: "unset", border: "0px" }} onClick={() => showTradePath(item.trade, item.name, item.id)}>
                                                    {/* <i className="bi bi-pencil-square"></i> */}
                                                    <img src="../images/edit40.png" width="30" />
                                                </button>
                                                <button style={{ backgroundColor: "unset", border: "0px" }} onClick={() => { setShowDeletePopup(true); setCurrentTeamID(item.id) }}>
                                                    {/* <i className="fa fa-trash" ></i> */}
                                                    <img src="../images/remove40.png" width="30" />
                                                </button>

                                            </div>
                                        </div>
                                    )
                                })}
                            </>

                        }
                    </div>
                    {/* <button onClick={() => { setShowPopup(true); }}>
                        Show tree
                    </button> */}

                </div>
            </div>
        </React.Fragment >
    )
}

export default Teams