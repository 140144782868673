import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import TreeTrades from './TreeTrades';
import TreeSupplementary from './TreeSupplementary';
import { BASE_URL } from "../../../Constants/serverConfig";
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import './index.css'
import axios from 'axios';
import Loader from '../../Loader/loader';
import { isMobile } from 'react-device-detect';
import MembeshipItems from './MembeshipItems';
import ModalEntrance from './ModalEntrance';
import InteractivePanel from '../InteractivePanel';
const DATA_TRADES = require('../../../Constants/dataSourceTotal.json');
const DATA_SUPPLEMENTARY = require('../../../Constants/supplementaryDefaultSA00.json');
const DATA_CHECK = require('../../../Constants/checkList.json');

function WorkFlowOrganism(props) {


    let initialProjectData = {
        name: props.data?.name ?? "",
        zip_code: props.data?.zip_code ?? "",
        work_type: props.data?.work_type ?? "",
        main_building_use: props.data?.main_building_use ?? "",
        gross_area: props.data?.gross_area ?? "",
        id: props.data?.id ?? "",
        uuid: props.data?.uuid ?? "",
        project_item_selection: []
    }
    const [showPopup, setShowPopup] = useState(false);
    const [messageEntrance, setMessageEntrance] = useState("");
    const [index, setIndex] = useState(0);

    const [showWorkFlowForm, setShowWorkFlowForm] = useState(false);
    const [showWorkFlowButtons, setShowWorkFlowButtons] = useState(true);
    const [currentPhase, setCurrentPhase] = useState(0);

    const [showCheckList, setShowCheckList] = useState(false);
    const [showFrameworkPanel, setShowFrameworkPanel] = useState(false);
    const [showIntroPanel, setShowIntroPanel] = useState(true);
    const [currentPanel, setCurrentPanel] = useState(0);
    const [checkedData, setCheckedData] = useState([]);

    const [showProjectDetails, setShowProjectDetails] = useState(true);//
    const [showProjectDetailsItems, setShowProjectDetailsItems] = useState(true);
    const [showProjectWorkflow, setShowProjectWorkflow] = useState(false);
    const [showProjectWorkflowNotification, setShowProjectWorkflowNotification] = useState(false);

    const [showSupplementaryModal, setShowSupplementaryModal] = useState(false);
    const [showInteractiveModal, setShowInteractiveModal] = useState(false);
    const [showSupplementaryPopup, setShowSupplementaryPopup] = useState(false);

    const [showItemDetails, setShowItemDetails] = useState(false);
    const [itemDetails, setItemDetails] = useState();
    const [activeAlert, setActiveAlert] = useState(false);

    const [pathList, setPathList] = useState(props.pathFramework ?? []);
    const [supplementaryList, setSupplementaryList] = useState(props.pathSupplementary ?? []);
    const [pathSupplementaryList, setPathSupplementaryList] = useState([]);
    const [supplementaryArray, setSupplementaryArray] = useState([]);
    const [projectId, setProjectId] = useState(props.data?.id || 0);
    const [lastPath, setLastPath] = useState([]);
    const [lastItemName, setLastItemName] = useState("");

    const [showModalEntrance, setShowModalEntrance] = useState(false);
    const [showStripeWall, setShowStripeWall] = useState(false);
    const [showSubscriptionWall, setShowSubscriptionWall] = useState(false);


    const [projectInformation, setProjectInformation] = useState(initialProjectData)

    const [schematicDesignSelected, setSchematicDesignSelected] = useState([]);

    const [workflow, setWorkflow] = useState(JSON.parse(JSON.stringify(DATA_TRADES)));
    const [checkData, setCheckData] = useState([]);
    const [supplementary, setSupplementary] = useState([]);

    const [editMode, setEditMode] = useState(false);

    const [learnMore, setLearnMore] = useState(false);

    const [tooltipDescriptionShow, setTooltipDescriptionShow] = useState(false);
    const target = useRef(null);

    const [showLoader, setShowLoader] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);
    const targetRefTool = React.useRef(null);


    const targetRef = useRef(null);
    const { subscription } = props;
    let history = useHistory();


    useEffect(() => {
                
        if (subscription.plan === 'no-plan') {
           let project = JSON.parse(sessionStorage.getItem('workflow_project'));
           setProjectInformation(project);
           setPathList(JSON.parse(project.project_item_selection));
           setWorkflow(checkedItemTree(JSON.parse(project.project_item_selection)));         
        }
    }, []);

    useEffect(() => {
        if (subscription.plan === 'no-plan') {
            console.log(projectInformation);       
        }
    }, [projectInformation]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTooltip(false);
        }, 10000);

        return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
    }, []);

    useEffect(() => {

        /**update supplementary array */

        let auxSupplementaryArray = [];
        supplementaryList.map(el => {
            auxSupplementaryArray.push({
                path: el.parent_path,
                list: JSON.parse(el.supplementary_path)
            });
        })
        setSupplementaryArray(auxSupplementaryArray);

        if (!props.isChildren) {
            history.push(`/workflow?s_id=${props.uuid}&check=true`)
            setProjectInformation(prevState => ({
                ...prevState,
                uuid: props.uuid
            }));
        }

        if (props.showFrameworkPanel) {
            setShowFrameworkPanel(true)
            setShowIntroPanel(false)
            setCurrentPanel(1);
        }
        setShowPopup(true);

        //const data = JSON.parse(JSON.stringify(DATA_TRADES));
        const check = JSON.parse(JSON.stringify(DATA_CHECK));


        //setWorkflow(data);
        setCheckData(check);

        if (showProjectWorkflow) {
            setShowProjectDetailsItems(false);
        }
        updateCurrentPanel(2)
        //editWorkflow(); setShowProjectDetailsItems(false); setShowProjectWorkflow(true)
    }, [])

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const check = queryParameters.get("check");

        if (check) {
            setShowCheckList(true);
        }
    }, [])

    useEffect(() => {

        let data = JSON.parse(JSON.stringify(DATA_SUPPLEMENTARY));
        setSupplementary(data);

    }, [showSupplementaryModal])

    useEffect(() => {
        if (supplementary.length) {
            setSupplementary(checkedItemSupplementaryTree(pathSupplementaryList));
        }
    }, [supplementary])

    useEffect(() => {
        console.log('jeee', pathSupplementaryList);

    }, [pathSupplementaryList])

    useEffect(() => {

        if (showProjectWorkflow) { editWorkflow() }

    }, [showProjectWorkflow])


    useEffect(() => {
        let data = require('../../../Constants/dataSourceTotal.json');

        if (editMode) {
            setCurrentPhase(3);
            setWorkflow(checkedItemTree(pathList));
            setShowWorkFlowButtons(false);
            //setShowProjectDetails(false);
            setShowWorkFlowForm(true)
        }


    }, [editMode]);

    useEffect(() => {

        console.log("pathArray", supplementaryArray);

    }, [supplementaryArray]);

    useEffect(() => {

        if (lastPath.length) {

            let path = JSON.stringify(lastPath[0].split("-").map(Number));
            let comparePath = JSON.stringify(lastPath);

            /**evitar duplicar el path */
            const pathExists = supplementaryArray.findIndex(pathObj => pathObj.path === JSON.stringify(lastPath));
            console.log(pathExists);

            if (pathExists < 0) {
                // Si el path no existe, agregarlo al array 
                setSupplementaryArray([...supplementaryArray, { path: JSON.stringify(lastPath), list: [] }]);
            } else {
                setPathSupplementaryList(supplementaryArray?.[pathExists]?.list || []);
                console.log(supplementaryArray?.[pathExists]?.list || []);

            }
            /** */

            const foundSupplementaryPath = supplementaryList.find(item => item.parent_path === comparePath);
            console.log(foundSupplementaryPath);

            if (foundSupplementaryPath) {
                let data = JSON.parse(JSON.stringify(DATA_SUPPLEMENTARY));
                setSupplementary(data);
                setPathSupplementaryList(supplementaryArray?.[pathExists]?.list || JSON.parse(foundSupplementaryPath.supplementary_path));
                console.log('db', foundSupplementaryPath.supplementary_path);

            } else {
                let data = JSON.parse(JSON.stringify(DATA_SUPPLEMENTARY));
                setSupplementary(data);
                //setPathSupplementaryList([]);
                console.log('no db');

            }

        }

    }, [lastPath])

    const handleSupplementaryModal = () => {

        setShowSupplementaryModal(true);
        setShowSupplementaryPopup(true);
    }

    const scrollNotificationProject = () => {
        if (isMobile) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const handleLastPath = (path, item) => {

        setLastPath([path]);
        setLastItemName(item.name);
    }

    const getCheckedItems = (data) => {
        setCheckedData(data)
    }

    const editWorkflow = () => {
        setWorkflow(checkedItemTree(pathList));
    }



    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const handleFormInput = (evt) => {

        const { name, value } = evt.target;

        setProjectInformation(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const startWorkFlowHandled = () => {
        setShowWorkFlowForm(true);
        setCurrentPhase(1);


    }

    const validateForm = (type = '') => {

        if (projectInformation.zip_code === "" || projectInformation.gross_area === "" || projectInformation.work_type === "" || projectInformation.main_building_use === "") {
            setActiveAlert(true);
        } else {

            if (type == "PRE-CONSTRUCTION") {
                //setCurrentPhase(3);
                // setShowIntroPanel(false);
                // setShowFrameworkPanel(true);
                // setCurrentPanel(2);
                saveWorkFlowStatus();
                setShowPopup(false);
                updateCurrentPanel(2)
            }
            else if (type == "PRE-DEVELOPMENT") {
                // setShowIntroPanel(false);
                // setShowPopup(false);
                // setShowFrameworkPanel(true);
                // setCurrentPanel(1);
                setShowPopup(false);
                updateCurrentPanel(1)
            }
        }
        setSchematicDesignSelected([1, 2, 3]);



    }

    const updateCurrentPanel = (id = 0) => {
        setCurrentPanel(id);
        if (id == 1) {
            setShowProjectDetails(false);
            setShowProjectDetailsItems(false);
            setShowIntroPanel(false);
            setShowProjectWorkflow(false)
            setShowFrameworkPanel(true);

        }
        if (id == 2) {
            setShowProjectDetails(true);
            setShowProjectDetailsItems(false); //cambiar
            setShowIntroPanel(true);
            setShowFrameworkPanel(false);
            setShowProjectWorkflow(true)
        }
    }

    const showProjectDescriptionModal = () => {
        setCurrentPhase(1);
        setShowWorkFlowButtons(false);
        setShowProjectDetails(true);
        setShowPopup(true);
        setShowWorkFlowForm(true);
        setLearnMore(false);
    }

    const saveWorkFlowStatus = () => {

        let body = {
            zip_code: projectInformation.zip_code,
            work_type: projectInformation.work_type,
            main_building_use: projectInformation.main_building_use,
            session_id: props.uuid,
            schematic_design_selected: JSON.stringify(schematicDesignSelected)
        }
        return true;
        axios
            .post(`${BASE_URL}/searcher/workflow-project`, body, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(response => {
                console.log(response);
            }).catch(err => {
                console.log(err);
            })
    }



    const ItemInformation = () => {
        return (
            <div>
                {itemDetails}
            </div>
        )
    }

    const updateItemsDetails = (data) => {
        setItemDetails(data);
    }

    const checkedItemTree = (paths) => {

        for (const [index, path] of paths.entries()) {

            let auxDataTrade = { ...workflow[0].children };
            //  console.log(path);
            //let path = [0,1];
            for (let i = 0; i < path.length; i++) {
                const pos = path[i];
                if ((i + 1) != path.length) {
                    auxDataTrade[pos].visible = true;
                }
                if (!auxDataTrade[pos]) {
                    auxDataTrade[pos] = { children: [] };
                }
                if (i === path.length - 1) {
                    auxDataTrade[pos].checked = true;
                } else {
                    auxDataTrade = auxDataTrade[pos].children;
                }
            }
        }


        return workflow;
    }

    const checkedItemSupplementaryTree = (paths) => {

        for (const [index, path] of paths.entries()) {

            let auxDataTrade = { ...supplementary[0].children };
            //  console.log(path);
            //let path = [0,1];
            for (let i = 0; i < path.length; i++) {
                const pos = path[i];
                if ((i + 1) != path.length) {
                    auxDataTrade[pos].visible = true;
                }
                if (!auxDataTrade[pos]) {
                    auxDataTrade[pos] = { children: [] };
                }
                if (i === path.length - 1) {
                    auxDataTrade[pos].checked = true;
                } else {
                    auxDataTrade = auxDataTrade[pos].children;
                }
            }
        }


        return supplementary;
    }

    const saveGroup = (data, pathArray) => {


        if (pathArray) {
            setPathList(pathArray);
            setProjectInformation(prevState => ({
                ...prevState,
                project_item_selection: pathArray
            }));
        }

    }

    const saveSupplementaryGroup = (data, pathArray) => {

        //      

        //fin

        if (pathArray) {
            //setPathSupplementaryList(prevState => [...prevState, ...pathArray]);
            setPathSupplementaryList(pathArray);
        }
    }

    const saveSupplementaryList = () => {
        setSupplementaryArray((prevData) =>
            prevData.map((item) =>
                item.path === JSON.stringify(lastPath)
                    ? { ...item, list: pathSupplementaryList }
                    : item
            )
        );
    }

    const submitDownloadCheckList = () => {
        setShowLoader(true);
        axios.post(`${BASE_URL}/project/generate-checklist-file`,
            {
                checked_data: JSON.stringify(checkedData),
                uuid: props.uuid

            })
            .then((response) => {

                if (response.status === 200) {
                    axios.get(BASE_URL + '/public/project/download-report/' + props.uuid, {
                        responseType: 'blob'// Configurar responseType como blob para manejar archivos binario
                    }).then(response => {

                        const url = window.URL.createObjectURL(new Blob([response.data]));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', props.uuid + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        setShowLoader(false);
                    }).catch(err => {
                        console.log(err);
                    });
                }

            })
            .catch((error) => {

            });
    }

    const submitDownloadFramework = () => {
        setShowLoader(true)
        axios.post(`${BASE_URL}/project/report`,
            {
                framework_data: JSON.stringify(pathList),
                uuid: props.uuid

            })
            .then((response) => {

                if (response.status === 200) {
                    axios.get(BASE_URL + '/public/project/download-report/' + props.uuid, {
                        responseType: 'blob'// Configurar responseType como blob para manejar archivos binario
                    }).then(response => {

                        const url = window.URL.createObjectURL(new Blob([response.data]));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', props.uuid + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        setShowLoader(false);
                    }).catch(err => {
                        console.log(err);
                    });
                }

            })
            .catch((error) => {

            });

    }

    const updateAllFramework = () => {
        if (props.isChildren) {

            

            let auxProject = projectInformation;
            auxProject.project_item_selection = JSON.stringify(pathList);                          
            if (subscription.plan === 'no-plan') {                
               sessionStorage.setItem('workflow_project', JSON.stringify(auxProject));
                return;
            }
            let bodyData = {
                project_data: auxProject,
                project_item_selection: pathList,
                project_supplementary_path: pathSupplementaryList,
                project_parent_supplementary_path: lastPath,
                project_supplementary_array: supplementaryArray
            }

            props.editProject(projectInformation.id, bodyData);
        } else {
            setShowProjectDetails(true)
            setShowProjectDetailsItems(true)
            setShowProjectWorkflow(false)
        }
    }

    const handleSupplementary = (item, checked) => {

        let path = item.split("-").map(Number);
        setSupplementary(checkedItemSupplementaryTree([path]));
    }


    const handleCheckedChange = (path, checked) => {

        const updateCheckedStatus = (node) => {
            console.log(node.path === path);

            if (node.path === path) {
                return { ...node, checked };
            }
            if (node.children) {
                return {
                    ...node,
                    children: node.children.map(updateCheckedStatus)
                };
            }
            return node;
        };

        setSupplementary(updateCheckedStatus(supplementary));
    };

    const handleProjectPhaseInput = (e) => {
        updateCurrentPanel(e.target.value)
    }

    const showSubscriptionsOptions = () => {
        setShowSubscriptionWall(true);
    }

    const handleManageModalEntrance = (toStripe) => {
        setShowSubscriptionWall(false);
        setShowModalEntrance(true);
        setShowStripeWall(toStripe);

    }

    const closeInteractiveModal = () => {
        setShowInteractiveModal(false);
    }

    const saveInteractiveModal = () => {
        setShowInteractiveModal(false);
    }

    const downloadInteractiveModal = () => {
        setShowInteractiveModal(false);
    }



    return (

        <div className={'workflow-container-organic ' + (props.isChildren ? 'children' : '')}>
            <Loader text="Loading..." open={showLoader} />

            <div className={`modal small-popup ` + (showInteractiveModal ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className={"modal-card"}>
                    <section className={"modal-card-body-interactive"}>
                        {workflow.length &&
                            <InteractivePanel propsData={workflow[0]} close={closeInteractiveModal} save={saveInteractiveModal} download={downloadInteractiveModal} type={'show'} format={'wbs'} />
                        }
                    </section>
                    {/* <div className='cont-supplementary-button bg-light text-center'>
                        <button className="btn btn-primary" onClick={() => saveSupplementaryList()}>Save</button>
                        <button className="btn btn-danger" onClick={() => setShowSupplementaryPopup(false)}>Close</button>
                    </div> */}
                </div>
            </div>


            <div className={`modal small-popup ` + (showSupplementaryPopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className={"modal-card"}>
                    <section className={"modal-card-body"}>
                        {supplementary.length &&
                            <TreeSupplementary
                                data={[supplementary[0]]}
                                handleSupplementary={handleSupplementary}
                                pathArray={pathSupplementaryList}
                                saveGroup={saveSupplementaryGroup}
                            ></TreeSupplementary>
                        }

                    </section>
                    <div className='cont-supplementary-button bg-light text-center'>
                        <button className="btn btn-primary" onClick={() => saveSupplementaryList()}>Save</button>
                        <button className="btn btn-danger" onClick={() => setShowSupplementaryPopup(false)}>Close</button>
                    </div>
                </div>
            </div>


            <div className={'workflow-body ' + (props.isChildren ? 'children' : '')}>
                <>
                    {showIntroPanel &&
                        <>
                            {showProjectDetails &&

                                <div className='workflow-details'>
                                    {showProjectWorkflow &&
                                        <div className='cont-workflow-organic w-100'>
                                            <div ref={targetRef} className={'node-notification-organic ' + (showProjectWorkflowNotification ? 'active' : 'inactive')}>
                                                <div>
                                                    <p>{lastItemName} may include a supplementary default <span>SUP</span> and / or Include Default <span>INC</span>.  Click the icons to select it</p>
                                                </div>
                                            </div>
                                            <form className='workflow-details-form-organic'>
                                                {/* <h3>Click to Customize Your Project’s <br></br>Construction Framework</h3>
                                                <p>Start by selecting building components, proceed to types, then assign materials; select to add to your framework, To define how work should be packaged, and assign parameters to any selection.</p>
                                                <p className='phase-3-span'>(<span style={{ color: '#1a67a3', cursor: 'pointer', fontWeight: '600' }}>Based on Uniformat / Masterformat connected</span>)</p> */}
                                                <TreeTrades
                                                    data={[workflow[0]]}
                                                    saveGroup={saveGroup}
                                                    pathArray={pathList}
                                                    handleLastPath={handleLastPath}
                                                    type={'SCHEMATIC'}
                                                    showDescription={() => setShowItemDetails(true)}
                                                    itemDetails={updateItemsDetails}
                                                    paidMembership={props.isChildren ? true : false}
                                                    setShowProjectWorkflowNotification={setShowProjectWorkflowNotification}
                                                    showSupplementaryModal={handleSupplementaryModal}
                                                    scrollNotificationProject={scrollNotificationProject}
                                                ></TreeTrades>
                                            </form>
                                            <div className='workflow-details-form-right-organic bg-light'>
                                                <div className='d-flex flex-column justify-content-center align-items-center w-100 '>
                                                    {props.isChildren &&
                                                        <>
                                                            <div className='w-100 d-none d-md-block'><h6 style={{ color: 'gray', fontSize: '14px' }} >Select all that apply and export excel to your favorite management tool</h6></div>
                                                            <div className='d-none d-md-flex flex-row w-100 justify-content-between'>

                                                                <button className='btn btn-primary mx-2' style={{ width: '15vw', height: '5vh', borderRadius: '0px', backgroundColor: '#1a67a3', fontFamily: 'Gotham, sans-serif' }} onClick={() => { updateAllFramework() }}>Save Changes</button>

                                                                <div className='d-flex flex-row'>
                                                                    {props.token &&
                                                                        <button className='d-none d-md-block btn btn-primary'
                                                                            style={{ width: '15vw', height: '5vh', borderRadius: '0px', backgroundColor: '#ffc107', borderColor: '#ffc107', color: 'black', fontFamily: 'Gotham, sans-serif' }}
                                                                            onClick={() => { setShowInteractiveModal(true) }} disabled={workflow.length ? false : true}>Table Of Content</button>
                                                                    }
                                                                    <button className='btn btn-primary mx-1'
                                                                        style={{ width: '15vw', height: '5vh', borderRadius: '0px', backgroundColor: '#ffc107', borderColor: '#ffc107', color: 'black', fontFamily: 'Gotham, sans-serif' }}
                                                                        onClick={() => { submitDownloadFramework() }}>Download WBS</button>
                                                                </div>
                                                            </div>

                                                            <div className='d-flex d-md-none flex-row w-100 justify-content-between mt-2'
                                                                style={{
                                                                    position: "fixed",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    width: "100%",
                                                                    backgroundColor: "#f8f9fa",
                                                                    padding: "0px",
                                                                    display: "flex",
                                                                    justifyContent: "space-around",
                                                                    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                                                                }}
                                                            >
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    show={showTooltip}
                                                                    target={targetRefTool.current}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top" className='tooltip-workflow'>
                                                                            Select all that apply and export excel to your favorite management tool.{' '}
                                                                            <Button
                                                                                variant="link"
                                                                                className="text-light p-0 btn-tooltip-framework"
                                                                                onClick={() => setShowTooltip(false)}
                                                                            >
                                                                                <strong>x</strong>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className='d-flex d-md-none flex-row w-100 justify-content-between' ref={targetRef}>
                                                                        <button className='btn btn-primary' style={{ width: '100%', height: '5vh', borderRadius: '0px' }} onClick={() => { subscription.plan === 'no-plan' ? props?.handleShowSubscriptionsWall() : updateAllFramework() }}>Save Changes</button>

                                                                        <button className='btn btn-primary'
                                                                            style={{ width: '100%', height: '5vh', borderRadius: '0px', backgroundColor: '#ffc107', borderColor: '#ffc107', color: 'black' }}
                                                                            onClick={() => { submitDownloadFramework() }}>Download WBS</button>
                                                                    </div>
                                                                </OverlayTrigger>


                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    {/* {!props.isChildren &&
                                        <div className='workflow-controls'>
                                            <button type="button" className='btn-workflow-new' onClick={() => { setShowModalEntrance(true); setMessageEntrance('If you want to register multiple projects, you must sign up or sign in.') }} ><i className="bi bi-file-earmark-plus-fill"></i> New project</button>
                                            <button type="button" className='btn-workflow-save' onClick={() => { setShowModalEntrance(true); setMessageEntrance('') }}><i className="bi bi-floppy"></i> Save</button>
                                        </div>
                                    } */}
                                    {/* {showProjectDetailsItems &&
                                        <div className='workflow-controls'>
                                            <button type="button" className='btn-workflow-download' onClick={() => submitDownloadCheckList()}><i className="bi bi-file-earmark-spreadsheet"></i>Download Checklist</button>
                                            <button type="button" className='btn-workflow-download' onClick={() => submitDownloadFramework()} ><i className="bi bi-file-earmark-spreadsheet"></i>Download Framework</button>
                                            <button type="button" className='btn-workflow-download' onClick={() => { setShowModalEntrance(true); setMessageEntrance('') }} data-toggle="tooltip" data-placement="top" title="Under Construction" disabled={true}><i className="bi bi-file-earmark-spreadsheet" ></i>Download Packages</button>
                                        </div>
                                    } */}
                                </div>


                            }
                        </>
                    }

                </>
            </div>

        </div >
    )
}

export default WorkFlowOrganism