import React, { useState, useEffect } from 'react';
import FrameworkList from './FrameworkList';
import FrameworkHeader from '../FrameworkHeader';
import Modal from 'react-bootstrap/Modal';
import InteractivePanel from '../InteractivePanel';
import { checkFrameworkItems, checkFrameworkItemsMultiple, checkFrameworkItemsMultipleParents } from '../../../Helpers/CheckTreeHelper';
import InputText from '../../Atoms/InputText';
const frameworkData = require('../../../Constants/frameworks/feasibility.json');

function ModalPhase({ active, close, save, download, data, type }) {
    return (
        <>
            <Modal size="lg" className="modal-framework" show={active} onHide={close} backdrop="static" centered>
                <Modal.Header  >
                </Modal.Header>
                <Modal.Body className='tour-select-framework-items'>
                    <InteractivePanel propsData={data} close={close} save={save} download={download} type={type} />
                </Modal.Body>
            </Modal>
        </>
    )
}
function ModalConfirmRemove({ active, remove, close, setValue, value, alert }) {
    return (
        <>
            <Modal size="lg" className="modal-framework" show={active} onHide={close} backdrop="static" centered>
                <Modal.Header  >
                </Modal.Header>
                <Modal.Body className=''>
                    <div className='delete-text'>
                        <img src="../images/warningAlert.png" width={300}></img>
                        <h4>Are you sure about deleting this framework? It's permanent.</h4>
                        <span>You must enter your password to confirm.</span>
                        {alert &&
                            <div className='alert alert-danger'>
                                <span>{alert}</span>
                            </div>
                        }
                        <div className='d-flex flex-row justify-content-center w-100 mt-3 input-modal-remove'>
                            <InputText label={""} name={"Password"} type={'password'} value={value} onChange={(e) => { setValue(e) }} placeholder={"Enter Password Account"} />
                        </div>
                        <div className='d-flex flex-column flex-md-row justify-content-center'>
                            <button className='btn btn-warning btn-confirm-remove mx-0 mx-md-5' onClick={remove}>Confirm</button>
                            <button className='btn btn-warning btn-cancel-remove mx-0 mx-md-5' onClick={close}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

function PreDevelopment({ projectFrameworks, projectFrameworksItems, saveFramework, getCustomFrameworks, removeCustomFrameworks, saveCustomFrameworkItems, getCustomFrameworkItems, downloadFramework, handleShowSubscriptionsWall, subscription, startTour, nextStep, stopTour, currentStep, setCurrentStep }) {

    const [frameworks, setFrameworks] = useState([]);
    const [selectedFrameworks, setSelectedFrameworks] = useState(projectFrameworks);
    const [listItemSelected, setListItemSelected] = useState(projectFrameworksItems);
    const [framework, setFramework] = useState();
    const [dataFeasibility, setDataFeasibility] = useState(frameworkData[0].children);
    const [currentFramework, setCurrentFramework] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState('predefined');
    const [showModalPhase, setShowModalPhase] = useState(false);
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [currentFrameworkToRemove, setCurrentFrameworkToRemove] = useState(0);
    const [inputPasswordValue, setInputPasswordValue] = useState("");
    const [textAlertRemove, setTextAlertRemove] = useState("");
    const [typeModal, setTypeModal] = useState('edit');

    const fetchProjects = async () => {
        setLoading(true);
        try {
            // Simulación de llamada a una API
            const response = await new Promise((resolve) =>
                setTimeout(() =>
                    resolve([
                        { "id": "F1", "name": "Initiation, Planning, and Conceptualization Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F2", "name": "Owner Development Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F3", "name": "Initial Feasibility Analysis Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F4", "name": "Demolition and Deconstruction Feasibility Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F5", "name": "Preliminary Risk and Contingency Planning Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F6", "name": "Preliminary Permitting and Compliance Strategy Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F7", "name": "High-Level Sustainability and Energy Efficiency Goals Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F8", "name": "Preliminary BIM Implementation Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F9", "name": "Consultant and Contractor Selection Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F10", "name": "Modular and Prefabrication Feasibility Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F11", "name": "Adaptive Reuse and Historical Preservation Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                        { "id": "F12", "name": "Funding Strategy and Financial Planning Framework", "description": "", "checked": false, "visible": false, "parent_id": "F" },
                    ]),
                    1500 // Simula un tiempo de respuesta
                )
            );
            updateFrameworks(response)
        } catch (error) {
            console.error('Error fetching frameworks:', error);
        } finally {
            setLoading(false);
            if (subscription.plan === 'no-plan') {
                let hiddenTour = localStorage.getItem('hiddenTour');
                if (hiddenTour !== "1") {
                    if (currentStep == 0) startTour();
                    if (currentStep == 1) nextStep(1);
                }
            };
        }
    };

    const fetchCustomProjects = () => {
        getCustomFrameworks('F');
    }
    // const fetchCustomProjects = async () => {
    //     setLoading(true);

    //     try {
    //         // Simulación de llamada a una API
    //         const response = await new Promise((resolve) =>
    //             setTimeout(() =>
    //                 resolve([
    //                     { "id": "F1", "name": "Initiation, Planning, and Conceptualization Framework", "description": "", "checked": true, "visible": false, "parent_id": "F" },

    //                 ]),
    //                 1500 // Simula un tiempo de respuesta
    //             )
    //         );
    //         setSelectedFrameworks(response);

    //     } catch (error) {
    //         console.error('Error fetching frameworks:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };



    useEffect(() => {
        fetchCustomProjects();
    }, []);

    useEffect(() => {
        if (showModalPhase) {
            if (subscription.plan === 'no-plan') {
                setCurrentStep(2);
                nextStep(2);
            }
        }
    }, [showModalPhase]);

    useEffect(() => {
        setSelectedFrameworks(projectFrameworks);
    }, [projectFrameworks]);

    useEffect(() => {
        setListItemSelected(projectFrameworksItems);
    }, [projectFrameworksItems]);

    useEffect(() => {
        if (!showModalPhase) fetchProjects();
    }, [selectedFrameworks]);

    useEffect(() => {
    }, [frameworks]);

    useEffect(() => {
        checkItemsFramework(currentFramework?.id)
    }, [listItemSelected]);

    // useEffect(() => {
    //     if (selectedTab === 'predefined') {
    //         fetchProjects();
    //     } else {
    //         fetchCustomProjects();
    //     }
    // }, [selectedTab]);

    const checkItemsFramework = (id) => {
        const element = dataFeasibility.find(item => item.id === id);

        let array;
        if (listItemSelected.length) {
            array = checkFrameworkItemsMultiple(listItemSelected, [element]);
            setCurrentFramework(array[0])
        } else {
            setCurrentFramework(element);
        }
    }

    const updateFrameworks = (frameworksList) => {
        // Extraer los códigos del array seleccionado
        const selectedCodes = new Set(selectedFrameworks.map(item => item.id));

        // Actualizar el array principal
        const updatedItems = frameworksList.map(item => ({
            ...item,
            checked: selectedCodes.has(item.id) // Verificar si el code está en los seleccionados
        }));

        updatedItems.sort((a, b) => b.checked - a.checked);

        setFrameworks(updatedItems);
    };

    const handleSelectFramework = (id) => {

        const element = frameworks.find(item => item.id === id);

        if (element) {

            const alreadyExists = selectedFrameworks.some(item => item.id === id);

            if (!alreadyExists) {
                setSelectedFrameworks([...selectedFrameworks, element]);
            }

        }

        element.checked = true;
        saveFramework(element);
        updateFrameworks(frameworks)
    }

    const handleRemoveFramework = (id) => {
        setCurrentFrameworkToRemove(id);
        setShowModalRemove(true);
    };

    const handleConfirmRemoveFramework = async () => {
        let remove = await removeCustomFrameworks(currentFrameworkToRemove, inputPasswordValue);

        if (remove.status === 'success') {
            const element = selectedFrameworks.filter(item => item.id !== currentFrameworkToRemove);
            setSelectedFrameworks(element);
            updateFrameworks(frameworks);
            setTextAlertRemove("");
            setInputPasswordValue("");
            handleCloseModal();
        } else {
            setTextAlertRemove('Authentication error, please try again.');
        }
    };

    const handleChangeInputRemove = (e) => {
        setInputPasswordValue(e.target.value);
    }

    const handleCloseModal = () => {
        setShowModalPhase(false);
        setShowModalRemove(false);
        setInputPasswordValue("");
        setCurrentFrameworkToRemove("");
        setTextAlertRemove("");
    }
    const handleSaveFrameworkCloseModal = (items, autosave) => {
        handleSaveFramework(items);
        if (!autosave) {
            setShowModalPhase(false);
        }
    }

    const handleUpdateFramework = (id) => {
        checkItemsFramework(id);
        getCustomFrameworkItems(id);
        setShowModalPhase(true);
        setTypeModal('edit');

    }

    const handleUpdateStateFramework = (id) => {

        setListItemSelected((prevList) => {
            // Verifica si el id ya existe en la lista
            const exists = prevList.some((item) => item.id === id.id);

            if (exists) {
                // Si ya existe, lo elimina
                return prevList.filter((item) => item.id !== id.id);
            } else {
                return [...prevList, id];
            }
        });

        //setListItemSelected([...listItemSelected, id])
    }

    const handleSaveFramework = (items) => {
        saveCustomFrameworkItems(items, currentFramework, 'F');
    }

    const handleShowTableContent = (id) => {
        if (subscription.plan === 'no-plan') {
            handleShowSubscriptionsWall();
            return;
        }
        checkItemsFramework(id);
        getCustomFrameworkItems(id);
        setShowModalPhase(true);
        setTypeModal('show');
    }

    const handleDownloadFramework = (id) => {
        downloadFramework(id);
    }

    return (
        <div>
            <ModalPhase active={showModalPhase} close={handleCloseModal} save={handleSaveFrameworkCloseModal} download={handleDownloadFramework} data={currentFramework} type={typeModal} />
            <ModalConfirmRemove active={showModalRemove} remove={handleConfirmRemoveFramework} setValue={handleChangeInputRemove} close={handleCloseModal} value={inputPasswordValue} alert={textAlertRemove} />
            <FrameworkHeader selectedTab={setSelectedTab} framework={framework} />
            <FrameworkList
                frameworks={frameworks}
                loading={loading}
                selectFramework={handleSelectFramework}
                removeFramework={handleRemoveFramework}
                updateFramework={handleUpdateFramework}
                showTableContent={handleShowTableContent}
                downloadFramework={handleDownloadFramework}
                selectedTab={selectedTab}
                stopTour={stopTour} />

        </div>
    )
}

export default PreDevelopment