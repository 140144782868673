import React from 'react';

const NavIcon = ({ icon, alt, onClick }) => (
  <div className='d-flex flex-row'>
    <div className="nav-icon mx-2 text-white" onClick={onClick} style={{ cursor: 'pointer', fontSize:'20px' }}>
      <i className={icon}></i>
    </div>    
  </div>
);

export default NavIcon;
