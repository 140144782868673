import React, { useState, useEffect } from 'react'
import FrameworkMenu from '../Molecules/FrameworkMenu';

const FrameworkHeader = ({ selectedTab, framework  }) => {

    const [selectedFrameworkMenu, setSelectedFrameworkMenu] = useState('Master Frameworks');
    const [color, setColor] = useState('#1a67a3');

    const menuItemsPreDevelopment = [
        { label: 'Master Frameworks', onClick: () => setSelectedFrameworkMenu('Master Frameworks') }
    ];

    useEffect(() => {
        if (selectedFrameworkMenu === 'Master Frameworks') {
            selectedTab('predefined');
            setColor('#1a67a3')
        } else {
            selectedTab('custom');
            setColor('#fcc75c')
        }
    }, [selectedFrameworkMenu])


    return (
        <div>
            {
                <FrameworkMenu menuItems={menuItemsPreDevelopment} selectedMenuItem={selectedFrameworkMenu} color={color} />
            }
        </div>

    )
}

export default FrameworkHeader