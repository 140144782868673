import React from 'react'
import FrameworkMenuItem from '../Atoms/FrameworkMenuItem'

const FrameworkMenu = ({ menuItems, selectedMenuItem, color }) => {
    return (
        <div className='d-none row' style={{width:'50vw'}}>
            {
                menuItems.map((item, index) => (
                    <div className={'col-4'} style={{cursor:'pointer'}}>
                        <FrameworkMenuItem
                            title={item.label}
                            onClick={item.onClick}
                            size={16}
                            selectedMenuItem={selectedMenuItem}
                            color={color}
                        />
                    </div>
                ))}
        </div>
    )
}

export default FrameworkMenu